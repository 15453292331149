/**
 *
 * Wrapper
 *
 */

import { ReactNode } from 'react';
import { Box, Flex, Link, SystemStyleObject } from '@chakra-ui/react';
import { ArrowLeftIcon } from 'assets/icons';
import { To, useNavigate } from 'react-router-dom';

interface WrapperProps {
	children?: ReactNode;
	contentCustomStyles?: SystemStyleObject;
	goBackRoute?: To;
}

const Wrapper = ({ children, contentCustomStyles, goBackRoute = '-1' }: WrapperProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			padding: {
				base: '0 1.8rem',
				sm: '0 2.8rem',
				md: '0 2.5rem',
			},
			width: '100%',
			maxWidth: '95.875rem',
			margin: '0 auto',
			mt: '3.125rem',
			mb: '3.125rem',
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
		},
		link: {
			display: 'flex',
			alignItems: 'center',
			color: 'black',
			fontSize: '1.5rem',
			gap: '0.5625rem',
		},
		content: {
			width: {
				base: '100%',
				sm: '100%',
				md: 'calc(100% - 13rem)',
			},
			alignSelf: 'center',
			mt: '2.5rem',
			...contentCustomStyles,
		},
	};

	const navigate = useNavigate();

	return (
		<Box sx={styles.container}>
			<Flex>
				<Link sx={styles.link} onClick={() => navigate(goBackRoute)}>
					<ArrowLeftIcon />
					Voltar
				</Link>
			</Flex>
			<Box sx={styles.content}>{children}</Box>
		</Box>
	);
};

export default Wrapper;
