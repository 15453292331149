/**
 *
 * IndicatorsModal
 *
 */

import Select, { IOption } from '../Select';
import TableGroup from '../TableGroup';
import { useCallback, useEffect, useMemo } from 'react';
import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spinner,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { IResponseCompanies, getAllCompanies } from 'services/http/company';
import { IMonthsOfTheYear } from 'services/http/flow';
import { IResponseIndicatorsExpanded, getIndicatorsExpanded } from 'services/http/history';
import { getListEmployeesByCompany } from 'services/http/user';
import { User } from 'types/user';
import { DEPARTMENTS_OPTIONS, DEPARTMENT_OPTION_VALUE_TO_ACCESS_LEVELS } from 'utils/constants';
import { generateMonths } from 'utils/generateMonthsForTable';
import { handleDisableSelect } from 'utils/handlePermissions';
import { formatDecimalNumber, formatNumbers } from 'utils/Numbers';
import { ResponseErrors, parseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';

interface IIndicatorFilterForm {
	employee?: IOption | null;
	company: IOption;
	department: IOption;
}

interface IndicatorsModalProps {
	onClose: () => void;
	isOpen: boolean;
	company: IOption;
	employee: IOption;
	department: string;
}

const IndicatorsModal = ({ company, isOpen, onClose, employee, department }: IndicatorsModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			maxW: '103rem',
			w: '100%',
			maxH: '100vh',
		},
		title: {
			fontSize: '1.875rem',
			fontWeight: '600',
			px: '4rem',
		},
		filter: {
			gap: '2rem',
			w: '100%',
			maxW: '57rem',
			flexDir: {
				base: 'column',
				md: 'row',
			},
		},
		table: {
			mt: '1.625rem',
			mb: '2.5rem',
			maxH: 'calc(100vh - 16rem)',
		},
		spinner: {
			w: '100%',
			h: '70vh',
			alignItems: 'center',
			justifyContent: 'center',
		},
		headerCustomStyle: { position: 'sticky', top: 0 },
		customTableStyle: {
			tbody: {
				tr: {
					'&:nth-of-type(-n + 4)': {
						td: {
							background: '#C1D6E1',
						},
					},
				},
			},
		},
	};

	const { addToast } = useCustomToast();

	const {
		session: { user },
	} = useSession();

	const isSelectDisabled = handleDisableSelect(user);

	const { control, getValues, setValue, watch, reset } = useForm<IIndicatorFilterForm>({
		defaultValues: {
			company,
			employee,
			department: department ? DEPARTMENTS_OPTIONS.find(dep => dep?.label === department) : undefined,
		},
	});

	const selectedCompany = watch('company');
	const selectedEmployee = watch('employee');
	const selectedDepartment = watch('department');

	const { data: companies } = useQuery<IResponseCompanies, AxiosError<ResponseErrors>, IResponseCompanies>(
		['companies'],
		() => getAllCompanies(),
		{
			onError: error => addToast({ type: 'error', title: 'Erro!', description: parseErrors(error?.response?.data) }),
			refetchOnMount: true,
			staleTime: 0,
			cacheTime: 0,
		},
	);

	const access_profile =
		DEPARTMENT_OPTION_VALUE_TO_ACCESS_LEVELS[
			selectedDepartment?.value as keyof typeof DEPARTMENT_OPTION_VALUE_TO_ACCESS_LEVELS
		];

	const { data: employees } = useQuery<User[], AxiosError<ResponseErrors>, User[]>(
		['employees', selectedCompany?.label, selectedDepartment?.value],
		() => getListEmployeesByCompany({ company: selectedCompany?.label!, access_profile }),
		{
			onError: errors =>
				addToast({
					type: 'error',
					title: 'Erro!',
					description: parseErrors(errors?.response?.data),
				}),
			staleTime: 0,
			cacheTime: 0,
			enabled: !!selectedCompany?.label && !!selectedDepartment?.value,
		},
	);

	const { data: indicatorsExpandedData, isLoading: isIndicatorsExpandedLoading } = useQuery<
		IResponseIndicatorsExpanded,
		AxiosError<ResponseErrors>,
		IResponseIndicatorsExpanded
	>(
		['indicators-expanded-for-modal', selectedEmployee?.value],
		() => getIndicatorsExpanded({ userId: Number(selectedEmployee?.value) }),
		{
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
			enabled: !!selectedEmployee?.value,
		},
	);

	const monthsAmount = 13;
	const months = generateMonths(monthsAmount);

	const updateIndicator = (indicator: IMonthsOfTheYear) => {
		const updatedIndicator: IMonthsOfTheYear = { average: indicator?.average * 100 };
		months.forEach(({ key: monthkey }) => {
			updatedIndicator[monthkey] = indicator?.[monthkey] * 100;
		});
		return updatedIndicator;
	};

	const generateTableData = useCallback(
		(key: string, formatNumber = false, formatCurrency = false) => {
			let indicatorsExpandedDataFormated = { ...indicatorsExpandedData };

			if (indicatorsExpandedData)
				indicatorsExpandedDataFormated = {
					...indicatorsExpandedData,
					txEvaluation: updateIndicator(indicatorsExpandedData.txEvaluation),
					txPurchase: updateIndicator(indicatorsExpandedData.txPurchase),
					txCapture: updateIndicator(indicatorsExpandedData.txCapture),
					conversion: updateIndicator(indicatorsExpandedData.conversion),
				};

			const isInteger = !formatNumber && !formatCurrency;
			let decimalPlaces = isInteger ? 0 : 2;

			if (isInteger && indicatorsExpandedDataFormated?.[key]?.['average'])
				indicatorsExpandedDataFormated[key]['average'] = Math.round(indicatorsExpandedDataFormated?.[key]?.['average']);

			let results = {};

			if (!indicatorsExpandedDataFormated || !Object.keys(indicatorsExpandedDataFormated).length) return [];

			results = {
				nisa: formatNumber
					? formatDecimalNumber(indicatorsExpandedDataFormated?.[key]?.['average'], '%')
					: formatCurrency
					? formatNumbers(indicatorsExpandedDataFormated?.[key]?.['average'] || 0, true)
					: indicatorsExpandedDataFormated?.[key]?.['average']
					? formatNumbers(indicatorsExpandedDataFormated?.[key]?.['average'] || 0, false, decimalPlaces)
					: '',
			};

			months?.forEach(({ key: monthkey }) => {
				results = {
					...results,
					[monthkey]: formatNumber
						? formatDecimalNumber(indicatorsExpandedDataFormated?.[key]?.[monthkey], '%')
						: formatCurrency
						? formatNumbers(indicatorsExpandedDataFormated?.[key]?.[monthkey] || 0, true)
						: String(indicatorsExpandedDataFormated?.[key]?.[monthkey] || ''),
				};
			});

			return results;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[indicatorsExpandedData, months],
	);

	const indicatorsTable = useMemo(() => {
		return {
			columns: [
				{
					label: 'INDICADORES',
					key: 'indicadores',
				},
				{
					label: 'NISA',
					key: 'nisa',
					columnCustomStyle: {
						textAlign: 'center',
					},
				},
				...months,
			],
			data: [
				{
					showHeaderGroup: false,
					children: [
						{
							indicadores: 'Fluxo de Loja (Passantes)',
							...generateTableData('fluxoLojaPassante'),
						},
						{
							indicadores: 'Fluxo de Ligaçoes Recebidas',
							...generateTableData('fluxoLigacoes'),
						},
						{
							indicadores: 'Comparecimento Ligações',
							...generateTableData('comparecimentoLigacoes'),
						},
						{
							indicadores: 'Fluxo Loja Vendedor',
							...generateTableData('fluxoLojaVendedor'),
						},
						{
							indicadores: 'Ligações Recebidas',
							...generateTableData('ligacoesRecebidas'),
						},
						{
							indicadores: 'Comparecimento',
							...generateTableData('comparecimento'),
						},
						{
							indicadores: 'Conversão',
							...generateTableData('conversion', true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'VENDAS TOTAL',
					...generateTableData('totalSales'),
					children: [
						{
							indicadores: 'Faturamento',
							...generateTableData('billings', false, true),
						},
						{
							indicadores: 'Ticket Médio',
							...generateTableData('averageTicket', false, true),
						},
						{
							indicadores: 'Despesas com Cortesias',
							...generateTableData('complimentaryExpenses', false, true),
						},
						{
							indicadores: '% M.B',
							...generateTableData('percentageMb', true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'FINANCIAMENTO',
					...generateTableData('financing', false, true),

					children: [
						{
							indicadores: 'Contratos Financiamento',
							...generateTableData('financingContracts'),
						},
						{
							indicadores: 'Penetracion Financiamento',
							...generateTableData('financingPenetration', true),
						},
						{
							indicadores: 'Financiamento fora',
							...generateTableData('financingEvasion', true),
						},
						{
							indicadores: 'Penetration Valor Financiado',
							...generateTableData('financingValuePenetration', true),
						},
						{
							indicadores: 'Retorno por Carro',
							...generateTableData('carReturn', false, true),
						},
						{
							indicadores: 'Rentabilidade Financiamento',
							...generateTableData('financingProfitability', true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'TCCB',
					...generateTableData('tccb', true),

					children: [
						{
							indicadores: 'TCCB por Contrato',
							...generateTableData('tccbByContract', false, true),
						},
						{
							indicadores: 'TCCB por Carro',
							...generateTableData('tccbByCar', false, true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'Emplacamento',
					...generateTableData('placement', true),

					children: [
						{
							indicadores: 'Emplacamento por carro',
							...generateTableData('placementByCar', false, true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'Captação',
					...generateTableData('capture'),

					children: [
						{
							indicadores: 'Avaliação',
							...generateTableData('evaluation'),
						},
						{
							indicadores: 'Taxa avaliação',
							...generateTableData('txEvaluation', true),
						},
						{
							indicadores: 'Taxa compra',
							...generateTableData('txPurchase', true),
						},
						{
							indicadores: 'Taxa captura',
							...generateTableData('txCapture', true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'Margem de contribuição',
					...generateTableData('contributionMargin', false, true),

					children: [
						{
							indicadores: 'Lucro Bruto Total',
							...generateTableData('contributionMargin2', false, true),
						},
						{
							indicadores: '(%) Lucro Bruto s/Faturamento',
							...generateTableData('contributionMargin3', true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'Acessórios',
					...generateTableData('accessoriesSale', false, true),

					children: [
						{
							indicadores: 'Ticket Médio Acessorios',
							...generateTableData('averageTicketAccessories', false, true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'HGSI',
					...generateTableData('hgsi', true),
				},
			],
		};
	}, [generateTableData, months]);

	useEffect(() => {
		reset({
			company,
			employee,
			department: department ? DEPARTMENTS_OPTIONS.find(dep => dep?.label === department) : undefined,
		});
	}, [company, department, employee, reset]);

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent data-testid="modal--sellers-indicators" sx={styles.container}>
				<ModalHeader>
					<Text sx={styles.title}>Consulta de indicadores</Text>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody px="5.625rem">
					<Flex sx={styles.filter}>
						<Controller
							name="company"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									label="Unidade"
									placeholder="Selecione a unidade"
									onChange={option => {
										onChange(option);
										setValue('employee', null);
									}}
									value={value}
									options={parsedOptionArray(companies?.companies)}
									dataTestId="input--sellers-indicators-unity"
									variant="small"
									formLabelVariant="small"
									isDisabled={isSelectDisabled}
								/>
							)}
						/>

						<Controller
							name="department"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									label="Departamento"
									placeholder="Selecione o departamento"
									onChange={option => {
										onChange(option);
										setValue('employee', null);
									}}
									value={value}
									options={DEPARTMENTS_OPTIONS}
									dataTestId="input--sellers-indicators-department"
									variant="small"
									formLabelVariant="small"
									isDisabled={isSelectDisabled}
								/>
							)}
						/>

						<Controller
							name="employee"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									isDisabled={!getValues('company')}
									label="Vendedor"
									placeholder={'Selecione o vendedor'}
									onChange={onChange}
									value={value}
									options={parsedOptionArray<User>(employees, 'username', 'id')}
									dataTestId="input--sellers-indicators-employee"
									variant="small"
									formLabelVariant="small"
								/>
							)}
						/>
					</Flex>

					<Flex sx={styles.table}>
						{isIndicatorsExpandedLoading ? (
							<Flex sx={styles.spinner}>{selectedDepartment?.value ? 'Selecione o vendedor' : <Spinner />}</Flex>
						) : (
							<TableGroup
								striped
								columns={indicatorsTable.columns}
								data={indicatorsTable.data}
								contentCustomStyle={styles?.customTableStyle}
								headerCustomStyle={styles?.headerCustomStyle}
							/>
						)}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default IndicatorsModal;
