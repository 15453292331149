/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * VehicleProfitabilityForm
 *
 */

import { AttentionPointForm, attentionsPointsButton } from '../AttentionPoints';
import InputNumeric from '../InputNumeric';
import InputText from '../InputText';
import { useEffect } from 'react';
import { Flex, Grid, GridItem, SystemStyleObject, Text } from '@chakra-ui/react';
import { IFlow } from 'app/pages/Home';
import { useDebounce } from 'hooks/useDebounce';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormReset, UseFormWatch } from 'react-hook-form';
import { IStepProps } from 'types/stepper';
import { maskThousands, unMaskThousands } from 'utils/Numbers';
import * as yup from 'yup';

export const schemaVehicleProfitabilityForm = {
	averageTicket: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	margin: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	validGift: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	unitGrossMargin: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	vehicleProfitabilityTotalMargin: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	financing: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	financingPenetrationAmount: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	financedAmount: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	rApplied: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	averageContractRevenue: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	financingFinancingIncome: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	tccbPenetrationAmount: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	contracts: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	averageContractValue: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	tccbRevenueTccb: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	attentionPoints: yup.array().of(
		yup.object().shape({
			point: yup.string().max(60, 'Limite de 60 caracteres.').nullable(),
		}),
	),
};

export interface IVehicleProfitabilityForm extends AttentionPointForm {
	averageTicket?: number; //TICKET MEDIO
	margin?: number; //MARGEM
	validGift?: number; //BRINDE VALIDO
	unitGrossMargin?: number; //MARGEM BRUTA UNIDADE
	vehicleProfitabilityTotalMargin?: number; //MARGEM TOTAL
	financing?: number; //FINANCIAMENTO
	financingPenetrationAmount?: number; //QUANTIDADE PENETRATION
	financedAmount?: number; //VALOR FINANCIADO
	rApplied?: number; //R APLICADO
	averageContractRevenue?: number; //RECEITA MEDIA CONTRATO
	financingFinancingIncome?: number; //RECEITA FINANCIAMENTO
	tccbPenetrationAmount?: number; //QUANTIDADE PENETRATION
	contracts?: number; //CONTRATOS
	averageContractValue?: number; //VALOR MEDIO CONTRATO
	tccbRevenueTccb?: number; //RECEITA TCCB
}

interface VehicleProfitabilityFormProps
	extends IVehicleProfitabilityForm,
		IStepProps<IVehicleProfitabilityForm, IFlow> {
	register: UseFormRegister<IVehicleProfitabilityForm>;
	control: Control<IVehicleProfitabilityForm>;
	watch: UseFormWatch<IVehicleProfitabilityForm>;
	reset: UseFormReset<IVehicleProfitabilityForm>;
	errors: FieldErrors<IVehicleProfitabilityForm>;
	readOnly?: boolean;
	onOpenAttentionPointsModal: () => void;
}

const VehicleProfitabilityForm = ({
	defaultValues,
	readOnly,
	placeholders,
	watching,
	register,
	control,
	watch,
	reset,
	errors,
	onOpenAttentionPointsModal,
}: VehicleProfitabilityFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDirection: 'column',
			maxW: {
				base: '100%',
				'2xl': '44rem',
			},
			minW: {
				base: '100%',
				'2xl': '41rem',
			},
		},
		tooltipContainer: {
			w: '100%',
			alignItems: 'center',
			gap: '0.5rem',
			justifyContent: 'flex-end',
		},
		content: {
			w: '100%',
			flexDirection: 'column',
			pr: '2.5rem',
			pb: '1.875rem',
			gap: '2.188rem',
		},
		section: {
			w: '100%',
			flexDirection: 'column',
			gap: '1.25rem',
		},
		title: {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			color: '#2E769B',
		},
		grid: {
			gap: '0.625rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				md: 'repeat(2, 1fr)',
				lg: 'repeat(3, 1fr)',
			},
		},
		buttonContainer: {
			w: '100%',
			justifyContent: 'end',
			mt: '0.313rem',
			gap: '1.875rem',
		},
		button: {
			fontSize: '1.375rem',
			px: '2rem',
			h: '4rem',
			w: {
				base: '100%',
				md: 'fit-content',
			},
		},
		customLabelStyle: {
			minHeight: '3.37rem',
			display: 'flex',
			alignItems: 'center',
			whiteSpace: 'pre-line',
		},
		addButton: {
			fontSize: '1.125rem',
			fontWeight: '600',
			color: 'blue.500',
			h: 'fit-content',
			display: 'flex',
			alignItems: 'center',
		},
	};

	const onChangeValues = () => {
		watching?.(watch());
	};

	const debounceCalc = useDebounce(onChangeValues, 800);

	useEffect(() => reset(defaultValues), [defaultValues]);

	return (
		<Flex sx={styles.container}>
			<Flex sx={styles.content}>
				<Flex sx={styles.section}>
					<Text sx={styles.title}>Rentabilidade Veículo</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="averageTicket"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="averageTicket"
										label="Ticket médio (R$):"
										placeholder={
											placeholders?.averageTicket ? `${maskThousands(placeholders?.averageTicket)} *` : 'Ticket médio *'
										}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										errorMessage={errors.averageTicket?.message}
										data-testid="input--averageTicket"
										onKeyUp={debounceCalc}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="margin"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="margin"
										label="Margem (%):"
										placeholder={placeholders?.margin ? `${maskThousands(placeholders?.margin)} *` : 'Margem *'}
										isReadOnly={readOnly}
										suffix="%"
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--margin"
										errorMessage={errors.margin?.message}
										onKeyUp={debounceCalc}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="validGift"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="validGift"
										label="Brindes válidos veículo:"
										placeholder="Brindes válidos veículo"
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										errorMessage={errors.validGift?.message}
										data-testid="input--validGift"
										onKeyUp={debounceCalc}
										decimalScale={0}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="unitGrossMargin"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="unitGrossMargin"
										label={`Margem bruta \n unidade (R$):`}
										placeholder="Margem bruta unidade"
										disabled
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--unitGrossMargin"
										errorMessage={errors.unitGrossMargin?.message}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="vehicleProfitabilityTotalMargin"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="vehicleProfitabilityTotalMargin"
										label="Margens total (R$):"
										placeholder="Margens total"
										disabled
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--vehicleProfitabilityTotalMargin"
										errorMessage={errors.vehicleProfitabilityTotalMargin?.message}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>

				<Flex sx={styles.section}>
					<Text sx={styles.title}>Financiamento</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<InputText
								name="financing"
								label="Financiamento:"
								placeholder="Financiamento"
								register={register}
								disabled
								data-testid="input--financing"
								errorMessage={errors.financing?.message}
								variant="small"
								labelVariant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="financingPenetrationAmount"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="financingPenetrationAmount"
										label={`Penetration - \n qtde (%):`}
										placeholder="Penetration"
										isReadOnly={readOnly}
										suffix="%"
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--financingPenetrationAmount"
										errorMessage={errors.financingPenetrationAmount?.message}
										onKeyUp={debounceCalc}
										decimalScale={0}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="financedAmount"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="financedAmount"
										label="Valor financiado (%):"
										placeholder="Valor financiado:"
										isReadOnly={readOnly}
										suffix="%"
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--financedAmount"
										errorMessage={errors.financedAmount?.message}
										onKeyUp={debounceCalc}
										decimalScale={0}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="rApplied"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="rApplied"
										label="“R” Aplicado (%):"
										placeholder="“R” Aplicado"
										isReadOnly={readOnly}
										suffix="%"
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--rApplied"
										errorMessage={errors.rApplied?.message}
										onKeyUp={debounceCalc}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="averageContractRevenue"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="averageContractRevenue"
										label={`Receita \n média/contrato (R$):`}
										placeholder="Receita média/contrato"
										disabled
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--averageContractRevenue"
										errorMessage={errors.averageContractRevenue?.message}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="financingFinancingIncome"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="financingFinancingIncome"
										label={`Receita \n financiamento (R$):`}
										placeholder="Receita financiamento"
										disabled
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--financingFinancingIncome"
										errorMessage={errors.financingFinancingIncome?.message}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>

				<Flex sx={styles.section}>
					<Text sx={styles.title}>T.C.C.B.</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="tccbPenetrationAmount"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="tccbPenetrationAmount"
										label={`Penetration - \n qtde (%):`}
										placeholder="Penetration"
										isReadOnly={readOnly}
										suffix="%"
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--tccbPenetrationAmount"
										errorMessage={errors.tccbPenetrationAmount?.message}
										onKeyUp={debounceCalc}
										decimalScale={0}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="contracts"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="contracts"
										label="Contratos:"
										placeholder="Contratos"
										disabled
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--contracts"
										errorMessage={errors.contracts?.message}
										decimalScale={0}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="averageContractValue"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="averageContractValue"
										label="Valor por contrato (R$):"
										placeholder="Valor por contrato"
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										errorMessage={errors.averageContractValue?.message}
										data-testid="input--averageContractValue"
										onKeyUp={debounceCalc}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="tccbRevenueTccb"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										name="tccbRevenueTccb"
										label="Receita T.C.C.B. (R$):"
										placeholder="Receita T.C.C.B."
										disabled
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--tccbRevenueTccb"
										errorMessage={errors.tccbRevenueTccb?.message}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default VehicleProfitabilityForm;
