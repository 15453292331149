/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * ServiceChannelsTable
 *
 */

import InputNumeric from '../InputNumeric';
import { useEffect } from 'react';
import { Box, SystemStyleObject, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { IFlow } from 'app/pages/Home';
import { useDebounce } from 'hooks/useDebounce';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormReset, UseFormWatch } from 'react-hook-form';
import { IStepProps } from 'types/stepper';
import { formatValues } from 'utils/form';
import { unMaskThousands } from 'utils/Numbers';

export interface ServiceChannelsTableForm {
	totalCalls?: number; //TOTAL ATENDIMENTOS
	totalConversion?: number; //TOTAL CONVERSÃO
	totalSales?: number; //TOTAL VENDA
	flowConversion?: number; //CONVERSÃO DE FLUXO DE LOJA PASSANTES
	callAttendanceConversion?: number; //CONVERSÃO DE COMPARECIMENTO LIGAÇÕES
	prospectingConversion?: number; //CONVERSÃO DE PROSPECÇÃO
	leadsConversion?: number; //CONVERSÃO DE LEADS
	workshopCustomerConversion?: number; //CONVERSÃO DE CLIENTE OFICINA
	flowSales?: number; //VENDAS DE FLUXO DE LOJA PASSANTES
	callAttendanceSales?: number; //VENDAS DE COMPARECIMENTO LIGAÇÕES
	prospectingSales?: number; //VENDAS DE PROSPECÇÃO
	leadsSales?: number; //VENDAS DE LEADS
	workshopCustomerSales?: number; //VENDAS DE CLIENTE OFICINA
}

interface ServiceChannelsTableProps extends IStepProps<ServiceChannelsTableForm, IFlow> {
	theadCustomStyle?: SystemStyleObject;
	tbodyCustomStyle?: SystemStyleObject;
	control: Control<ServiceChannelsTableForm>;
	watch: UseFormWatch<ServiceChannelsTableForm>;
	reset: UseFormReset<ServiceChannelsTableForm>;
	getValues: UseFormGetValues<IFlow>;
	errors: FieldErrors<IFlow>;
}

const ServiceChannelsTable = ({
	defaultValues,
	watching,
	theadCustomStyle,
	tbodyCustomStyle,
	control,
	reset,
	watch,
	getValues,
	errors,
}: ServiceChannelsTableProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			maxW: {
				base: '100%',
				'2xl': '66.688rem',
			},
			overflowX: 'auto',
			pb: '0.25rem',
		},
		header: {
			background: 'blue.800',
		},
		align: {
			textAlign: 'center',
		},
		cell: {
			color: 'black',
			background: 'gray.85',

			_notFirst: {
				textAlign: 'center',
			},
		},
		cellStriped: {
			color: 'black',
			background: 'gray.90',
			textAlign: 'center',
		},
		footer: {
			background: 'rgba(23, 53, 105, 0.1)',
		},
		footerCell: {
			fontWeight: 700,
			fontSize: '1rem',
			color: 'black',

			_notFirst: {
				textAlign: 'center',
			},
		},
	};

	const onChangeValues = () => {
		watching?.({ ...formatValues(watch()) });
	};

	const debounceCalc = useDebounce(onChangeValues, 800);

	useEffect(() => reset(defaultValues), [defaultValues]);

	return (
		<Box sx={styles.container}>
			<Box>
				<Table>
					<Thead sx={theadCustomStyle}>
						<Tr sx={styles.header}>
							<Th>Canais de atendimento</Th>
							<Th sx={styles.align}>Atendimentos</Th>
							<Th sx={styles.align}>Conversão</Th>
							<Th sx={styles.align}>Vendas</Th>
						</Tr>
					</Thead>
					<Tbody sx={tbodyCustomStyle}>
						<Tr>
							<Td sx={styles.cell}>Fluxo de loja passante</Td>
							<Td sx={styles.cellStriped}>{defaultValues?.shopFlowCustomerServiceQuantity || 0}</Td>
							<Td sx={styles.cell} borderColor="#63B3ED">
								<Controller
									name="flowConversion"
									control={control}
									render={({ field: { onChange, value } }) => (
										<InputNumeric
											name="flowConversion"
											placeholder="0%"
											onChange={e => onChange(unMaskThousands(e?.target?.value))}
											value={value || ''}
											suffix="%"
											decimalScale={0}
											inputVariant="noStyle"
											onKeyUp={debounceCalc}
											data-testid="input--flowConversion-table"
											borderColor={errors?.flowConversion?.message ? 'red' : 'transparent'}
										/>
									)}
								/>
							</Td>
							<Td sx={styles.cell} data-testid="text--flowSales">
								{getValues?.('flowSales') || 0}
							</Td>
						</Tr>
						<Tr>
							<Td sx={styles.cell}>Comparecimento ligações</Td>
							<Td sx={styles.cellStriped}>{defaultValues?.callsCustomerServiceQuantity || 0}</Td>
							<Td sx={styles.cell}>
								<Controller
									name="callAttendanceConversion"
									control={control}
									render={({ field: { onChange, value } }) => (
										<InputNumeric
											name="callAttendanceConversion"
											placeholder="0%"
											onChange={e => onChange(unMaskThousands(e?.target?.value))}
											value={value || ''}
											suffix="%"
											decimalScale={0}
											inputVariant="noStyle"
											onKeyUp={debounceCalc}
											data-testid="input--callAttendanceConversion-table"
											borderColor={errors?.callAttendanceConversion?.message ? 'red' : 'transparent'}
										/>
									)}
								/>
							</Td>
							<Td sx={styles.cell} data-testid="text--callAttendanceSales">
								{getValues?.('callAttendanceSales') || 0}
							</Td>
						</Tr>
						<Tr>
							<Td sx={styles.cell}>Prospecção</Td>
							<Td sx={styles.cellStriped}>{defaultValues?.prospectionCustomerServiceQuantity || 0}</Td>
							<Td sx={styles.cell}>
								<Controller
									name="prospectingConversion"
									control={control}
									render={({ field: { onChange, value } }) => (
										<InputNumeric
											name="prospectingConversion"
											placeholder="0%"
											onChange={e => onChange(unMaskThousands(e?.target?.value))}
											value={value || ''}
											suffix="%"
											decimalScale={0}
											inputVariant="noStyle"
											onKeyUp={debounceCalc}
											data-testid="input--prospectingConversion-table"
											borderColor={errors?.prospectingConversion?.message ? 'red' : 'transparent'}
										/>
									)}
								/>
							</Td>
							<Td sx={styles.cell} data-testid="text--prospectingSales">
								{getValues?.('prospectingSales') || 0}
							</Td>
						</Tr>
						<Tr>
							<Td sx={styles.cell}>Leads</Td>
							<Td sx={styles.cellStriped}>{defaultValues?.leadsCustomerServiceQuantity || 0}</Td>
							<Td sx={styles.cell}>
								<Controller
									name="leadsConversion"
									control={control}
									render={({ field: { onChange, value } }) => (
										<InputNumeric
											name="leadsConversion"
											placeholder="0%"
											onChange={e => onChange(unMaskThousands(e?.target?.value))}
											value={value || ''}
											suffix="%"
											decimalScale={0}
											inputVariant="noStyle"
											onKeyUp={debounceCalc}
											data-testid="input--leadsConversion-table"
											borderColor={errors?.leadsConversion?.message ? 'red' : 'transparent'}
										/>
									)}
								/>
							</Td>
							<Td sx={styles.cell} data-testid="text--leadsSales">
								{getValues?.('leadsSales') || 0}
							</Td>
						</Tr>
						<Tr>
							<Td sx={styles.cell}>Cliente oficina</Td>
							<Td sx={styles.cellStriped}>{defaultValues?.quantity || 0}</Td>
							<Td sx={styles.cell}>
								<Controller
									name="workshopCustomerConversion"
									control={control}
									render={({ field: { onChange, value } }) => (
										<InputNumeric
											name="workshopCustomerConversion"
											placeholder="0%"
											onChange={e => onChange(unMaskThousands(e?.target?.value))}
											value={value || ''}
											suffix="%"
											decimalScale={0}
											inputVariant="noStyle"
											onKeyUp={debounceCalc}
											data-testid="input--workshopCustomerConversion-table"
											borderColor={errors?.workshopCustomerConversion?.message ? 'red' : 'transparent'}
										/>
									)}
								/>
							</Td>
							<Td sx={styles.cell} data-testid="text--workshopCustomerSales">
								{getValues?.('workshopCustomerSales') || 0}
							</Td>
						</Tr>
						<Tr sx={styles.footer}>
							<Td sx={styles.footerCell}>Total</Td>
							<Td sx={styles.footerCell}>{getValues?.('totalCalls')}</Td>
							<Td sx={styles.footerCell}>{getValues?.('totalConversion') || 0}%</Td>
							<Td sx={styles.footerCell}>{getValues?.('totalSales') || 0}</Td>
						</Tr>
					</Tbody>
				</Table>
			</Box>
		</Box>
	);
};

export default ServiceChannelsTable;
