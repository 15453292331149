/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * SalesPriceForm
 *
 */

import { AttentionPointForm, attentionsPointsButton } from '../AttentionPoints';
import InputNumeric from '../InputNumeric';
import { useEffect } from 'react';
import { Flex, Grid, GridItem, SystemStyleObject, Text } from '@chakra-ui/react';
import { IFlow } from 'app/pages/Home';
import { useDebounce } from 'hooks/useDebounce';
import { Control, Controller, FieldErrors, UseFormReset, UseFormWatch } from 'react-hook-form';
import { IStepProps } from 'types/stepper';
import { unMaskThousands } from 'utils/Numbers';
import * as yup from 'yup';

export const schemaSalesPriceForm = {
	averageSaleValue: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	averageValueCost: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	attentionPoints: yup.array().of(
		yup.object().shape({
			point: yup.string().max(60, 'Limite de 60 caracteres.').nullable(),
		}),
	),
};

export interface ISalesPriceForm extends AttentionPointForm {
	averageSaleValue?: number; //VALOR MEDIO VENDA
	averageValueCost?: number; //VALOR MEDIO CUSTO
	profitDocument?: number; //LUCRO DOCUMENTO
	// attentionPoints?: string; //PONTOS DE ATENÇÃO
}

interface SalesPriceFormProps extends IStepProps<ISalesPriceForm, IFlow> {
	control: Control<ISalesPriceForm>;
	watch: UseFormWatch<ISalesPriceForm>;
	reset: UseFormReset<ISalesPriceForm>;
	errors: FieldErrors<ISalesPriceForm>;
	readOnly?: boolean;
	onOpenAttentionPointsModal: () => void;
}

const SalesPriceForm = ({
	defaultValues,
	readOnly,
	watching,
	control,
	watch,
	reset,
	errors,
	onOpenAttentionPointsModal,
}: SalesPriceFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDirection: 'column',
			maxW: {
				base: '100%',
				'2xl': '44rem',
			},
			minW: {
				base: '100%',
				'2xl': '41rem',
			},
		},
		tooltipContainer: {
			w: '100%',
			alignItems: 'center',
			gap: '0.5rem',
			justifyContent: 'flex-end',
		},
		content: {
			w: '100%',
			flexDirection: 'column',
			pr: '2.5rem',
			pb: '1.875rem',
			gap: '2.188rem',
		},
		section: {
			w: '100%',
			flexDirection: 'column',
			gap: '1.25rem',
		},
		title: {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			color: '#2E769B',
		},
		grid: {
			gap: '0.625rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				md: 'repeat(2, 1fr)',
				lg: 'repeat(3, 1fr)',
			},
		},
		buttonContainer: {
			w: '100%',
			justifyContent: 'end',
			mt: '0.313rem',
			gap: '1.875rem',
		},
		button: {
			fontSize: '1.375rem',
			px: '2rem',
			h: '4rem',
			w: {
				base: '100%',
				md: 'fit-content',
			},
		},
		customLabelStyle: {
			minHeight: '3.37rem',
			display: 'flex',
			alignItems: 'center',
			whiteSpace: 'pre-line',
		},
		addButton: {
			fontSize: '1.125rem',
			fontWeight: '600',
			color: 'blue.500',
			h: 'fit-content',
			display: 'flex',
			alignItems: 'center',
		},
	};

	const onChangeValues = () => {
		watching?.(watch());
	};

	const debounceCalc = useDebounce(onChangeValues, 800);

	useEffect(() => reset(defaultValues), [defaultValues]);

	return (
		<Flex sx={styles.container}>
			<Flex sx={styles.content}>
				<Flex sx={styles.section}>
					<Text sx={styles.title}>Preço de venda emplacamento</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="averageSaleValue"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label={`Valor médio \n de venda (R$):`}
										name="averageSaleValue"
										placeholder="Valor médio de venda"
										errorMessage={errors?.averageSaleValue?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled={readOnly}
										data-testid="input--average-sales-value"
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="averageValueCost"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label={`Valor médio \n de custo (R$):`}
										name="averageValueCost"
										placeholder="Valor médio de custo"
										errorMessage={errors?.averageValueCost?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled={readOnly}
										data-testid="input--average-cost-value"
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="profitDocument"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label={`Lucro por \n documento (R$):`}
										name="profitDocument"
										placeholder="Lucro por documento"
										errorMessage={errors?.profitDocument?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										data-testid="input--profit-by-document"
										disabled
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default SalesPriceForm;
