/**
 *
 * IndicatorsForm
 *
 */

import CustomModal from '../CustomModal';
import Select, { IOption } from '../Select';
import { useEffect, useMemo } from 'react';
import {
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Switch,
	SystemStyleObject,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IResponseCompanies, getAllCompanies } from 'services/http/company';
import { IResponseGetIndicators, getIndicators } from 'services/http/flow';
import { ResponseErrors, parseErrors } from 'utils/parseErrors';
import * as yup from 'yup';

export interface IIndicatorsForm {
	name: IOption;
	active: boolean;
	companies: string[];
}

interface IndicatorsFormProps {
	isEditing?: boolean;
	defaultValues?: IIndicatorsForm;
	onSubmit: (data: IIndicatorsForm) => void;
}

const IndicatorsForm = ({ isEditing = false, defaultValues, onSubmit }: IndicatorsFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			maxW: '48.75rem',
			w: 'full',
			bgColor: 'white',
			p: '3rem',
			gap: '2rem',
			flexDir: 'column',
		},
		header: {
			justifyContent: 'space-between',
			w: 'full',
			alignItems: 'center',
			mb: {
				base: '1.5rem',
				md: '0.625rem',
			},
			gap: '1.5rem',
			flexWrap: 'wrap',
		},
		title: {
			fontSize: '1.875rem',
			fontWeight: '600',
		},
		switchContainer: {
			display: 'flex',
			alignItems: 'center',
			gap: '0.5rem',
			w: 'fit-content',
		},
		span: {
			fontSize: '1rem',
			fontWeight: '400',
			w: 'fit-content',
			m: '0',
		},
		unitContainer: {
			mt: '1.5rem',
			gap: '1.5rem',
			flexDir: 'column',
		},
		checkboxContainer: {
			display: 'flex',
			flexDir: 'column',
			gap: '1.5rem',
		},
		text: {
			fontSize: '1.125rem',
			fontWeight: '600',
		},
		buttonContainer: {
			gap: '1.875rem',
			justifyContent: 'flex-end',
			flexDir: {
				base: 'column-reverse',
				md: 'row',
			},
		},
		button: {
			w: {
				md: '9.25rem',
			},
			h: '4rem',
			fontSize: '1.375rem',
		},
	};

	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenSwitch, onOpen: onOpenSwitch, onClose: onCloseSwitch } = useDisclosure();
	const navigate = useNavigate();

	const { data: companies } = useQuery<IResponseCompanies, AxiosError<ResponseErrors>, IResponseCompanies>(
		['companies'],
		() => getAllCompanies(),
		{
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
		},
	);

	const { allCompaniesIds, sortedCompanies } = useMemo(() => {
		const allCompaniesIds = companies?.companiesObjects?.map(item => String(item.id || '')).sort() || [];

		const sortedCompanies = companies?.companiesObjects?.sort((a, b) => a.company.localeCompare(b.company));

		return { allCompaniesIds, sortedCompanies };
	}, [companies?.companiesObjects]);

	const { data: indicators } = useQuery<IResponseGetIndicators, AxiosError<ResponseErrors>, IResponseGetIndicators>(
		['indicators'],
		() => getIndicators(),
		{
			onError: errors =>
				addToast({
					type: 'error',
					title: 'Erro!',
					description: parseErrors(errors?.response?.data),
				}),
		},
	);

	const options: IOption[] = useMemo(() => {
		return Object.entries(indicators || []).map(([key, val]) => {
			return {
				value: key,
				label: val,
			};
		});
	}, [indicators]);

	const formatedDefaultValues = useMemo(() => {
		const defaultOption = options.find(item => item.label === defaultValues?.name.label);

		return {
			...defaultValues,
			name: defaultOption,
		};
	}, [defaultValues, options]);

	const schema = yup.object().shape({
		name: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
		active: yup.boolean(),
		companies: yup.array().of(yup.string()),
	});

	const {
		register,
		control,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors, isDirty },
	} = useForm<IIndicatorsForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			active: true,
			...formatedDefaultValues,
		},
	});

	const formValues = watch();

	const handleOnSubmit = (data: IIndicatorsForm) => {
		onSubmit(data);
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target;
		if (checked) {
			setValue('companies', allCompaniesIds);
		} else {
			setValue('companies', []);
		}
	};

	const isItemChecked = (all = true, currentId?: string) => {
		if (formValues?.companies?.length) {
			return all
				? allCompaniesIds.every(item => formValues?.companies?.includes(item))
				: formValues?.companies.includes(currentId!);
		}

		return false;
	};

	const handleGoBack = () => {
		if (isEditing) {
			isDirty ? onOpen() : navigate(ROUTES.listIndicators);
		} else {
			Boolean(formValues?.name) || Boolean(formValues?.companies?.length) ? onOpen() : navigate(ROUTES.listIndicators);
		}
	};

	const handleEditSwitch = () => {
		if (isEditing && formValues.active) onOpenSwitch();
	};

	useEffect(() => reset(formatedDefaultValues), [formatedDefaultValues, reset]);

	return (
		<Flex as="form" sx={styles.container} onSubmit={handleSubmit(handleOnSubmit)}>
			<Flex flexDir="column">
				<Flex sx={styles.header}>
					<Text sx={styles.title} data-testid="text--title">
						{isEditing ? 'Editar indicador' : 'Cadastrar indicador'}
					</Text>

					<FormControl sx={styles.switchContainer} onClick={handleEditSwitch}>
						<Controller
							name="active"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Switch id="active" isChecked={value} onChange={onChange} data-testid="switch--active" />
							)}
						/>
						<FormLabel htmlFor="active" sx={styles.span}>
							{formValues.active ? 'Desativar indicador' : 'Ativar indicador'}
						</FormLabel>
					</FormControl>
				</Flex>

				<Controller
					name="name"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Select
							name="name"
							label="Indicador"
							placeholder="Selecione o indicador"
							onChange={onChange}
							value={value}
							options={options}
							variant="small"
							formLabelVariant="small"
							errorMessageVariant="small"
							dataTestId="input--name"
							errorMessage={errors.name?.message}
						/>
					)}
				/>

				<Flex sx={styles.unitContainer}>
					<Text sx={styles.text}>Unidade</Text>

					<FormControl isInvalid={!!errors.companies?.message} sx={styles.checkboxContainer}>
						<Checkbox onChange={handleSelectAll} isChecked={isItemChecked()}>
							Selecionar todos
						</Checkbox>

						{sortedCompanies?.map(item => (
							<Checkbox
								key={item.id}
								{...register('companies')}
								value={item.id}
								isChecked={isItemChecked(false, String(item.id))}
								data-testid={`checkbox--companies.${item.id}`}
							>
								{item.company}
							</Checkbox>
						))}
					</FormControl>
				</Flex>
			</Flex>

			<Flex sx={styles.buttonContainer}>
				<Button sx={styles.button} variant="outlined" onClick={handleGoBack} data-testid="button--back">
					Voltar
				</Button>
				<Button sx={styles.button} type="submit" data-testid="button--submit">
					{isEditing ? 'Salvar' : 'Cadastrar'}
				</Button>
			</Flex>

			<CustomModal
				title=""
				body="As informações do indicador não foram salvas. Gostaria de cancelar mesmo assim?"
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Não',
						type: 'outlined',
						onClick: onClose,
						datatestid: 'button--cancel',
					},
					{
						label: 'Sim',
						type: 'solid',
						onClick: () => navigate(ROUTES.listIndicators),
						datatestid: 'button--confirm',
					},
				]}
			/>
			<CustomModal
				title=""
				body="Ao desativar o indicador, este ficará indisponível para o vinculo nos próximos formulários de Geração de fluxo por Canais de venda nas unidades associadas.
				Deseja continuar mesmo assim?"
				isOpen={isOpenSwitch}
				onClose={onCloseSwitch}
				actions={[
					{
						label: 'Não',
						type: 'outlined',
						onClick: () => {
							setValue('active', true);
							onCloseSwitch();
						},
						datatestid: 'button--cancel',
					},
					{
						label: 'Sim',
						type: 'solid',
						onClick: onCloseSwitch,
						datatestid: 'button--confirm',
					},
				]}
			/>
		</Flex>
	);
};

export default IndicatorsForm;
