import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { User } from 'types/user';

const api = getAxios();

export interface IRequestCompanyInformations {
	companyId: number;
	timeRange?: number;
}

interface ISalesMade {
	order: number;
	month: string;
	totalSales: number;
	profit: number;
}

export interface IResponseCompanyInformations {
	salesMade: ISalesMade[];
	customers: number;
	profit: number;
	customersPercentageLastYear: number;
	profitPercentageLastYear: number;
}

export const getCompanyInformations = async ({
	companyId,
	timeRange = 6,
}: IRequestCompanyInformations): Promise<IResponseCompanyInformations> => {
	const response = await api.post(ENDPOINTS.getCompanyInformations, { companyId, timeRange });
	return response.data;
};

export interface IRequestIndicatorsExpanded {
	userId: number;
}

interface IMonthsOfTheYear {
	[key: string]: number;
}

export interface IResponseIndicatorsExpanded {
	[key: string]: IMonthsOfTheYear;
}

export const getIndicatorsExpanded = async (data: IRequestIndicatorsExpanded): Promise<IResponseIndicatorsExpanded> => {
	const response = await api.post(ENDPOINTS.getIndicatorsExpanded, data);
	return response.data;
};

export interface IResponseGoals {
	expectative: number;
	percentage: string;
	realSalary: number;
	seller: string;
	quantitySalesExpectative: number;
	quantitySalesReal: number;
}

export const getGoals = async (companyId: string): Promise<IResponseGoals[]> => {
	const response = await api.post(ENDPOINTS.getGoals(companyId));
	return response.data;
};

export interface IResponseServicesChannels {
	seller: User;
	flowSales: {
		flowStore: number;
		sales: number;
		conversion: number;
	};
	pass: {
		flowStorePass: number;
		flowStorePassSales: number;
		conversionFlowStorePass: number;
	};
	calls: {
		calls: number;
		callsAttended: number;
		callsSale: number;
		conversionCalls: number;
	};
	prospection: {
		prospection: number;
		prospectionSale: number;
		conversionProspeccao: number;
	};
	factory: {
		factory: number;
		factorySale: number;
		conversionOficina: number;
	};
	leads: {
		leads: number;
		leadsSale: number;
		conversionLeads: number;
	};
}

export const getServicesChannels = async (companyId: string): Promise<IResponseServicesChannels[]> => {
	const response = await api.get(ENDPOINTS.getServicesChannels(companyId));
	return response.data;
};
