/**
 *
 * RegisterIndicators
 *
 */

import { Flex, SystemStyleObject } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import IndicatorsForm, { IIndicatorsForm } from 'app/components/IndicatorsForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { createIndicator } from 'services/http/indicator';
import { IResponseCreateIndicator, IRequestIndicator } from 'types/indicator';
import { ResponseErrors, parseErrors } from 'utils/parseErrors';

const RegisterIndicators = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			my: '9.5rem',
			justifyContent: 'center',
		},
	};

	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { mutate: createIndicatorMutate } = useMutation<
		IResponseCreateIndicator,
		AxiosError<ResponseErrors>,
		IRequestIndicator
	>(data => createIndicator(data), {
		onSuccess: () => navigate(ROUTES.listIndicators),
		onError: errors => {
			addToast({
				type: 'error',
				title: 'Erro!',
				description: parseErrors(errors?.response?.data),
			});
		},
	});

	const handleOnSubmit = (data: IIndicatorsForm) => {
		const payload: IRequestIndicator = {
			...data,
			name: data.name.label,
		};

		createIndicatorMutate(payload);
	};

	return (
		<Flex sx={styles.container}>
			<IndicatorsForm onSubmit={handleOnSubmit} />
		</Flex>
	);
};

export default RegisterIndicators;
