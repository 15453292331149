/**
 *
 * SellersIndicatorsModal
 *
 */

import Select, { IOption } from '../Select';
import Table from '../Table';
import { useCallback, useEffect, useMemo } from 'react';
import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spinner,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { IResponseCompanies, getAllCompanies } from 'services/http/company';
import { getIndicatorsAverage } from 'services/http/indicator';
import { getListEmployeesByCompany } from 'services/http/user';
import { IUsersIndicatorAverage } from 'types/indicator';
import { User } from 'types/user';
import { DEPARTMENTS_OPTIONS, DEPARTMENT_OPTION_VALUE_TO_ACCESS_LEVELS } from 'utils/constants';
import { handleDisableSelect } from 'utils/handlePermissions';
import { formatDecimalNumber, formatNumbers } from 'utils/Numbers';
import { ResponseErrors, parseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';

interface ISellerIndicatorForm {
	employee?: IOption[] | null;
	company: IOption;
	department: IOption;
}

interface SellersIndicatorsModalProps {
	onClose: () => void;
	isOpen: boolean;
	company: IOption;
	department: string;
}

const SellersIndicatorsModal = ({ isOpen, onClose, company, department }: SellersIndicatorsModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			maxW: '103rem',
			w: '100%',
			maxH: '100vh',
		},
		title: {
			fontSize: '1.875rem',
			fontWeight: '600',
			px: '4rem',
		},
		filter: {
			gap: '2rem',
			w: '100%',
			maxW: '57rem',
			flexDir: {
				base: 'column',
				md: 'row',
			},
		},
		table: {
			mt: '1.625rem',
			mb: '2.5rem',
			maxH: 'calc(100vh - 16rem)',
		},
		spinner: {
			w: '100%',
			h: '70vh',
			alignItems: 'center',
			justifyContent: 'center',
		},
		headerCustomStyle: { position: 'sticky', top: 0 },
		customTableStyle: {
			tbody: {
				tr: {
					'&:nth-of-type(-n + 3)': {
						td: {
							background: '#C1D6E1',
						},
					},
				},
			},
		},
	};

	const { addToast } = useCustomToast();

	const {
		session: { user },
	} = useSession();

	const isSelectDisabled = handleDisableSelect(user);

	const { control, getValues, setValue, watch, reset } = useForm<ISellerIndicatorForm>({
		defaultValues: {
			company,
			employee: undefined,
			department: department ? DEPARTMENTS_OPTIONS.find(dep => dep?.label === department) : undefined,
		},
	});

	const selectedCompany = watch('company');
	const selectedEmployee = watch('employee');
	const selectedDepartment = watch('department');

	const { data: companies } = useQuery<IResponseCompanies, AxiosError<ResponseErrors>, IResponseCompanies>(
		['companies'],
		() => getAllCompanies(),
		{
			onError: error => addToast({ type: 'error', title: 'Erro!', description: parseErrors(error?.response?.data) }),
			refetchOnMount: true,
			staleTime: 0,
			cacheTime: 0,
		},
	);

	const access_profile =
		DEPARTMENT_OPTION_VALUE_TO_ACCESS_LEVELS[
			selectedDepartment?.value as keyof typeof DEPARTMENT_OPTION_VALUE_TO_ACCESS_LEVELS
		];

	const { data: employees } = useQuery<User[], AxiosError<ResponseErrors>, User[]>(
		['employees', selectedCompany?.label, selectedDepartment?.value],
		() => getListEmployeesByCompany({ company: selectedCompany?.label!, access_profile }),
		{
			onError: errors =>
				addToast({
					type: 'error',
					title: 'Erro!',
					description: parseErrors(errors?.response?.data),
				}),
			staleTime: 0,
			cacheTime: 0,
			enabled: !!selectedCompany?.value && !!selectedDepartment?.value,
		},
	);

	const { data: indicatorsAverage, isLoading: isIndicatorsAverageLoading } = useQuery<
		IUsersIndicatorAverage,
		AxiosError<ResponseErrors>,
		IUsersIndicatorAverage
	>(
		['average-indicators', selectedCompany?.value, selectedEmployee?.length, selectedDepartment?.value],
		() =>
			getIndicatorsAverage({
				user__company_id__id: String(selectedCompany?.value || ''),
				user__id: selectedEmployee?.map(item => String(item.value || '')),
				user__department: String(selectedDepartment?.value || ''),
			}),
		{
			onError: errors =>
				addToast({
					type: 'error',
					title: 'Erro!',
					description: parseErrors(errors?.response?.data),
				}),
			staleTime: 0,
			cacheTime: 0,
			enabled: !!selectedCompany?.value && !!selectedDepartment?.value,
		},
	);

	const keys = Object.keys(indicatorsAverage || {});

	const roundAverage = (isInteger: boolean, data: number) => (isInteger ? Math.round(data) : data);

	const generateTableData = useCallback(
		(key: string, formatNumber = false, formatCurrency = false) => {
			let formattedData = {} as { [key: string]: { [key: string]: number | string } };

			let indicatorsExpandedDataFormated: IUsersIndicatorAverage = {} as IUsersIndicatorAverage;

			if (!indicatorsAverage || !Object.keys(indicatorsAverage).length) return [];

			const isInteger = !formatNumber && !formatCurrency;
			let decimalPlaces = isInteger ? 0 : 2;

			Object.keys(indicatorsAverage).forEach(key => {
				const dataFormated = indicatorsAverage[key].map(data => {
					let average: number = roundAverage(isInteger, data.average);

					if (
						data.indicator === 'tx_evaluation' ||
						data.indicator === 'tx_purchase' ||
						data.indicator === 'tx_capture' ||
						data.indicator === 'conversion'
					)
						average = roundAverage(isInteger, data.average * 100);

					return { ...data, average };
				});

				indicatorsExpandedDataFormated[key] = dataFormated;
			});

			keys.forEach(key => {
				indicatorsExpandedDataFormated?.[key].forEach(item => {
					const indicator: string = item.indicator;

					const exists = formattedData?.[indicator];

					if (exists) {
						const newData = {
							...exists,
							[key]: formatNumber
								? formatDecimalNumber(item.average, '%')
								: formatCurrency
								? formatNumbers(item.average || 0, true)
								: formatNumbers(item.average || 0, false, decimalPlaces),
						};

						formattedData[indicator] = newData;
					} else {
						formattedData = {
							...formattedData,
							[indicator]: {
								[key]: formatNumber
									? formatDecimalNumber(item.average, '%')
									: formatCurrency
									? formatNumbers(item.average || 0, true)
									: formatNumbers(item.average || 0, false, decimalPlaces),
							},
						};
					}
				});
			});

			return formattedData[key] as {};
		},
		[indicatorsAverage, keys],
	);

	const averageIndicatorsTable = useMemo(() => {
		const usersColumns = keys.map(item => ({
			label: item,
			key: item,
		}));

		return {
			columns: [
				{
					label: 'INDICADORES',
					key: 'indicator',
				},
				...usersColumns,
			],
			data: [
				{
					indicator: 'Fluxo de Loja (Passantes)',
					...generateTableData('fluxo_loja_passante'),
				},
				{
					indicator: 'Fluxo de Ligaçoes Recebidas',
					...generateTableData('fluxo_ligacoes'),
				},
				{
					indicator: 'Comparecimento Ligações',
					...generateTableData('comparecimento_ligacoes'),
				},
				{
					indicator: 'Fluxo Loja Vendedor',
					...generateTableData('fluxo_loja_vendedor'),
				},
				{
					indicator: 'Ligações Recebidas',
					...generateTableData('ligacoes_recebidas'),
				},
				{
					indicator: 'Comparecimento',
					...generateTableData('comparecimento'),
				},
				{
					indicator: 'Conversão',
					...generateTableData('conversion', true),
				},
				{
					indicator: 'Vendas Total',
					...generateTableData('total_sales'),
				},
				{
					indicator: 'Faturamento',
					...generateTableData('billings', false, true),
				},
				{
					indicator: 'Ticket Médio',
					...generateTableData('average_ticket', false, true),
				},
				{
					indicator: 'Despesas com Cortesias',
					...generateTableData('complimentary_expenses', false, true),
				},
				{
					indicator: '% M.B',
					...generateTableData('percentage_mb', true),
				},
				{
					indicator: 'Financiamento',
					...generateTableData('financing', false, true),
				},
				{
					indicator: 'Contratos Financiamento',
					...generateTableData('financing_contracts'),
				},
				{
					indicator: 'Penetracion Financiamento',
					...generateTableData('financing_penetration', true),
				},
				{
					indicator: 'Financiamento fora',
					...generateTableData('financing_evasion', true),
				},
				{
					indicator: 'Penetration Valor Financiado',
					...generateTableData('financing_value_penetration', true),
				},
				{
					indicator: 'Retorno por Carro',
					...generateTableData('carReturn', false, true),
				},
				{
					indicator: 'Rentabilidade Financiamento',
					...generateTableData('financing_profitability', true),
				},
				{
					indicator: 'TCCB',
					...generateTableData('tccb', true),
				},
				{
					indicator: 'TCCB por Contrato',
					...generateTableData('tccb_by_contract', false, true),
				},
				{
					indicator: 'TCCB por Carro',
					...generateTableData('tccb_by_car', false, true),
				},
				{
					indicator: 'Emplacamento',
					...generateTableData('placement', true),
				},
				{
					indicator: 'Emplacamento por carro',
					...generateTableData('placement_by_car', false, true),
				},
				{
					indicator: 'Captação',
					...generateTableData('capture'),
				},
				{
					indicator: 'Avaliação',
					...generateTableData('evaluation'),
				},
				{
					indicator: 'Taxa avaliação',
					...generateTableData('tx_evaluation', true),
				},
				{
					indicator: 'Taxa compra',
					...generateTableData('tx_purchase', true),
				},
				{
					indicator: 'Taxa captura',
					...generateTableData('tx_capture', true),
				},
				{
					indicator: 'Margem de contribuição',
					...generateTableData('contribution_margin', false, true),
				},
				{
					indicator: 'Lucro Bruto Total',
					...generateTableData('contribution_margin_2', false, true),
				},
				{
					indicator: '(%) Lucro Bruto s/Faturamento',
					...generateTableData('contribution_margin_3', true),
				},
				{
					indicator: 'Acessórios',
					...generateTableData('accessories_sale', false, true),
				},
				{
					indicator: 'Ticket Médio Acessorios',
					...generateTableData('average_ticket_accessories', false, true),
				},
				{
					indicator: 'HGSI',
					...generateTableData('hgsi', true),
				},
			],
		};
	}, [generateTableData, keys]);

	useEffect(() => {
		reset({
			company,
			employee: undefined,
			department: department ? DEPARTMENTS_OPTIONS.find(dep => dep?.label === department) : undefined,
		});
	}, [company, department, reset]);

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent data-testid="modal--sellers-indicators" sx={styles.container}>
				<ModalHeader>
					<Text sx={styles.title}>Consulta de funcionários</Text>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody px="5.625rem">
					<Flex sx={styles.filter}>
						<Controller
							name="company"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									label="Unidade"
									placeholder="Selecione a unidade"
									onChange={option => {
										onChange(option);
										setValue('employee', null);
									}}
									value={value}
									options={parsedOptionArray(companies?.companiesObjects, 'company', 'id')}
									dataTestId="input--sellers-indicators-unity"
									variant="small"
									formLabelVariant="small"
									isDisabled={isSelectDisabled}
								/>
							)}
						/>

						<Controller
							name="department"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									label="Departamento"
									placeholder="Selecione o departamento"
									onChange={option => {
										onChange(option);
										setValue('employee', null);
									}}
									value={value}
									options={DEPARTMENTS_OPTIONS}
									dataTestId="input--sellers-indicators-department"
									variant="small"
									formLabelVariant="small"
									isDisabled={isSelectDisabled}
								/>
							)}
						/>

						<Controller
							name="employee"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									isDisabled={!getValues('company')}
									label="Vendedor"
									placeholder={'Selecione o vendedor'}
									onChange={onChange}
									value={value}
									options={parsedOptionArray<User>(employees, 'username', 'id')}
									dataTestId="input--sellers-indicators-employee"
									variant="small"
									formLabelVariant="small"
									isMulti
								/>
							)}
						/>
					</Flex>

					<Flex sx={styles.table}>
						{isIndicatorsAverageLoading ? (
							<Flex sx={styles.spinner}>
								<Spinner />
							</Flex>
						) : (
							<Table
								striped
								data={averageIndicatorsTable.data}
								columns={averageIndicatorsTable.columns}
								headerCustomStyle={styles?.headerCustomStyle}
								contentCustomStyle={styles.customTableStyle}
							/>
						)}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default SellersIndicatorsModal;
