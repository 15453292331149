/**
 *
 * ListIndicators
 *
 */

import { useMemo } from 'react';
import { Box, Button, Flex, HStack, Heading, IconButton, Spinner, SystemStyleObject } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import CustomPagination from 'app/components/Pagination';
import Select, { IOption } from 'app/components/Select';
import Table, { IColumn } from 'app/components/Table';
import { TableEditIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useDebounce } from 'hooks/useDebounce';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { MdArrowDropDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getAllCompanies, IResponseCompanies } from 'services/http/company';
import { getIndicators, getIndicatorsNames } from 'services/http/indicator';
import { IPaginatedIndicators, IResponseIndicatorsNames } from 'types/indicator';
import { handleDisableSelect } from 'utils/handlePermissions';
import { parseErrors, ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';

interface IForm {
	status?: IOption;
	companies?: IOption;
	search?: IOption;
	page_size?: string;
	page?: string;
}

const ListIndicators = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			padding: { lg: '4.5rem', base: '1rem' },
			boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
			backgroundColor: 'white',
			borderRadius: '8px',
		},
		header: {
			w: '100%',
			alignItems: 'baseline',
			justifyContent: 'space-between',
			gap: '1.5rem',
			flexDirection: {
				base: 'column',
				md: 'row',
			},
		},
		addButton: {
			h: '4rem',
			fontSize: { base: '1rem', lg: '1.375rem' },
			fontWeight: 400,
			px: '2rem',
			letterSpacing: '1px',
			w: { base: '100%', md: 'fit-content' },
		},
		heading: {
			fontSize: '1.87rem',
			lineHeight: '3.25rem',
			color: '#000',
			fontWeight: 'semibold',
		},
		filter: {
			m: {
				base: '1rem 0',
				md: '2.5rem 0',
			},
		},
		filterText: {
			color: 'blue.500',
			fontWeight: 600,
			fontSize: '1.375rem',
		},
		filterFields: {
			display: 'flex',
			flexWrap: 'wrap',
			mt: '1.25rem',
			gap: '3.125rem',
			flexDirection: {
				base: 'column',
				xl: 'row',
			},
			alignItems: { xl: 'flex-end', base: 'flex-start' },
		},
		select: {
			w: '100%',
			flex: 1,
			maxW: {
				base: '100%',
				xl: '23.5rem',
			},
		},
		selectLeft: {
			flex: 1,
			w: {
				base: '100%',
				xl: '15.25rem',
			},
		},
		button: {
			borderRadius: 0,
			width: '2.1875rem',
			height: '2.1875rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			_hover: {
				filter: 'brightness(0.8)',
			},
		},
		spinner: {
			boxSize: 4,
			color: 'white',
		},
		pagination: {
			w: '100%',
			justifyContent: 'end',
			mt: '2.5rem',
		},
		spinnerBox: {
			justifyContent: 'center',
		},
		grid: {
			justifyContent: 'center',
		},
		strippedCell: {
			backgroundColor: '#F2F2F2',
		},
		cell: {
			backgroundColor: '#ffffff',
		},
		boxSelectLeft: {
			w: { base: '100%', xl: 'fit-content' },
			gap: '2rem',
		},
	};

	const selectStyle = {
		border: '1px solid #cccccc',
		height: '60px',
		borderRadius: '5px',
	};

	const navigate = useNavigate();

	const columns: IColumn[] = [
		{
			label: 'Indicadores',
			key: 'name',
		},
		{
			label: 'Status',
			key: 'status',
		},
		{
			label: 'Unidade',
			key: 'units',
		},
		{
			label: 'Ações',
			key: 'actions',
		},
	];

	const allOptions = useMemo(() => ({ value: undefined, label: 'Todos' }), []);

	const { addToast } = useCustomToast();

	const statusOptions = [allOptions, { value: true, label: 'Ativo' }, { value: false, label: 'Inativo' }];

	const {
		session: { user },
	} = useSession();

	const isSelectDisabled = handleDisableSelect(user);

	const defaultValues = {
		page_size: '10',
		page: '1',
		companies: allOptions,
		status: statusOptions[1],
		search: allOptions,
	};

	const { control, setValue, handleSubmit, getValues, watch } = useForm<IForm>({
		defaultValues,
	});

	const formValues = watch();

	const { data: companies, isLoading: companiesIsLoading } = useQuery<
		IResponseCompanies,
		AxiosError<ResponseErrors>,
		IResponseCompanies
	>(['companies'], () => getAllCompanies(), {
		onSuccess: data => {
			const defaultCompany = data?.companiesObjects?.find(item => item.company === user.company);

			setValue('companies', { label: defaultCompany?.company || '', value: defaultCompany?.id });
		},
		onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
	});

	const {
		data: listIndicators,
		refetch: refetchListIndicators,
		isLoading: isLoadingListIndicators,
		isRefetching: isRefetchingListIndicators,
	} = useQuery<IPaginatedIndicators, AxiosError<ResponseErrors>, IPaginatedIndicators>(
		['getIndicators', formValues],
		() =>
			getIndicators({
				...getValues(),
				companies: getValues('companies.value')?.toString(),
				search: getValues('search.value')?.toString(),
				active: getValues('status.value')?.toString(),
			}),
		{
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
		},
	);

	const { data: indicatorsList, isLoading: isLoadingIndicatorsList } = useQuery<
		IResponseIndicatorsNames[],
		AxiosError<ResponseErrors>
	>(
		['getIndicatorsList', formValues.status?.value],
		() => getIndicatorsNames({ active: String(formValues.status?.value || '') }),
		{
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
		},
	);

	const listIndicatorsFilter = useMemo(
		() =>
			listIndicators?.results?.map(indicator => {
				return {
					name: indicator.name,
					status: indicator.active ? 'Ativo' : 'Inativo',
					units: indicator.companies.map(e => e.company).join('; '),
					actions: (
						<Flex sx={styles?.grid}>
							<Box>
								<IconButton
									data-testid={`button--edit--${indicator.id}`}
									aria-label={`Editar indicador ${indicator.id}`}
									icon={<TableEditIcon />}
									background="yellow.500"
									sx={styles?.button}
									onClick={() => navigate(ROUTES.editIndicator(indicator.id!))}
								/>
							</Box>
						</Flex>
					),
				};
			}) || [],
		[listIndicators?.results, navigate, styles?.button, styles?.grid],
	);

	const onFilter = (page: number) => {
		setValue('page', page.toString());
		handleSubmit(onSubmit)();
	};

	const debounced = useDebounce(() => onFilter(Number(defaultValues.page)));

	const onSubmit = () => refetchListIndicators();

	return (
		<Box p={{ lg: '3.75rem', base: '1rem' }}>
			<Box sx={styles.container}>
				<HStack sx={styles?.header}>
					<Heading sx={styles.heading}>Indicadores</Heading>
				</HStack>

				{companiesIsLoading ? (
					<Flex sx={styles.spinnerBox}>
						<Spinner />
					</Flex>
				) : (
					<>
						<Box sx={styles?.filter} as="form" onSubmit={handleSubmit(onSubmit)}>
							<Box sx={styles?.filterFields}>
								<Box sx={styles?.select}>
									<Controller
										control={control}
										name="companies"
										render={({ field }) => (
											<Select
												dropdownIndicatorComponent={MdArrowDropDown}
												styles={{
													control: styles => {
														return {
															...styles,
															...selectStyle,
														};
													},
												}}
												label="Unidade"
												placeholder="Selecione uma unidade"
												options={[allOptions, ...parsedOptionArray(companies?.companiesObjects || [], 'company', 'id')]}
												onChange={event => {
													setValue('companies', event as IOption);
													debounced();
												}}
												value={field.value}
												dataTestId="input--unity"
												isDisabled={isSelectDisabled}
											/>
										)}
									/>
								</Box>
								<Flex sx={styles.boxSelectLeft}>
									<Box>
										<Controller
											control={control}
											name="status"
											render={({ field }) => (
												<Select
													styles={{
														control: styles => {
															return {
																...styles,
																...selectStyle,
															};
														},
													}}
													dropdownIndicatorComponent={MdArrowDropDown}
													label="Status"
													options={statusOptions}
													dataTestId="input--status"
													value={field?.value}
													placeholder="Status"
													onChange={value => {
														field?.onChange(value);
														debounced();
													}}
												/>
											)}
										/>
									</Box>

									<Box sx={styles?.selectLeft}>
										<Controller
											control={control}
											name="search"
											render={({ field }) => (
												<Select
													styles={{
														control: styles => {
															return {
																...styles,
																...selectStyle,
															};
														},
													}}
													dropdownIndicatorComponent={MdArrowDropDown}
													label="Indicador"
													placeholder="Indicador"
													options={[allOptions, ...parsedOptionArray(indicatorsList, 'name', 'name')]}
													dataTestId="input--search"
													value={field?.value}
													isLoading={isLoadingIndicatorsList}
													onChange={value => {
														field?.onChange(value);
														debounced();
													}}
												/>
											)}
										/>
									</Box>
								</Flex>
								<Box ml="auto" w={['100%', 'fit-content']}>
									<Button
										sx={styles.addButton}
										data-testid="button--cancel"
										onClick={() => navigate(ROUTES.registerIndicator)}
									>
										Cadastrar novo indicador
									</Button>
								</Box>
							</Box>
						</Box>

						{isLoadingListIndicators || isRefetchingListIndicators ? (
							<Flex sx={styles.spinnerBox}>
								<Spinner />
							</Flex>
						) : (
							<Table
								striped
								columns={columns}
								data={listIndicatorsFilter}
								cellStripedCustomStyle={styles.strippedCell}
								cellCustomStyle={styles.cell}
								headerCustomStyle={{ backgroundColor: '#173569' }}
							/>
						)}
					</>
				)}

				<Flex sx={styles.pagination}>
					<CustomPagination
						currentPage={Number(getValues('page'))}
						totalItems={listIndicators?.totalRecords}
						pageSize={Number(defaultValues?.page_size)}
						onPaginate={onFilter}
					/>
				</Flex>
			</Box>
		</Box>
	);
};

export default ListIndicators;
