export const ROUTES = {
	// No auth routes
	login: '/login',
	register: `/cadastro/:token`,
	recoverPassword: '/recuperar-senha',
	resetPassword: `/redefinir-senha/:token`,

	// Auth routes
	home: '/',
	profile: '/perfil',
	editProfile: '/editar-perfil',

	// Access control
	accessControl: '/controle-de-acesso',
	userRegister: '/cadastrar-usuario',
	viewUser: '/visualizar-usuario/',
	editUser: (id: string) => `/editar-usuario/${id}`,
	// Flow
	listSalesChannelsFlow: '/historico-canais-de-venda',
	registerFlow: '/cadastro-fluxo/',
	viewFlow: '/visualizar-fluxo/',
	editFlow: '/editar-fluxo/',

	//Commissions
	listCommissions: '/listar-comissao/',
	registerCommission: '/cadastro-comissao/',
	editCommission: (id: string) => `/editar-comissao/${id}`,

	//Indicators
	listIndicators: '/listar-indicadores',
	registerIndicator: '/cadastro-indicador',
	editIndicator: (id: string) => `/editar-indicador/${id}`,
};
