import { useSession } from './useSession';
import { isInsideOptionArray } from 'utils/insideArray';
import { parsedOptionArray } from 'utils/parseOptionArray';

export const useCanViewAction = (accessLevels?: string[]) => {
	const { session } = useSession();

	if (!accessLevels) return true;

	return isInsideOptionArray(parsedOptionArray(accessLevels), parsedOptionArray(session?.user?.accessProfile));
};
