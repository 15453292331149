import { ACCESS_LEVELS } from './constants';
import { IOption } from 'app/components/Select';
import { useSession } from 'hooks/useSession';

export const useListAccessProfiles = (_defaultValues: IOption[] = []) => {
	const { session } = useSession();

	let listAccessProfiles: IOption[] = [];

	// Checa qual o nível de acesso o usuário tem e popula a lista de acesso que vai ser devolvida pela função
	if (session?.user?.accessProfile?.includes(ACCESS_LEVELS.administrator?.value)) {
		listAccessProfiles = Object.values(ACCESS_LEVELS);
		return listAccessProfiles;
	}
	if (session?.user?.accessProfile?.includes(ACCESS_LEVELS.new_manager?.value)) {
		listAccessProfiles.push(ACCESS_LEVELS['new_seller']);
	}
	if (session?.user?.accessProfile?.includes(ACCESS_LEVELS.preowned_manager?.value)) {
		listAccessProfiles.push(ACCESS_LEVELS['preowned_seller']);
	}

	return listAccessProfiles;
};
