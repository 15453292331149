/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * WageForm
 *
 */

import { attentionsPointsButton } from '../AttentionPoints';
import InputNumeric from '../InputNumeric';
import { useEffect } from 'react';
import { Button, Flex, Grid, GridItem, SystemStyleObject, Text, Tooltip } from '@chakra-ui/react';
import { IFlow } from 'app/pages/Home';
import { TooltipIcon } from 'assets/icons';
import { useDebounce } from 'hooks/useDebounce';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import { IStepProps } from 'types/stepper';
import { unMaskThousands } from 'utils/Numbers';

export interface IWageForm {
	totalSalary?: number; //VALOR TOTAL SALARIO
	commissionOnSale?: number; //COMISSAO SOBRE VENDA
	comissionOnMargin?: number; //COMISSAO SOBRE MARGEM
	financingCommission?: number; //COMISSAO FINANCIAMENTO
	forwardingCommission?: number; //COMISSAO DESPACHANTE
	tccbCommission?: number; //COMISSAO TCCB
	saleValue?: number; //VALOR VENDA
	quantitySale?: number; //QUANTIDADE VENDA
	totalMargin?: number; //MARGEM TOTAL
	financingIncome?: number; //RECEITA FINANCIAMENTO
	salaryDispatcherRevenue?: number; //RECEITA DESPACHANTE
	revenueTccb?: number; //RECEITA TCCB
	fundingAward?: number; //PREMIO FINANCIAMENTO
	salaryCaptureAward?: number; //PREMIAÇÃO POR CAPTURA
	dispatcherAward?: number; //PREMIO DESPACHANTE
	tccbAward?: number; //PREMIO TCCB
	salarySaleAccessories?: number; //VENDA ACESSORIOS
	dsr?: number; //DSR
	calendarDays?: number; //DIAS CALENDARIO
	sundayHolidays?: number; //DOMINGOS FERIADOS
}

interface SalaryCalculationValues {
	financingIncome: number; //RECEITA FINANCIAMENTO
	revenueTccb: number; //RECETIA TCCB
	dispatcherRevenue: number; //RECEITA DESPACHANTE
	captureAward: number; //PREMIAÇÃO POR CAPTURA
	saleAccessories: number; //VENDA ACESSORIOS
	commissionOnSale: number; //COMISSAO SOBRE VENDA
	comissionOnMargin: number; //COMISSAO SOBRE MARGEM
}

interface WageFormProps extends IStepProps<IWageForm, IFlow> {
	readOnly?: boolean;
	salaryCalculationValues?: SalaryCalculationValues;
	onOpenAttentionPointsModal: () => void;
	formChannelFlow: UseFormReturn<IFlow>;
}

const WageForm = ({
	readOnly = false,
	defaultValues,
	onSubmit,
	prevStep,
	salaryCalculationValues,
	watching,
	isLoadingButton,
	isEditing,
	onOpenAttentionPointsModal,
	formChannelFlow,
}: WageFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			p: '1.375rem',
			backgroundColor: 'white',
			borderRadius: '0.313rem',
			border: '1px solid #D9D9D9',
			flexDirection: 'column',
		},
		tooltipContainer: {
			w: '100%',
			alignItems: 'center',
			gap: '0.5rem',
			justifyContent: 'flex-end',
		},
		content: {
			w: '100%',
			flexDirection: 'column',
			pb: '1.875rem',
			gap: '2.188rem',
		},
		section: {
			w: '100%',
			flexDirection: 'column',
			gap: '1.25rem',
		},
		title: {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			color: '#2E769B',
		},
		grid: {
			flexWrap: 'wrap',
			gap: '1rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				md: 'repeat(3, 1fr)',
				lg: 'repeat(4, 1fr)',
			},
		},
		buttonContainer: {
			w: '100%',
			justifyContent: 'end',
			mt: '0.313rem',
			gap: '1.875rem',
		},
		button: {
			fontSize: '1.375rem',
			px: '2rem',
			h: '4rem',
			w: {
				base: '100%',
				md: 'fit-content',
			},
		},
		customLabelStyle: {
			minHeight: '3.37rem',
			display: 'flex',
			alignItems: 'center',
			whiteSpace: 'pre-line',
		},
		addButton: {
			fontSize: '1.125rem',
			fontWeight: '600',
			color: 'blue.500',
			h: 'fit-content',
			display: 'flex',
			alignItems: 'center',
		},
	};

	const {
		control,
		handleSubmit,
		watch,
		reset,
		setError,
		formState: { errors },
	} = formChannelFlow;

	const onHandleSubmit = (data: IWageForm) => {
		onSubmit(data);
	};

	const onChangeValues = () => {
		watching?.(watch());
	};

	const handlePrevStep = () => {
		onChangeValues();
		prevStep?.();
	};

	const debounceCalc = useDebounce(onChangeValues, 800);

	useEffect(() => {
		reset(defaultValues);

		Object.entries(errors).forEach(([key, value]) => {
			const current = defaultValues?.[key as keyof IFlow] as number;

			if (typeof current === 'undefined' || isNaN(current)) {
				setError(key as keyof IFlow, value as FieldError);
			}
		});
	}, [defaultValues]);

	return (
		<Flex as="form" onSubmit={handleSubmit(onHandleSubmit)} sx={styles.container}>
			<Flex sx={styles.tooltipContainer}>
				<Text>* = Média</Text>
				<Tooltip label="Os campos com valores contendo o símbolo '*' são os valores de referência(média) não sendo o valor final do campo.">
					<span>
						<TooltipIcon />
					</span>
				</Tooltip>
			</Flex>
			<Flex sx={styles.content}>
				<Flex sx={styles.section}>
					<Text sx={styles.title}>Salário</Text>

					<Grid sx={styles.grid}>
						<GridItem mb="1rem">
							<Controller
								name="totalSalary"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Valor total (R$):"
										placeholder="Valor total"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--totalSalary"
										h="4rem"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="commissionOnSale"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label={`Comis. sobre \n venda (R$):`}
										placeholder="Comis. sobre venda"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--commissionOnSale"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="comissionOnMargin"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label={`Comis. sobre \n margem (R$):`}
										placeholder="Comis. sobre margem"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--comissionOnMargin"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="financingCommission"
								defaultValue={salaryCalculationValues?.financingIncome}
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label={`Comis. \n financiamento (R$):`}
										placeholder="Comis. financiamento"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--financingCommission"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="forwardingCommission"
								defaultValue={salaryCalculationValues?.dispatcherRevenue}
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label={`Comis. \n despachante (R$):`}
										placeholder="Comis. despachante"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--forwardingCommission"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="tccbCommission"
								defaultValue={salaryCalculationValues?.revenueTccb}
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label={`Comissão \n T.C.C.B. (R$):`}
										placeholder="Comissão T.C.C.B."
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--tccbCommission"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="saleValue"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Valor venda (R$):"
										placeholder="Valor venda"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={onChange}
										value={value || ''}
										data-testid="input--saleValue"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="quantitySale"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Quantidade venda:"
										placeholder="Quantidade venda"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--quantitySale"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="totalMargin"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Margem total (R$):"
										placeholder="Margem total"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--totalMargin"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="financingIncome"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Receita financiamento (R$):"
										placeholder="Receita financiamento"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--financingIncome"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="salaryDispatcherRevenue"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label={`Receita \n despachante (R$):`}
										placeholder="Receita despachante"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--salaryDispatcherRevenue"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="revenueTccb"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Receita T.C.C.B. (R$):"
										placeholder="Receita T.C.C.B."
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--revenueTccb"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="fundingAward"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Prêmio financiamento (R$):"
										placeholder="Prêmio financiamento"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--fundingAward"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="dispatcherAward"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label={`Prêmio \n despachante (R$):`}
										placeholder="Prêmio despachante"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--dispatcherAward"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="tccbAward"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Prêmio T.C.C.B. (R$):"
										placeholder="Prêmio T.C.C.B."
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--tccbAward"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="salarySaleAccessories"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label={`Venda \n acessórios (R$):`}
										placeholder="Venda acessórios"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--salarySaleAccessories"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="salaryCaptureAward"
								defaultValue={defaultValues?.salaryCaptureAward}
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Premiação por captura (R$):"
										placeholder="Premiação por captura"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--captureAward"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="dsr"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="DSR (R$):"
										placeholder="DSR"
										formLabelVariant="small"
										inputVariant="small"
										disabled
										isReadOnly
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--dsr"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="calendarDays"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Dias de calendário:"
										placeholder="Dias de calendário"
										formLabelVariant="small"
										inputVariant="small"
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										onKeyUp={debounceCalc}
										value={value || ''}
										errorMessage={errors.calendarDays?.message}
										data-testid="input--calendarDays"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem mb="1rem">
							<Controller
								name="sundayHolidays"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										label="Domingos e feriados:"
										placeholder="Domingos e feriados"
										formLabelVariant="small"
										inputVariant="small"
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										onKeyUp={debounceCalc}
										value={value || ''}
										errorMessage={errors.sundayHolidays?.message}
										data-testid="input--sundayHolidays"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>

				<Flex sx={styles.buttonContainer}>
					<Button variant="outlined" sx={styles.button} data-testid="button--cancel" onClick={handlePrevStep}>
						Anterior
					</Button>
					<Button sx={styles.button} type="submit" data-testid="button--submit" isLoading={isLoadingButton}>
						{isEditing ? 'Salvar' : 'Cadastrar'}
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default WageForm;
