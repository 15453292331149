/**
 *
 * EditIndicators
 *
 */

import { useMemo } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import IndicatorsForm, { IIndicatorsForm } from 'app/components/IndicatorsForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { editIndicator, getIndicatorById } from 'services/http/indicator';
import { IRequestIndicator, IResponseIndicator } from 'types/indicator';
import { ResponseErrors, parseErrors } from 'utils/parseErrors';

const EditIndicator = () => {
	const { indicatorId = '' } = useParams();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { data: indicator, isLoading: loadingIndicator } = useQuery<IResponseIndicator, AxiosError<ResponseErrors>>(
		['indicatorById', indicatorId],
		() => getIndicatorById(indicatorId),
		{
			onError: (errors: any) =>
				addToast({
					type: 'error',
					title: 'Erro!',
					description: parseErrors(errors?.response?.data),
				}),
			enabled: !!indicatorId,
		},
	);

	const defaultValues = useMemo(() => {
		return {
			name: { label: indicator?.name || '', value: '' },
			active: indicator?.active ?? true,
			companies: indicator?.companies.map(e => e?.id!.toString()) || [],
		};
	}, [indicator]);

	const { mutate: updateIndicatorMutate } = useMutation<
		IResponseIndicator,
		AxiosError<ResponseErrors>,
		IRequestIndicator
	>(data => editIndicator(data, indicatorId), {
		onSuccess: () => {
			navigate(ROUTES.listIndicators);
		},
		onError: errors =>
			addToast({
				type: 'error',
				title: 'Erro!',
				description: parseErrors(errors?.response?.data),
			}),
	});

	const handleOnSubmit = (data: IIndicatorsForm) => {
		const payload: IRequestIndicator = {
			...data,
			name: data.name.label,
		};

		updateIndicatorMutate(payload);
	};
	return (
		<Flex mt="6rem" justifyContent="center">
			{loadingIndicator ? (
				<Flex data-testid="loading-indicator">
					<Spinner />
				</Flex>
			) : (
				<IndicatorsForm onSubmit={handleOnSubmit} isEditing defaultValues={defaultValues} />
			)}
		</Flex>
	);
};

export default EditIndicator;
