import { IFlow } from '../Home';
import { viewSaleChannelFlowPDF } from '../ViewSalesChannelFlow';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Flex, Heading, Link, Spinner, SystemStyleObject, Text, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttentionPoints from 'app/components/AttentionPoints';
import FormsChannelFlow, { flowSchema } from 'app/components/FormsChannelFlow';
import IndicatorsModal from 'app/components/IndicatorsModal';
import Select, { IOption } from 'app/components/Select';
import SellersIndicatorsModal from 'app/components/SellersIndicatorsModal';
import ServiceChannelsTable from 'app/components/ServiceChannelsTable';
import Stepper from 'app/components/Stepper';
import TableGroup, { IColumnTableGroup, IRowTableGroup } from 'app/components/TableGroup';
import UserFilter from 'app/components/UserFilter';
import WageForm from 'app/components/WageForm';
import { ArrowLeftIcon, ViewIndicatorIcon, ViewSellersIndicatorIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { format } from 'date-fns';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCommissions } from 'services/http/commission';
import { getAllCompanies, IResponseCompanies } from 'services/http/company';
import {
	createFlow,
	getAttendenceSimulatorValues,
	getFlowById,
	getIndicators,
	getSalaryCalculationValues,
	IMonthsOfTheYear,
	IRequestCreateFlow,
	IResponseAttendenceSimulatorValues,
	IResponseCreateFlow,
	IResponseGetFlowById,
	IResponseGetIndicators,
	IResponseSalaryCalculationValues,
} from 'services/http/flow';
import { IResponseIndicatorsExpanded, getIndicatorsExpanded } from 'services/http/history';
import { getListEmployeesByCompany } from 'services/http/user';
import { IPaginatedCommissions } from 'types/commission';
import { User } from 'types/user';
import {
	DEPARTMENT_OPTION_LABEL_TO_ACCESS_LEVELS,
	INDICATOR_TO_SEGMENT,
	SEGMENT_TABLE,
	SEGMENT_TYPE_CURRENCY,
	SEGMENT_TYPE_PERCENTAGE,
	SEGMENT_TYPE_STRING,
	TOTAL_PERCENTAGE,
} from 'utils/constants';
import { multiplyPercentageFields } from 'utils/form';
import { generateMonths } from 'utils/generateMonthsForTable';
import { handleDisableSelect } from 'utils/handlePermissions';
import { formatDecimalNumber, formatNumbers, unMaskThousands } from 'utils/Numbers';
import { parseErrors, parseErrorsFlow, ResponseErrors } from 'utils/parseErrors';
import {
	commissionOnMarginHelper,
	commissionOnSaleHelper,
	financingAwardHelper,
	formattedCommission,
	forwardingAndTccbAwardHelper,
} from 'utils/wageHelpers';
import * as yup from 'yup';

const RegisterSalesChannelFlow = () => {
	const styles: Record<string, SystemStyleObject> = {
		wrapperContainer: {
			padding: {
				base: '0 1.8rem',
				sm: '0 2.8rem',
				md: '0 2.5rem',
			},
			width: '100%',
			maxWidth: '143.313rem',
			margin: '0 auto',
			mt: '3.125rem',
			mb: '3.125rem',
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
		},
		spinnerBox: {
			justifyContent: 'center',
		},
		container: {
			width: '100%',
			maxWidth: '143.313rem',
			mb: '3.125rem',
			justifyContent: 'center',
			flexDirection: 'column',
			gap: '2.5rem',
			mt: '2.5rem',
		},
		title: {
			fontWeight: '700',
			fontSize: '2.5rem',
		},
		wrapper: {
			w: '100%',
			gap: '1rem',
			height: 'max-content',
			position: 'relative',
			flexDirection: {
				base: 'column',
				'2xl': 'row',
			},
		},
		firstColumn: {
			flexDirection: 'column',
			gap: '2.5rem',
		},
		secundColumn: {
			w: {
				base: '100%',
				'2xl': '67%',
			},
			flexDirection: 'column',
			height: '100%',
			position: 'sticky',
			top: '0',
		},
		subTitle: {
			fontWeight: 600,
			fontSize: '1.875rem',
			textAlign: 'left',
			w: '100%',
		},
		firstTable: {
			flexDirection: 'column',
			gap: '2.5rem',
			mb: '2.688rem',
		},
		secundTable: {
			flexDirection: 'column',
		},
		filter: {
			flexDirection: 'column',
			mt: '2.5rem',
		},
		table: {
			maxH: '30rem',
		},
		successContainer: {
			flexDir: 'column',
			backgroundColor: 'white',
			borderColor: 'gray.300',
			borderWidth: '1px',
			borderRadius: '5px',
			padding: '2.5rem',
			alignItems: 'center',
		},
		successTitle: {
			fontWeight: '600',
			fontSize: '1.563rem',
			color: 'blue.800',
			mb: '1.5rem',
		},
		successButtons: {
			px: '1.688rem',
		},
		link: {
			display: 'flex',
			alignItems: 'center',
			color: 'black',
			fontSize: '1.5rem',
			gap: '0.5625rem',
		},
		button: {
			h: 'fit-content',
			color: '#2E769B',
			fontSize: '1.5rem',
			fontWeight: '600',
		},
		headerCustomStyle: { position: 'sticky', top: 0 },
	};

	const navigate = useNavigate();
	const { addToast } = useCustomToast();

	const {
		session: { user },
	} = useSession();

	const isSelectDisabled = handleDisableSelect(user);

	const { onOpen: onOpenIndicators, isOpen: isOpenIndicators, onClose: onCloseIndicators } = useDisclosure();
	const { onOpen: onOpenSellers, isOpen: isOpenSellers, onClose: onCloseSellers } = useDisclosure();
	const {
		isOpen: isOpenAttentionPoints,
		onOpen: onOpenAttentionPoints,
		onClose: onCloseAttentionPoints,
	} = useDisclosure();

	const [currentStep, setCurrentStep] = useState(0);
	const [formValues, setFormValues] = useState<IFlow>({});
	const [formCalc, setFormCalc] = useState<IFlow>({});

	let [searchParams] = useSearchParams();

	const company = searchParams.get('unidade') as string;
	const employeeId = searchParams.get('usuario') as string;
	const department = searchParams.get('departamento') as string;
	const access_profile =
		DEPARTMENT_OPTION_LABEL_TO_ACCESS_LEVELS[department as keyof typeof DEPARTMENT_OPTION_LABEL_TO_ACCESS_LEVELS];

	const { mutate: getFlowMutation, isLoading: isLoadingFlow } = useMutation<
		IResponseGetFlowById,
		AxiosError<ResponseErrors>,
		void
	>(() => getFlowById(String(flow?.id)), {
		onSuccess: data => {
			viewSaleChannelFlowPDF(data);
		},
		onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
	});

	const { data: companies, isLoading: isCompaniesLoading } = useQuery<
		IResponseCompanies,
		AxiosError<ResponseErrors>,
		IResponseCompanies
	>(['companies'], () => getAllCompanies(), {
		onError: error => addToast({ type: 'error', title: 'Erro!', description: parseErrors(error?.response?.data) }),
		refetchOnMount: true,
		staleTime: 0,
		cacheTime: 0,
	});

	const { selectedCompany, selectedCompanyForModal } = useMemo(() => {
		const selectedCompany = companies?.companiesObjects?.find(item => item.company === company);

		const selectedCompanyForModal: IOption = {
			label: selectedCompany?.company || '',
			value: selectedCompany?.id,
		};

		return { selectedCompany, selectedCompanyForModal };
	}, [companies?.companiesObjects, company]);

	const { data: employees, isLoading: isEmployeesLoading } = useQuery<User[], AxiosError<ResponseErrors>, User[]>(
		['employees', company, department],
		() => getListEmployeesByCompany({ company, access_profile }),
		{
			onError: errors =>
				addToast({
					type: 'error',
					title: 'Erro!',
					description: parseErrors(errors?.response?.data),
				}),
			staleTime: 0,
			cacheTime: 0,
		},
	);

	const { selectedEmployee, selectedEmployeeForModal } = useMemo(() => {
		const selectedEmployee = employees?.find(item => String(item.id) === employeeId) || ({} as User);

		const selectedEmployeeForModal: IOption = {
			label: selectedEmployee.username || '',
			value: selectedEmployee.id,
		};

		return { selectedEmployee, selectedEmployeeForModal };
	}, [employeeId, employees]);

	const { data: indicatorsExpandedData, isLoading: isIndicatorsExpandedLoading } = useQuery<
		IResponseIndicatorsExpanded,
		AxiosError<ResponseErrors>,
		IResponseIndicatorsExpanded
	>(
		['history-indicators-expanded', selectedEmployee.id],
		() => getIndicatorsExpanded({ userId: Number(selectedEmployee.id) }),
		{
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
			enabled: !!selectedEmployee.id,
		},
	);

	const { control, watch } = useForm<{ segment?: IOption | null }>({
		defaultValues: {
			segment: null,
		},
	});

	const schema = yup.object().shape(flowSchema);

	const registerForm = useForm<IFlow>({
		resolver: yupResolver(schema),
		defaultValues: formCalc,
	});

	const { data: indicators } = useQuery<IResponseGetIndicators, AxiosError<ResponseErrors>, IResponseGetIndicators>(
		['indicators'],
		() => getIndicators(),
		{
			onError: errors =>
				addToast({
					type: 'error',
					title: 'Erro!',
					description: parseErrors(errors?.response?.data),
				}),
		},
	);

	const segments: IOption[] = useMemo(() => {
		return Object.entries(indicators || []).map(([key, val]) => {
			return {
				value: key,
				label: val,
			};
		});
	}, [indicators]);

	const monthsAmount = 13;
	const months = generateMonths(monthsAmount);

	const filteredSellerTrackingTable = (filter: string) => {
		const selectedValue = INDICATOR_TO_SEGMENT[filter];
		let values: Record<string, string> = {};

		const filteredValueIsString = SEGMENT_TYPE_STRING.find(item => item === selectedValue);
		const filteredValueIsPercentage = SEGMENT_TYPE_PERCENTAGE.find(item => item === selectedValue);
		const filteredValueIsCurrency = SEGMENT_TYPE_CURRENCY.find(item => item === selectedValue);

		if (filteredValueIsString) {
			values = {
				indicadores: SEGMENT_TABLE[filteredValueIsString],
				nisa: '',
				...generateTableData(filteredValueIsString),
			};
		}

		if (filteredValueIsPercentage) {
			values = {
				indicadores: SEGMENT_TABLE[filteredValueIsPercentage],
				nisa: '',
				...generateTableData(filteredValueIsPercentage, true),
			};
		}

		if (filteredValueIsCurrency) {
			values = {
				indicadores: SEGMENT_TABLE[filteredValueIsCurrency],
				nisa: '',
				...generateTableData(filteredValueIsCurrency, false, true),
			};
		}

		let data: IRowTableGroup[] = [
			{
				showHeaderGroup: false,
				children: [values],
			},
		];

		return data;
	};

	const updateIndicator = (indicator: IMonthsOfTheYear) => {
		const updatedIndicator: IMonthsOfTheYear = { average: indicator?.average * 100 };
		months.forEach(({ key: monthkey }) => {
			updatedIndicator[monthkey] = indicator?.[monthkey] * 100;
		});
		return updatedIndicator;
	};

	const generateTableData = useCallback(
		(key: string, formatNumber = false, formatCurrency = false) => {
			let indicatorsExpandedDataFormated = { ...indicatorsExpandedData };

			if (indicatorsExpandedData)
				indicatorsExpandedDataFormated = {
					...indicatorsExpandedData,
					txEvaluation: updateIndicator(indicatorsExpandedData.txEvaluation),
					txPurchase: updateIndicator(indicatorsExpandedData.txPurchase),
					txCapture: updateIndicator(indicatorsExpandedData.txCapture),
					conversion: updateIndicator(indicatorsExpandedData.conversion),
				};

			const isInteger = !formatNumber && !formatCurrency;
			let decimalPlaces = isInteger ? 0 : 2;

			if (isInteger && indicatorsExpandedDataFormated?.[key]?.['average'])
				indicatorsExpandedDataFormated[key]['average'] = Math.round(indicatorsExpandedDataFormated?.[key]?.['average']);

			let results = {};

			if (!indicatorsExpandedDataFormated || !Object.keys(indicatorsExpandedDataFormated).length) return [];

			results = {
				nisa: formatNumber
					? formatDecimalNumber(indicatorsExpandedDataFormated?.[key]?.['average'], '%')
					: formatCurrency
					? formatNumbers(indicatorsExpandedDataFormated?.[key]?.['average'] || 0, true)
					: indicatorsExpandedDataFormated?.[key]?.['average']
					? formatNumbers(indicatorsExpandedDataFormated?.[key]?.['average'] || 0, false, decimalPlaces)
					: '',
			};

			months?.forEach(({ key: monthkey }) => {
				results = {
					...results,
					[monthkey]: formatNumber
						? formatDecimalNumber(Number(indicatorsExpandedDataFormated?.[key]?.[monthkey]) || 0, '%')
						: formatCurrency
						? formatNumbers(indicatorsExpandedDataFormated?.[key]?.[monthkey] || 0, true)
						: String(indicatorsExpandedDataFormated?.[key]?.[monthkey] || ''),
				};
			});

			return results;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[indicatorsExpandedData, months],
	);

	const indicatorsTable = useMemo(() => {
		return {
			columns: [
				{
					label: 'INDICADORES',
					key: 'indicadores',
				},
				{
					label: 'NISA',
					key: 'nisa',
					columnCustomStyle: {
						textAlign: 'center',
					},
				},
				...months,
			] as IColumnTableGroup[],
			data: [
				{
					showHeaderGroup: false,
					children: [
						{
							indicadores: 'Fluxo de Loja (Passantes)',
							...generateTableData('fluxoLojaPassante'),
						},
						{
							indicadores: 'Fluxo de Ligaçoes Recebidas',
							...generateTableData('fluxoLigacoes'),
						},
						{
							indicadores: 'Comparecimento Ligações',
							...generateTableData('comparecimentoLigacoes'),
						},
						{
							indicadores: 'Fluxo Loja Vendedor',
							...generateTableData('fluxoLojaVendedor'),
						},
						{
							indicadores: 'Ligações Recebidas',
							...generateTableData('ligacoesRecebidas'),
						},
						{
							indicadores: 'Comparecimento',
							...generateTableData('comparecimento'),
						},
						{
							indicadores: 'Conversão',
							...generateTableData('conversion', true),
						},
					],
					columnCustomStyle: {
						backgroundColor: 'red',
					},
				},
				{
					showHeaderGroup: true,
					indicadores: 'VENDAS TOTAL',
					...generateTableData('totalSales'),
					children: [
						{
							indicadores: 'Faturamento',
							...generateTableData('billings', false, true),
						},
						{
							indicadores: 'Ticket Médio',
							...generateTableData('averageTicket', false, true),
						},
						{
							indicadores: 'Despesas com Cortesias',
							...generateTableData('complimentaryExpenses', false, true),
						},
						{
							indicadores: '% M.B',
							...generateTableData('percentageMb', true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'FINANCIAMENTO',
					...generateTableData('financing', false, true),

					children: [
						{
							indicadores: 'Contratos Financiamento',
							...generateTableData('financingContracts'),
						},
						{
							indicadores: 'Penetracion Financiamento',
							...generateTableData('financingPenetration', true),
						},
						{
							indicadores: 'Financiamento fora',
							...generateTableData('financingEvasion', true),
						},
						{
							indicadores: 'Penetration Valor Financiado',
							...generateTableData('financingValuePenetration', true),
						},
						{
							indicadores: 'Retorno por Carro',
							...generateTableData('carReturn', false, true),
						},
						{
							indicadores: 'Rentabilidade Financiamento',
							...generateTableData('financingProfitability', true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'TCCB',
					...generateTableData('tccb', true),

					children: [
						{
							indicadores: 'TCCB por Contrato',
							...generateTableData('tccbByContract', false, true),
						},
						{
							indicadores: 'TCCB por Carro',
							...generateTableData('tccbByCar', false, true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'Emplacamento',
					...generateTableData('placement', true),

					children: [
						{
							indicadores: 'Emplacamento por carro',
							...generateTableData('placementByCar', false, true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'Captação',
					...generateTableData('capture'),

					children: [
						{
							indicadores: 'Avaliação',
							...generateTableData('evaluation'),
						},
						{
							indicadores: 'Taxa avaliação',
							...generateTableData('txEvaluation', true),
						},
						{
							indicadores: 'Taxa compra',
							...generateTableData('txPurchase', true),
						},
						{
							indicadores: 'Taxa captura',
							...generateTableData('txCapture', true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'Margem de contribuição',
					...generateTableData('contributionMargin', false, true),

					children: [
						{
							indicadores: 'Lucro Bruto Total',
							...generateTableData('contributionMargin2', false, true),
						},
						{
							indicadores: '(%) Lucro Bruto s/Faturamento',
							...generateTableData('contributionMargin3', true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'Acessórios',
					...generateTableData('accessoriesSale', false, true),

					children: [
						{
							indicadores: 'Ticket Médio Acessorios',
							...generateTableData('averageTicketAccessories', false, true),
						},
					],
				},
				{
					showHeaderGroup: true,
					indicadores: 'HGSI',
					...generateTableData('hgsi', true),
				},
			] as IRowTableGroup[],
		};
	}, [generateTableData, months]);

	const segmentValue = watch('segment')?.value;

	const indicatorsTableData = useMemo(
		() => {
			const segmentValue = watch('segment')?.value;

			return segmentValue ? filteredSellerTrackingTable(String(segmentValue)) : indicatorsTable?.data;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[watch('segment'), indicatorsTable?.data],
	);

	const { data: attendenceSimulator } = useQuery<
		IResponseAttendenceSimulatorValues,
		AxiosError<ResponseErrors>,
		IResponseAttendenceSimulatorValues
	>([employeeId, 'atendenceSimulator'], () => getAttendenceSimulatorValues(Number(employeeId)), {
		onError: errors =>
			addToast({
				type: 'error',
				title: 'Erro!',
				description: parseErrors(errors?.response?.data),
			}),
		enabled: !!employeeId,
	});

	const { data: salaryCalculationValues } = useQuery<
		IResponseSalaryCalculationValues,
		AxiosError<ResponseErrors>,
		IResponseSalaryCalculationValues
	>(['salaryCalculationValues'], () => getSalaryCalculationValues(), {
		onError: errors =>
			addToast({
				type: 'error',
				title: 'Erro!',
				description: parseErrors(errors?.response?.data),
			}),
	});

	const { data: commissions } = useQuery<IPaginatedCommissions, AxiosError<ResponseErrors>, IPaginatedCommissions>(
		['commission', selectedCompany?.id, selectedEmployee.department],
		() => getCommissions({ company: selectedCompany?.id, department: selectedEmployee?.department }),
		{
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
			enabled: !!selectedCompany?.id && !!selectedEmployee.department,
		},
	);

	const commissionValues = useMemo(() => {
		const commission = commissions?.results.find(item => item);

		const commissionScales = {
			scale1: commission?.scale1,
			scale2: commission?.scale2,
			scale3: commission?.scale3,
			scale4: commission?.scale4,
			scale5: commission?.scale5,
		};

		const commissionOnSale = commission?.indicatorComissionValues.find(item => item.indicator.name === 'Faturamento');
		const commissionOnMargin = commission?.indicatorComissionValues.find(
			item => item.indicator.name === 'Margem de Contribuição',
		);
		const commissionOnFinancing = commission?.indicatorComissionValues.find(
			item => item.indicator.name === 'Financiamento',
		);
		const commissionOnDispatcher = commission?.indicatorComissionValues.find(
			item => item.indicator.name === 'Emplacamento',
		);
		const commissionOnTCCB = commission?.indicatorComissionValues.find(item => item.indicator.name === 'TCCB');

		return {
			commissionScales,
			commissionOnSale,
			commissionOnMargin,
			commissionOnFinancing,
			commissionOnDispatcher,
			commissionOnTCCB,
		};
	}, [commissions?.results]);

	const handleCommission = useCallback(
		(totalSale: number, type: 'Sale' | 'Margin' | 'Financing' | 'Dispatcher' | 'TCCB') => {
			const { scale1, scale2, scale3, scale4, scale5 } = commissionValues.commissionScales;

			const commission = commissionValues?.[`commissionOn${type}`];

			let value;

			if (commission) {
				if (scale1 && totalSale < scale1) value = Number(commission?.base);
				if (scale1 && totalSale >= scale1) value = Number(commission?.scale1);
				if (scale2 && totalSale >= scale2) value = Number(commission?.scale2);
				if (scale3 && totalSale >= scale3) value = Number(commission?.scale3);
				if (scale4 && totalSale >= scale4) value = Number(commission?.scale4);
				if (scale5 && totalSale >= scale5) value = Number(commission?.scale5);
			}

			return value;
		},
		[commissionValues],
	);

	const {
		mutate: createFlowMutate,
		isSuccess: isCreateFlowSuccess,
		isLoading: isLoadingCreateFlow,
		data: flow,
	} = useMutation<IResponseCreateFlow, AxiosError<ResponseErrors>, IRequestCreateFlow>(data => createFlow(data), {
		onError: errors => {
			addToast({
				type: 'error',
				title: 'Erro!',
				description: parseErrorsFlow(errors?.response?.data),
			});
		},
	});

	const onSubmitForm = (data: IFlow) => {
		setFormValues(prevState => ({ ...prevState, ...data }));
	};

	const onCreateFlowMutate = (data: IFlow) => {
		if (!employeeId)
			return addToast({
				type: 'warning',
				title: 'Alerta',
				description: 'Selecione um vendedor para continuar!',
			});

		const selectedCompany = companies?.companiesObjects?.find(cpn => cpn?.company === company);
		onSubmitForm(data);
		createFlowMutate({
			shopFlow: {
				passingShopFlow: data.passingShopFlow || 0,
				shopSellers: data.shopSellers || 0,
				customerServiceQuantity: data.shopFlowCustomerServiceQuantity || 0,
			},
			calls: {
				incomingCalls: data.incomingCalls || 0,
				attendance: unMaskThousands(data.attendance || 0),
				customerServiceQuantity: data.callsCustomerServiceQuantity || 0,
			},
			prospection: {
				prospection: data.prospection || 0,
				conversionOnFlow: String(unMaskThousands(data.conversionOnFlow || 0)),
				customerServiceQuantity: data.prospectionCustomerServiceQuantity || 0,
			},
			leads: {
				leads: data.leads || 0,
				conversionOnFlow: String(unMaskThousands(data.leadsConversionOnFlow || 0)),
				customerServiceQuantity: data.leadsCustomerServiceQuantity || 0,
			},
			workshop: {
				customerWorkshop: data.customerWorkshop || 0,
				conversionEvaluation: String(unMaskThousands(data.conversionEvaluation || 0)),
				quantity: data.quantity || 0,
			},
			vehicleProfitability: {
				averageTicket: unMaskThousands(data.averageTicket || 0),
				margin: String(unMaskThousands(data.margin || 0)),
				validGift: data.validGift || 0,
				unitGrossMargin: unMaskThousands(data.unitGrossMargin || 0),
				totalMargin: unMaskThousands(data.vehicleProfitabilityTotalMargin || 0),
			},
			financing: {
				financing: data.financing || 0,
				penetrationAmount: String(unMaskThousands(data.financingPenetrationAmount || 0)),
				financedAmount: String(unMaskThousands(data.financedAmount || 0)),
				rApplied: String(unMaskThousands(data.rApplied || 0)),
				averageContractRevenue: unMaskThousands(data.averageContractRevenue || 0),
				financingIncome: unMaskThousands(data.financingFinancingIncome || 0),
			},
			tccb: {
				penetrationAmount: String(unMaskThousands(data.tccbPenetrationAmount || 0)),
				contracts: data.contracts || 0,
				averageContractValue: unMaskThousands(data.averageContractValue || 0),
				revenueTccb: unMaskThousands(data.tccbRevenueTccb || 0),
			},
			priceOfPlacement: {
				averageSaleValue: unMaskThousands(data.averageSaleValue || 0),
				averageValueCost: unMaskThousands(data.averageValueCost || 0),
				profitDocument: unMaskThousands(data.profitDocument || 0),
			},
			attentionPoints: data?.attentionPoints?.map(item => item?.point!),
			dispatcher: {
				penetrationAmount: String(unMaskThousands(data.penetrationAmount || 0)),
				averageCarRevenue: unMaskThousands(data.averageCarRevenue || 0),
				dispatcherRevenue: unMaskThousands(data.dispatcherRevenue || 0),
			},
			accessories: {
				unitSales: unMaskThousands(data.unitSales || 0),
				saleAccessories: unMaskThousands(data.accessoriesSaleAccessories || 0),
			},
			usedPickup: {
				assessmentRate: String(unMaskThousands(data.assessmentRate || 0)),
				reviewsAmount: data.reviewsAmount || 0,
				fundingRate: String(unMaskThousands(data.fundingRate || 0)),
				totalCatch: data.totalCatch || 0,
				captureAward: data.usedPickupCaptureAward || 0,
			},
			salary: {
				totalSalary: data.totalSalary || 0,
				commissionOnSale: data.commissionOnSale?.toFixed(2) || '0',
				comissionOnMargin: data.comissionOnMargin?.toFixed(2) || '0',
				financingCommission: data.financingCommission?.toFixed(2) || '0',
				forwardingCommission: data.forwardingCommission?.toFixed(2) || '0',
				tccbCommission: data.tccbCommission?.toFixed(2) || '0',
				saleValue: data.saleValue || 0,
				quantitySale: data.quantitySale || 0,
				totalMargin: data.totalMargin || 0,
				financingIncome: data.financingIncome || 0,
				dispatcherRevenue: data.salaryDispatcherRevenue || 0,
				revenueTccb: data.revenueTccb || 0,
				fundingAward: data.fundingAward || 0,
				dispatcherAward: data.dispatcherAward || 0,
				tccbAward: data.tccbAward || 0,
				saleAccessories: data.salarySaleAccessories || 0,
				captureAward: data.salaryCaptureAward || 0,
				dsr: data.dsr || 0,
				calendarDays: data.calendarDays || 0,
				sundayHolidays: data.sundayHolidays || 0,
			},
			company: {
				company: selectedCompany?.company || '',
				autoAvaliarCompanyName: selectedCompany?.autoAvaliarCompanyName || '',
				nisaBiCompanyName: selectedCompany?.nisaBiCompanyName || '',
				nisaSyonetCompanyId: selectedCompany?.nisaSyonetCompanyId || 0,
			},
			user: Number(employeeId),
			monthYear: String(format(new Date(), 'yyyy-MM-dd')),
			serviceChannels: {
				passingShopFlow: {
					nome: 'Fluxo de loja passante',
					atendimentos: data.shopFlowCustomerServiceQuantity || 0,
					conversao: unMaskThousands(data.flowConversion || 0),
					vendas: data.flowSales || 0,
				},
				attendanceCalls: {
					nome: 'Comparecimento ligações',
					atendimentos: data.callsCustomerServiceQuantity || 0,
					conversao: unMaskThousands(data.callAttendanceConversion || 0),
					vendas: data.callAttendanceSales || 0,
				},
				prospection: {
					nome: 'Prospecção',
					atendimentos: data.prospectionCustomerServiceQuantity || 0,
					conversao: unMaskThousands(data.prospectingConversion || 0),
					vendas: data.prospectingSales || 0,
				},
				leads: {
					nome: 'Leads',
					atendimentos: data.leadsCustomerServiceQuantity || 0,
					conversao: unMaskThousands(data.leadsConversion || 0),
					vendas: data.leadsSales || 0,
				},
				customerWorkshop: {
					nome: 'Cliente oficina',
					atendimentos: data.quantity || 0,
					conversao: unMaskThousands(data.workshopCustomerConversion || 0),
					vendas: data.workshopCustomerSales || 0,
				},
				total: {
					nome: 'Total',
					atendimentos: data.totalCalls || 0,
					conversao: data.totalConversion || 0,
					vendas: data.totalSales || 0,
				},
			},
		});
	};

	const averageQuatitySeller = Math.round(
		Number(attendenceSimulator?.averageStoreFlowPassing) / Number(attendenceSimulator?.totalSellersInCompany),
	);

	const calcFields = useCallback(() => {
		// StoreFlowForm -> CALCULOS

		//CÁLCULO FLUXO LOJA QUANTIDADE ATENDIMENTOS
		const shopFlowCustomerServiceQuantity = Math.round(
			Number(formValues?.passingShopFlow || 0) / Number(formValues?.shopSellers || 1),
		);

		//CÁLCULO LIGAÇÕES QUANTIDADE ATENDIMENTOS
		const callsCustomerServiceQuantity = Math.round(
			multiplyPercentageFields(formValues?.incomingCalls, formValues?.attendance),
		);

		//CÁLCULO PROSPECÇÃO QUANTIDADE ATENDIMENTOS
		const prospectionCustomerServiceQuantity = Math.round(
			multiplyPercentageFields(formValues?.prospection, formValues?.conversionOnFlow),
		);

		//CÁLCULO LEADS QUANTIDADE ATENDIMENTOS
		const leadsCustomerServiceQuantity = Math.round(
			multiplyPercentageFields(formValues?.leads, formValues?.leadsConversionOnFlow),
		);

		//CÁLCULO CLIENTE OFICINA QUANTIDADE ATENDIMENTOS
		const quantity = Math.round(
			multiplyPercentageFields(formValues?.customerWorkshop, formValues?.conversionEvaluation),
		);

		// ServiceChannelsTable -> CALCULOS

		//CÁLCULO TOTAL ATENDIMENTOS
		const totalCalls =
			shopFlowCustomerServiceQuantity +
			callsCustomerServiceQuantity +
			prospectionCustomerServiceQuantity +
			leadsCustomerServiceQuantity +
			quantity;

		//CÁLCULO VENDAS DE FLUXO DE LOJA PASSANTES
		const flowSales = Math.floor(
			Number(shopFlowCustomerServiceQuantity || 0) *
				(Number(unMaskThousands(formValues.flowConversion || 0)) / TOTAL_PERCENTAGE),
		);

		//CÁLCULO VENDAS DE COMPARECIMENTO LIGAÇÕES
		const callAttendanceSales = Math.floor(
			Number(callsCustomerServiceQuantity || 0) *
				(Number(unMaskThousands(formValues.callAttendanceConversion || 0)) / TOTAL_PERCENTAGE),
		);

		//CÁLCULO VENDAS DE PROSPECÇÃO
		const prospectingSales = Math.floor(
			Number(prospectionCustomerServiceQuantity || 0) *
				(Number(unMaskThousands(formValues.prospectingConversion || 0)) / TOTAL_PERCENTAGE),
		);

		//CÁLCULO VENDAS DE LEADS
		const leadsSales = Math.floor(
			Number(leadsCustomerServiceQuantity || 0) *
				(Number(unMaskThousands(formValues.leadsConversion || 0)) / TOTAL_PERCENTAGE),
		);

		//CÁLCULO VENDAS DE LEADS
		const workshopCustomerSales = Math.floor(
			Number(quantity || 0) * (Number(unMaskThousands(formValues.workshopCustomerConversion || 0)) / TOTAL_PERCENTAGE),
		);

		//CÁLCULO TOTAL VENDA
		const totalSales = flowSales + callAttendanceSales + prospectingSales + leadsSales + workshopCustomerSales;

		//CÁLCULO TOTAL CONVERSÃO
		const totalConversion = Math.round((totalSales / totalCalls) * TOTAL_PERCENTAGE);

		// VehicleProfitabilityForm -> CALCULOS

		// Cálculo Margem Bruta por Unidade
		const unitGrossMargin =
			Math.round(
				(Number(unMaskThousands(formValues?.averageTicket || 0)) * Number(unMaskThousands(formValues.margin || 0))) /
					TOTAL_PERCENTAGE,
			) - Number(formValues.validGift || 0);

		// Cálculo Margem Total
		const vehicleProfitabilityTotalMargin = Math.round(unMaskThousands(unitGrossMargin || 0)) * Number(totalSales);

		// Cálculo Financiamento
		const financing = Math.floor(multiplyPercentageFields(Number(totalSales), formValues?.financingPenetrationAmount));

		// Cálculo Receita média/Contrato
		const A = Math.round(
			(Number(totalSales) * unMaskThousands(formValues.financingPenetrationAmount || 0)) / TOTAL_PERCENTAGE,
		);
		const B1 = A * Number(unMaskThousands(formValues.averageTicket || 0));
		const B2 = (B1 * Number(unMaskThousands(formValues.financedAmount || 0))) / TOTAL_PERCENTAGE;
		const B3 = (B2 * Number(unMaskThousands(formValues.rApplied || 0))) / TOTAL_PERCENTAGE;
		const Y = Math.round(
			(Number(totalSales) * unMaskThousands(formValues.financingPenetrationAmount || 0)) / TOTAL_PERCENTAGE,
		);
		const averageContractRevenue = B3 / Y || 0;

		// Cálculo Receita Financiamento
		const financingFinancingIncome = Number(unMaskThousands(averageContractRevenue || 0)) * Number(financing);

		// Cálculo Contratos
		const contracts = Math.floor(
			(Number(financing) * unMaskThousands(formValues.tccbPenetrationAmount || 0)) / TOTAL_PERCENTAGE,
		);

		// Cálculo Receita T.C.C.B
		const tccbRevenueTccb = unMaskThousands(formValues.averageContractValue || 0) * Number(contracts);

		// SalesPriceForm -> CALCULOS

		//CÁLCULO LUCRO DOCUMENTO
		const profitDocument =
			unMaskThousands(formValues.averageSaleValue || 0) - unMaskThousands(formValues.averageValueCost || 0);

		// DispatcherForm -> CALCULOS

		//CÁLCULO RECEITA MEDIA CARRO
		const averageCarRevenue = profitDocument;

		//CÁLCULO RECEITA DESPACHANTE
		const dispatcherRevenue =
			Math.round(
				Number(totalSales) * (Number(unMaskThousands(formValues?.penetrationAmount || 0)) / TOTAL_PERCENTAGE),
			) * averageCarRevenue;

		// //CÁLCULO RECEITA MEDIA CARRO
		// const averageCarRevenue =
		// 	(Number(totalSales) *
		// 		(Number(unMaskThousands(formValues?.penetrationAmount || 0)) / TOTAL_PERCENTAGE) *
		// 		Number(profitDocument)) /
		// 	Number(totalSales);

		// //CÁLCULO RECEITA DESPACHANTE
		// const dispatcherRevenue = averageCarRevenue * Number(totalSales);

		//CÁLCULO VENDA ACESSORIOS
		const accessoriesSaleAccessories = unMaskThousands(formValues?.unitSales || 0) * Number(totalSales || 0);

		//CÁLCULO QUANTIDADE AVALIACOES
		const reviewsAmount = Math.round(
			Number(totalCalls || 0) * Number(unMaskThousands(formValues?.assessmentRate || 0) / TOTAL_PERCENTAGE),
		);

		//CÁLCULO TOTAL CAPTURA
		const totalCatch = Math.round(
			unMaskThousands(formValues?.fundingRate || 0) * (Number(unMaskThousands(reviewsAmount || 0)) / TOTAL_PERCENTAGE),
		);

		//CÁLCULO PREMIACAO CAPTURA
		const usedPickupCaptureAward = unMaskThousands(formValues?.assessmentRate || 0) > 54 ? TOTAL_PERCENTAGE : 0;

		// WageForm -> CALCULOS

		//QUANTIDADE DE VENDAS
		const quantitySale = totalSales;

		// RECEITA FINANCIAMENTO → Receita financiamento (%)
		const financingIncome = financingFinancingIncome;

		// RECEITA DESPACHANTE → Receita despachante (%)
		const salaryDispatcherRevenue = dispatcherRevenue;

		// RECEITA T.C.C.B. → Receita T.C.C.B. (%)
		const revenueTccb = tccbRevenueTccb;

		// Cálculo Valor venda
		const saleValue = Number(formValues?.averageTicket) * Number(totalSales);

		// Cálculo Comissão sobre venda
		const commissionOnSaleAPercentage = commissionOnSaleHelper(Number(totalSales));
		const commissionOnSaleBPercentage =
			handleCommission(totalSales, 'Sale') || Number(salaryCalculationValues?.commissionOnSale);
		const commissionOnSalePercentage = commissionOnSaleAPercentage + commissionOnSaleBPercentage;
		const commissionOnSale = unMaskThousands(formattedCommission(commissionOnSalePercentage, saleValue));

		// Cálculo Margem total
		const totalMargin = Number(unitGrossMargin) * Number(quantitySale);

		// Cálculo Comissão sobre margem
		const commissionOnMarginAPercentage = commissionOnMarginHelper(Number(totalSales));
		const commissionOnMarginBPercentage =
			handleCommission(totalSales, 'Margin') || Number(salaryCalculationValues?.comissionOnMargin);
		const comissionOnMarginPercentage = commissionOnMarginAPercentage + commissionOnMarginBPercentage;
		const comissionOnMargin = unMaskThousands(formattedCommission(comissionOnMarginPercentage, totalMargin));

		// Cálculo Comissão receita financiamento
		const financingCommissionPercentage =
			handleCommission(totalSales, 'Financing') || salaryCalculationValues?.financingIncome;
		const financingCommission = unMaskThousands(formattedCommission(financingCommissionPercentage!, financingIncome));

		// Cálculo Comissão receita despachante
		const forwardingCommissionPercentage =
			handleCommission(totalSales, 'Dispatcher') || salaryCalculationValues?.dispatcherRevenue;
		const forwardingCommission = unMaskThousands(
			formattedCommission(forwardingCommissionPercentage!, salaryDispatcherRevenue),
		);

		// Cálculo Comissão receita T.C.C.B
		const commissionOnTCCBPercentage =
			handleCommission(totalSales, 'TCCB') || Number(salaryCalculationValues?.revenueTccb);

		const tccbCommission = unMaskThousands(formattedCommission(commissionOnTCCBPercentage!, revenueTccb));

		// Cálculo Prêmio financiamento
		const financingAwardA = financingAwardHelper(Number(totalSales));
		const fundingAward = (financingAwardA / TOTAL_PERCENTAGE) * Number(financingFinancingIncome);

		// Cálculo Prêmio despachante
		const forwardingAwardA = forwardingAndTccbAwardHelper(Number(totalSales));
		const dispatcherAward = (forwardingAwardA / TOTAL_PERCENTAGE) * Number(dispatcherRevenue);

		// Cálculo Prêmio T.C.C.B
		const tccbAwardA = forwardingAndTccbAwardHelper(Number(totalSales));
		const tccbAward = (tccbAwardA / TOTAL_PERCENTAGE) * Number(tccbRevenueTccb);

		// Cálculo Venda acessório
		const companySalesPercentage = Number(formValues?.unitSales) > 1990 ? 3.0 : 0.5;
		const salarySaleAccessories = Number(accessoriesSaleAccessories) * (companySalesPercentage / TOTAL_PERCENTAGE);

		// Cálculo Premiação por captura
		const salaryCaptureAward = Number(salaryCalculationValues?.captureAward) * Number(totalCatch);

		// Cálculo DSR
		const dsrPart1 = financingCommission + forwardingCommission + tccbCommission;
		const dsrPart2 = (totalMargin * comissionOnMarginPercentage) / TOTAL_PERCENTAGE;
		const dsrPart3 = saleValue * (commissionOnSalePercentage / TOTAL_PERCENTAGE);
		const dsrPart4 =
			(Number(formValues?.calendarDays) - Number(formValues?.sundayHolidays)) / Number(formValues?.sundayHolidays) || 1;

		const dsr = (dsrPart1 + dsrPart2 + dsrPart3) / dsrPart4;

		// // Cálculo Salário
		// const wagePart1 =
		// 	financingIncome +
		// 	salaryDispatcherRevenue +
		// 	revenueTccb +
		// 	fundingAward +
		// 	dispatcherAward +
		// 	tccbAward +
		// 	salarySaleAccessories +
		// 	salaryCaptureAward +
		// 	dsr;
		// const wagePart2 = (saleValue * commissionOnSalePercentage) / TOTAL_PERCENTAGE;
		// const wagePart3 = (totalMargin * comissionOnMarginPercentage) / TOTAL_PERCENTAGE;
		// const totalSalary = wagePart1 + wagePart2 + wagePart3;
		const totalSalary =
			commissionOnSale +
			comissionOnMargin +
			financingCommission +
			forwardingCommission +
			tccbCommission +
			fundingAward +
			salaryCaptureAward +
			dispatcherAward +
			tccbAward +
			salarySaleAccessories +
			dsr;

		setFormCalc({
			...formValues,
			shopFlowCustomerServiceQuantity,
			callsCustomerServiceQuantity,
			prospectionCustomerServiceQuantity,
			leadsCustomerServiceQuantity,
			quantity,
			totalCalls,
			flowSales,
			callAttendanceSales,
			prospectingSales,
			leadsSales,
			workshopCustomerSales,
			totalSales,
			totalConversion,
			unitGrossMargin,
			vehicleProfitabilityTotalMargin,
			financing,
			averageContractRevenue,
			financingFinancingIncome,
			contracts,
			tccbRevenueTccb,
			profitDocument,
			averageCarRevenue,
			dispatcherRevenue,
			accessoriesSaleAccessories,
			reviewsAmount,
			totalCatch,
			usedPickupCaptureAward,
			quantitySale,
			commissionOnSale,
			comissionOnMargin,
			financingCommission,
			forwardingCommission,
			tccbCommission,
			saleValue,
			totalMargin,
			financingIncome,
			salaryDispatcherRevenue,
			revenueTccb,
			fundingAward,
			dispatcherAward,
			tccbAward,
			salarySaleAccessories,
			salaryCaptureAward,
			dsr,
			totalSalary,
		});
	}, [
		formValues,
		handleCommission,
		salaryCalculationValues?.captureAward,
		salaryCalculationValues?.comissionOnMargin,
		salaryCalculationValues?.commissionOnSale,
		salaryCalculationValues?.dispatcherRevenue,
		salaryCalculationValues?.financingIncome,
		salaryCalculationValues?.revenueTccb,
	]);

	useEffect(() => {
		calcFields();
	}, [calcFields, formValues]);

	const onOpenAttentionPointsModal = () => {
		onOpenAttentionPoints();
	};

	const elements = [
		<FormsChannelFlow
			formChannelFlow={registerForm}
			attendenceSimulator={attendenceSimulator}
			averageQuatitySeller={averageQuatitySeller}
			onSubmit={onSubmitForm}
			defaultValues={formCalc}
			onOpenAttentionPointsModal={onOpenAttentionPointsModal}
		/>,
		<WageForm
			formChannelFlow={registerForm}
			onSubmit={onCreateFlowMutate}
			watching={onSubmitForm}
			defaultValues={formCalc}
			salaryCalculationValues={salaryCalculationValues}
			isLoadingButton={isLoadingCreateFlow}
			onOpenAttentionPointsModal={onOpenAttentionPointsModal}
		/>,
	];

	return (
		<Flex sx={styles.wrapperContainer}>
			<Flex>
				<Link sx={styles.link} onClick={() => navigate('-1')}>
					<ArrowLeftIcon />
					Voltar
				</Link>
			</Flex>
			<Flex sx={styles.container}>
				<Heading sx={styles.title}>Cadastro de Objetivos do Mês</Heading>
				{isCompaniesLoading || isEmployeesLoading ? (
					<Flex sx={styles.spinnerBox}>
						<Spinner />
					</Flex>
				) : (
					<>
						<UserFilter
							company={company}
							department={department}
							employee={selectedEmployee}
							companies={companies?.companies || []}
							employees={employees || []}
							labelEmployee="Vendedor"
							title="Seleção de vendedor"
							isReadOnly={currentStep > 0}
							showDepartment
							isUserPermited={isSelectDisabled}
						/>
						{isCreateFlowSuccess ? (
							<Flex sx={styles.container}>
								<Text sx={styles.subTitle}>Cadastro de novo fluxo</Text>
								<Flex sx={styles.successContainer}>
									<Text sx={styles.successTitle} data-testid="success-message">
										Cadastro realizado com sucesso!
									</Text>
									<Text mb="4.375rem">
										Visualize o documento de Geração de fluxo por Canais de vendas ou confira o histórico:
									</Text>
									<Flex gap="1.25rem">
										<Button isLoading={isLoadingFlow} sx={styles.successButtons} onClick={() => getFlowMutation()}>
											Visualizar documento
										</Button>
										<Button
											sx={styles.successButtons}
											variant="outlined"
											onClick={() => navigate(ROUTES.listSalesChannelsFlow)}
										>
											Ver histórico
										</Button>
									</Flex>
								</Flex>
							</Flex>
						) : (
							<Flex sx={styles.wrapper}>
								<Flex sx={styles.firstColumn}>
									<Text sx={styles.subTitle}>Cadastro de novo fluxo</Text>
									<Stepper elements={elements} currentStep={setCurrentStep} />
								</Flex>
								<Flex sx={styles.secundColumn}>
									{elements.length - 1 === currentStep && (
										<Flex sx={styles.firstTable}>
											<Text sx={styles.subTitle}>Canais de atendimento</Text>
											<ServiceChannelsTable
												defaultValues={formCalc}
												watching={onSubmitForm}
												onSubmit={onSubmitForm}
												control={registerForm.control}
												getValues={registerForm.getValues}
												reset={registerForm.reset}
												watch={registerForm.watch}
												errors={registerForm.formState.errors}
											/>
										</Flex>
									)}
									{currentStep < elements.length - 1 && !isIndicatorsExpandedLoading && (
										<Flex sx={styles.secundTable}>
											<Text sx={styles.subTitle}>Consulta dos 12 meses anteriores</Text>
											<Flex sx={styles.filter}>
												<Flex mb="2rem" w="18.75rem">
													<Controller
														name="segment"
														control={control}
														render={({ field: { value, onChange } }) => (
															<Select
																label="Indicador:"
																name="segment"
																placeholder="Indicador"
																dataTestId="input--segment"
																variant="medium"
																options={segments}
																onChange={onChange}
																value={value}
																isClearable
															/>
														)}
													/>
												</Flex>
												<TableGroup
													striped
													data={indicatorsTableData}
													columns={indicatorsTable.columns}
													contentCustomStyle={{
														...styles.table,
														tbody: {
															tr: {
																'&:nth-of-type(-n + 4)': {
																	td: {
																		background: segmentValue ? 'initial' : '#C1D6E1',
																	},
																},
															},
														},
													}}
													variant="small"
													headerCustomStyle={styles?.headerCustomStyle}
												/>
											</Flex>

											<Flex gap="5rem" mt="1.75rem">
												<Button
													variant="unstyled"
													leftIcon={<ViewIndicatorIcon />}
													sx={styles.button}
													onClick={onOpenIndicators}
												>
													Ver todos os indicadores
												</Button>
												<Button
													variant="unstyled"
													leftIcon={<ViewSellersIndicatorIcon />}
													sx={styles.button}
													onClick={onOpenSellers}
												>
													Ver consulta de funcionários
												</Button>
											</Flex>
										</Flex>
									)}
								</Flex>
							</Flex>
						)}
					</>
				)}
			</Flex>
			<SellersIndicatorsModal
				isOpen={isOpenSellers}
				onClose={onCloseSellers}
				company={selectedCompanyForModal}
				department={department}
			/>
			<IndicatorsModal
				isOpen={isOpenIndicators}
				onClose={onCloseIndicators}
				company={selectedCompanyForModal}
				employee={selectedEmployeeForModal}
				department={department}
			/>
			<AttentionPoints
				isOpen={isOpenAttentionPoints}
				onClose={onCloseAttentionPoints}
				control={registerForm.control}
				errors={registerForm.formState.errors}
			/>
		</Flex>
	);
};

export default RegisterSalesChannelFlow;
