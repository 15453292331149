/**
 *
 * InputNumeric
 *
 */

import { FormControl, FormErrorMessage, FormLabel, Input, InputProps, SystemStyleObject, Text } from '@chakra-ui/react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

export interface InputNumericProps extends InputProps {
	formLabelVariant?: string;
	inputVariant?: string;
	label?: string;
	errorMessage?: string;
	errorMessageVariant?: string;
	required?: boolean;
	disabled?: boolean;
	suffix?: string;
	decimalScale?: number;
	isAllowed?: (values: NumberFormatValues) => boolean;
	customLabelStyle?: SystemStyleObject;
}

const InputNumeric = ({
	formLabelVariant,
	inputVariant,
	label,
	errorMessage,
	required,
	disabled,
	suffix,
	errorMessageVariant,
	decimalScale = 2,
	customLabelStyle,
	...rest
}: InputNumericProps) => {
	return (
		<>
			<FormControl isInvalid={!!errorMessage}>
				{label && (
					<FormLabel variant={formLabelVariant} mr="0" sx={customLabelStyle}>
						{label}{' '}
						{required && (
							<Text variant="required" as="span">
								*
							</Text>
						)}
					</FormLabel>
				)}
				<Input
					variant={inputVariant}
					disabled={disabled}
					suffix={suffix}
					thousandSeparator="."
					decimalSeparator=","
					as={NumericFormat}
					decimalScale={decimalScale}
					fixedDecimalScale
					{...rest}
				/>
				{errorMessage && (
					<FormErrorMessage data-testid="text--error" variant={errorMessageVariant}>
						{errorMessage}
					</FormErrorMessage>
				)}
			</FormControl>
		</>
	);
};

export default InputNumeric;
