import { getAxios } from 'hooks/useAxios';
import qs from 'qs';
import { ENDPOINTS } from 'services/endpoints';
import {
	IResponseIndicatorsNames,
	IResponseIndicator,
	IPaginatedIndicators,
	IUsersIndicatorAverage,
} from 'types/indicator';
import { IRequestIndicator, IResponseCreateIndicator } from 'types/indicator';

export interface IndicatorParams {
	search?: string;
	ordering?: string;
	active?: string;
	companies?: string;
	limit?: string;
	offset?: string;
}

export interface IndicatorAverageParams {
	search?: string;
	ordering?: string;
	user__company_id__id?: string;
	user__department?: string;
	user__id?: string[];
	limit?: string;
	offset?: string;
}

export const createIndicator = async (data: IRequestIndicator): Promise<IResponseCreateIndicator> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.registerIndicatorsTypes, data);
	return response?.data;
};

export const getIndicators = async (params?: IndicatorParams): Promise<IPaginatedIndicators> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.getIndicatorsTypes, { params });
	return response?.data;
};

export const getIndicatorById = async (indicatorId: string): Promise<IResponseIndicator> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.getIndicatorById(indicatorId));
	return response.data;
};

export const editIndicator = async (data: IRequestIndicator, indicatorId: string): Promise<IResponseIndicator> => {
	const api = getAxios();
	const response = await api.put(ENDPOINTS.editIndicator(indicatorId), data);
	return response.data;
};

export const getIndicatorsNames = async (params?: IndicatorParams): Promise<IResponseIndicatorsNames[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.getIndicatorsNames, { params });
	return response?.data;
};

export const getIndicatorsAverage = async (params?: IndicatorAverageParams): Promise<IUsersIndicatorAverage> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.getIndicatorsAvarege, {
		params,
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response?.data;
};
