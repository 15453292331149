/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * StoreFlowForm
 *
 */
import { AttentionPointForm, attentionsPointsButton } from '../AttentionPoints';
import InputNumeric from '../InputNumeric';
import InputText from '../InputText';
import { useEffect } from 'react';
import { Flex, Grid, GridItem, SystemStyleObject, Text, Tooltip } from '@chakra-ui/react';
import { IFlow } from 'app/pages/Home';
import { TooltipIcon } from 'assets/icons';
import { useDebounce } from 'hooks/useDebounce';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormReset, UseFormWatch } from 'react-hook-form';
import { IStepProps } from 'types/stepper';
import { maskThousands, unMaskThousands } from 'utils/Numbers';
import * as yup from 'yup';

export interface IStoreFlowForm extends AttentionPointForm {
	passingShopFlow?: number; //FLUXO LOJA PASSANTE
	shopSellers?: number; //VENDEDORES LOJA
	shopFlowCustomerServiceQuantity?: number; //QUANTIDADE ATENDIMENTOS
	flowConversion?: number; //CONVERSÃO DE FLUXO DE LOJA PASSANTES
	flowSales?: number; //VENDAS DE FLUXO DE LOJA PASSANTES
	incomingCalls?: number; //LIGAÇÕES RECEBIDAS
	attendance?: number; //COMPARECIMENTO
	callsCustomerServiceQuantity?: number; //QUANTIDADE ATENDIMENTOS
	callAttendanceConversion?: number; //CONVERSÃO DE COMPARECIMENTO LIGAÇÕES
	callAttendanceSales?: number; //VENDAS DE COMPARECIMENTO LIGAÇÕES
	prospection?: number; //PROSPECÇÃO
	conversionOnFlow?: number; //CONVERSÃO EM FLUXO
	prospectionCustomerServiceQuantity?: number; //QUANTIDADE ATENDIMENTOS
	prospectingConversion?: number; //CONVERSÃO DE PROSPECÇÃO
	prospectingSales?: number; //VENDAS DE PROSPECÇÃO
	leads?: number; //LEADS
	leadsConversionOnFlow?: number; //CONVERSÃO EM FLUXO
	leadsCustomerServiceQuantity?: number; //QUANTIDADE ATENDIMENTOS
	leadsConversion?: number; //CONVERSÃO DE LEADS
	leadsSales?: number; //VENDAS DE LEADS
	customerWorkshop?: number; //CLIENTE OFICINA
	conversionEvaluation?: number; //CONVERSÃO AVALIAÇÃO
	quantity?: number; //QUANTIDADE
	workshopCustomerConversion?: number; //CONVERSÃO DE CLIENTE OFICINA
	workshopCustomerSales?: number; //VENDAS DE CLIENTE OFICINA
}
interface StoreFlowFormProps extends IStepProps<IStoreFlowForm, IFlow> {
	register: UseFormRegister<IStoreFlowForm>;
	control: Control<IStoreFlowForm>;
	watch: UseFormWatch<IStoreFlowForm>;
	reset: UseFormReset<IStoreFlowForm>;
	errors: FieldErrors<IStoreFlowForm>;
	readOnly?: boolean;
	onOpenAttentionPointsModal: () => void;
}

export const schemaStoreFlowForm = {
	passingShopFlow: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	shopSellers: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	shopFlowCustomerServiceQuantity: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	flowConversion: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Conversão de fluxo de loja passante é obrigatório.'),
	flowSales: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	incomingCalls: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	attendance: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	callsCustomerServiceQuantity: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	callAttendanceConversion: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Conversão de comparecimento ligações é obrigatório.'),
	callAttendanceSales: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	prospection: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	conversionOnFlow: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	prospectionCustomerServiceQuantity: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	prospectingConversion: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Conversão de prospecção é obrigatório.'),
	prospectingSales: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	leads: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	leadsConversionOnFlow: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	leadsCustomerServiceQuantity: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	leadsConversion: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Conversão de leads é obrigatório.'),
	leadsSales: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	customerWorkshop: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	conversionEvaluation: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	quantity: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	workshopCustomerConversion: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Conversão de cliente oficina é obrigatório.'),
	workshopCustomerSales: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	attentionPoints: yup.array().of(
		yup.object().shape({
			point: yup.string().max(60, 'Limite de 60 caracteres.').nullable(),
		}),
	),
};

const StoreFlowForm = ({
	defaultValues,
	readOnly,
	placeholders,
	watching,
	register,
	control,
	watch,
	reset,
	errors,
	onOpenAttentionPointsModal,
}: StoreFlowFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDirection: 'column',
			maxW: {
				base: '100%',
				'2xl': '44rem',
			},
			minW: {
				base: '100%',
				'2xl': '41rem',
			},
			w: '100%',
		},
		tooltipContainer: {
			w: '100%',
			alignItems: 'center',
			gap: '0.5rem',
			justifyContent: 'flex-end',
		},
		content: {
			w: '100%',
			flexDirection: 'column',
			pr: '2.5rem',
			pb: '1.875rem',
			gap: '2.188rem',
		},
		section: {
			w: '100%',
			flexDirection: 'column',
			gap: '1.25rem',
		},
		title: {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			color: '#2E769B',
		},
		grid: {
			gap: '0.625rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				md: 'repeat(2, 1fr)',
				lg: 'repeat(3, 1fr)',
			},
		},
		buttonContainer: {
			w: '100%',
			justifyContent: 'end',
			mt: '0.313rem',
		},
		button: {
			fontSize: '1.375rem',
			px: '2rem',
			h: '4rem',
			w: {
				base: '100%',
				md: 'fit-content',
			},
		},
		customLabelStyle: {
			minHeight: '3.37rem',
			display: 'flex',
			alignItems: 'center',
			whiteSpace: 'pre-line',
		},
		addButton: {
			fontSize: '1.125rem',
			fontWeight: '600',
			color: 'blue.500',
			h: 'fit-content',
			display: 'flex',
			alignItems: 'center',
		},
	};

	const onChangeValues = () => {
		watching?.(watch());
	};

	const debounceCalc = useDebounce(onChangeValues, 800);

	useEffect(() => reset(defaultValues), [defaultValues]);

	return (
		<Flex sx={styles.container}>
			<Flex sx={styles.tooltipContainer}>
				<Text>* = Média</Text>
				<Tooltip label="Os campos com valores contendo o símbolo '*' são os valores de referência(média) não sendo o valor final do campo.">
					<span>
						<TooltipIcon />
					</span>
				</Tooltip>
			</Flex>

			<Flex sx={styles.content}>
				<Flex sx={styles.section}>
					<Text sx={styles.title}>Fluxo de loja</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="passingShopFlow"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Fluxo de loja passante:"
										placeholder={
											placeholders?.passingShopFlow
												? `${maskThousands(placeholders?.passingShopFlow)} *`
												: 'Fluxo de loja passante *'
										}
										name="passingShopFlow"
										errorMessage={errors?.passingShopFlow?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--passingShopFlow"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="shopSellers"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Vendedores loja:"
										placeholder={
											placeholders?.shopSellers ? `${maskThousands(placeholders?.shopSellers)} *` : 'Vendedores loja *'
										}
										name="shopSellers"
										errorMessage={errors?.shopSellers?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--shopSellers"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<InputText
								label="Qtd. atendimento:"
								labelVariant="small"
								placeholder={
									placeholders?.shopFlowCustomerServiceQuantity
										? `${maskThousands(placeholders?.shopFlowCustomerServiceQuantity)} *`
										: 'Qtd. atendimento *'
								}
								name="shopFlowCustomerServiceQuantity"
								register={register}
								disabled
								data-testid="input--shopFlowCustomerServiceQuantity"
								variant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>

						<GridItem display="none">
							<Controller
								name="flowConversion"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Conversão:"
										placeholder="Conversão"
										name="flowConversion"
										errorMessage={errors?.flowConversion?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--flowConversion"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										suffix="%"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem display="none">
							<InputText
								label="Vendas"
								labelVariant="small"
								placeholder="Vendas"
								name="flowSales"
								register={register}
								disabled
								data-testid="input--flowSales"
								variant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>

				<Flex sx={styles.section}>
					<Text sx={styles.title}>Ligações</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="incomingCalls"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Ligações recebidas:"
										placeholder={
											placeholders?.incomingCalls
												? `${maskThousands(placeholders?.incomingCalls)} *`
												: 'Ligações recebidas *'
										}
										name="incomingCalls"
										errorMessage={errors?.incomingCalls?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--incomingCalls"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<Controller
								name="attendance"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Comparecimento (%):"
										name="attendance"
										formLabelVariant="small"
										placeholder={
											placeholders?.attendance ? `${maskThousands(placeholders?.attendance)} *` : 'Comparecimento *'
										}
										errorMessage={errors?.attendance?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled={readOnly}
										suffix="%"
										data-testid="input--attendance"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<InputText
								label="Qtd. atendimento:"
								labelVariant="small"
								placeholder="Qtd. atendimento"
								name="callsCustomerServiceQuantity"
								register={register}
								disabled={true}
								data-testid="input--callsCustomerServiceQuantity"
								variant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>
						<GridItem display="none">
							<Controller
								name="callAttendanceConversion"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Conversão:"
										placeholder="Conversão"
										name="callAttendanceConversion"
										errorMessage={errors?.callAttendanceConversion?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--callAttendanceConversion"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										suffix="%"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem display="none">
							<InputText
								label="Vendas"
								labelVariant="small"
								placeholder="Vendas"
								name="callAttendanceSales"
								register={register}
								disabled
								data-testid="input--callAttendanceSales"
								variant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>

				<Flex sx={styles.section}>
					<Text sx={styles.title}>Prospecção</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="prospection"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Prospecção:"
										placeholder="Prospecção"
										name="prospection"
										errorMessage={errors?.prospection?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--prospect"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<Controller
								name="conversionOnFlow"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Conversão em fluxo (%):"
										name="conversionOnFlow"
										formLabelVariant="small"
										placeholder="Conversão em fluxo"
										errorMessage={errors?.conversionOnFlow?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled={readOnly}
										suffix="%"
										data-testid="input--conversionOnFlow"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<InputText
								label="Qtd. atendimento:"
								labelVariant="small"
								placeholder="Qtd. atendimento"
								name="prospectionCustomerServiceQuantity"
								register={register}
								disabled={true}
								data-testid="input--prospectionCustomerServiceQuantity"
								variant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>
						<GridItem display="none">
							<Controller
								name="prospectingConversion"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Conversão:"
										placeholder="Conversão"
										name="prospectingConversion"
										errorMessage={errors?.prospectingConversion?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--prospectingConversion"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										suffix="%"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem display="none">
							<InputText
								label="Vendas"
								labelVariant="small"
								placeholder="Vendas"
								name="prospectingSales"
								register={register}
								disabled
								data-testid="input--prospectingSales"
								variant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>

				<Flex sx={styles.section}>
					<Text sx={styles.title}>Leads</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="leads"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Leads:"
										placeholder="Leads"
										name="leads"
										errorMessage={errors?.leads?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--leads"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<Controller
								name="leadsConversionOnFlow"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Conversão fluxo (%):"
										name="leadsConversionOnFlow"
										formLabelVariant="small"
										placeholder="Conversão fluxo"
										errorMessage={errors?.leadsConversionOnFlow?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled={readOnly}
										suffix="%"
										data-testid="input--leadsConversionOnFlow"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<InputText
								label="Qtd. atendimento:"
								labelVariant="small"
								placeholder="Qtd. atendimento"
								name="leadsCustomerServiceQuantity"
								register={register}
								disabled={true}
								data-testid="input--leadsCustomerServiceQuantity"
								variant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>
						<GridItem display="none">
							<Controller
								name="leadsConversion"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Conversão:"
										placeholder="Conversão"
										name="leadsConversion"
										errorMessage={errors?.leadsConversion?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--leadsConversion"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										suffix="%"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem display="none">
							<InputText
								label="Vendas"
								labelVariant="small"
								placeholder="Vendas"
								name="leadsSales"
								register={register}
								disabled
								data-testid="input--leadsSales"
								variant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>

				<Flex sx={styles.section}>
					<Text sx={styles.title}>Oficina</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="customerWorkshop"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Cliente oficina:"
										placeholder="Cliente oficina"
										name="customerWorkshop"
										errorMessage={errors?.customerWorkshop?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--customerWorkshop"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<Controller
								name="conversionEvaluation"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label={`Conversão \n avaliação (%):`}
										name="conversionEvaluation"
										formLabelVariant="small"
										placeholder="Conversão avaliação"
										errorMessage={errors?.conversionEvaluation?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled={readOnly}
										suffix="%"
										data-testid="input--conversionEvaluation"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<InputText
								label="Qtd. atendimento:"
								labelVariant="small"
								placeholder="Qtd. atendimento"
								name="quantity"
								register={register}
								disabled={true}
								data-testid="input--quantity"
								variant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>
						<GridItem display="none">
							<Controller
								name="workshopCustomerConversion"
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputNumeric
										formLabelVariant="small"
										label="Conversão:"
										placeholder="Conversão"
										name="workshopCustomerConversion"
										errorMessage={errors?.workshopCustomerConversion?.message}
										onKeyUp={debounceCalc}
										isReadOnly={readOnly}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										data-testid="input--workshopCustomerConversion"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										suffix="%"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem display="none">
							<InputText
								label="Vendas"
								labelVariant="small"
								placeholder="Vendas"
								name="workshopCustomerSales"
								register={register}
								disabled
								data-testid="input--workshopCustomerSales"
								variant="small"
								errorMessageVariant="small"
								customLabelStyle={styles.customLabelStyle}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default StoreFlowForm;
