/**
 *
 * FormsChannelFlow
 *
 */
import ServiceChannelsTable from '../ServiceChannelsTable';
import { useEffect } from 'react';
import { Button, Flex, SystemStyleObject, Text } from '@chakra-ui/react';
import DispatcherForm, { schemaDispatcherForm } from 'app/components/DispatcherForm';
import SalesPriceForm, { schemaSalesPriceForm } from 'app/components/SalesPriceForm';
import StoreFlowForm, { schemaStoreFlowForm } from 'app/components/StoreFlowForm';
import VehicleProfitabilityForm, { schemaVehicleProfitabilityForm } from 'app/components/VehicleProfitabilityForm';
import { IFlow } from 'app/pages/Home';
import { FieldError, UseFormReturn } from 'react-hook-form';
import { IResponseAttendenceSimulatorValues } from 'services/http/flow';
import { IStepProps } from 'types/stepper';

export const flowSchema = {
	...schemaStoreFlowForm,
	...schemaVehicleProfitabilityForm,
	...schemaSalesPriceForm,
	...schemaDispatcherForm,
};

interface FormsChannelFlowProps extends IStepProps<IFlow, IFlow> {
	readOnly?: boolean;
	attendenceSimulator?: IResponseAttendenceSimulatorValues;
	averageQuatitySeller: number;
	onOpenAttentionPointsModal: () => void;
	formChannelFlow: UseFormReturn<IFlow>;
}

export const FormsChannelFlow = ({
	defaultValues,
	onSubmit,
	attendenceSimulator,
	averageQuatitySeller,
	nextStep,
	formChannelFlow,
	onOpenAttentionPointsModal,
}: FormsChannelFlowProps) => {
	const styles: Record<string, SystemStyleObject> = {
		formContainer: {
			backgroundColor: 'white',
			borderRadius: '0.313rem',
			border: '1px solid #D9D9D9',
			flexDirection: 'column',
			maxW: {
				base: '100%',
				'2xl': '44rem',
			},
			w: '100%',
		},
		formBodyContainer: {
			p: '1.375rem',
			maxHeight: '40rem',
			overflow: 'auto',
			flexDirection: 'column',
			maxW: {
				base: '100%',
				'2xl': '44rem',
			},
			minW: {
				base: '100%',
				'2xl': '41rem',
			},
			w: '100%',
		},
		formActionsContainer: {
			p: '2.5rem 2.62rem 3rem',
			justifyContent: 'end',
		},
		formButton: {
			fontWeight: '600',
			fontSize: '1.375rem',
			px: '2rem',
			h: '4rem',
			w: {
				base: '100%',
				md: 'fit-content',
			},
		},
		theadCustomStyle: {
			th: {
				fontFamily: 'Boston',
				fontWeight: 600,
				fontSize: { base: '1.125rem' },
				textTransform: 'inherit',
				color: 'white',
				padding: '0.5rem 1rem',
				borderTop: '1px solid',
				borderBottom: '1px solid',
				borderRight: '1px solid',
				borderColor: 'gray.200',
			},
		},
		tbodyCustomStyle: {
			td: {
				fontFamily: 'Boston',
				fontSize: { base: '1.125rem' },
				padding: '0.5rem 1rem',
				borderBottom: '1px solid',
				borderRight: '1px solid',
				borderColor: 'gray.200',
			},
		},
	};

	const {
		register,
		control,
		watch,
		reset,
		handleSubmit,
		getValues,
		setError,
		formState: { errors },
	} = formChannelFlow;

	const onSubmitForm = (data: IFlow) => {
		onSubmit?.(data);
		nextStep?.();
	};

	const onSubmitTable = (data: IFlow) => {
		onSubmit?.(data);
	};

	const errorsConversion = [
		errors.flowConversion?.message,
		errors.callAttendanceConversion?.message,
		errors.prospectingConversion?.message,
		errors.leadsConversion?.message,
		errors.workshopCustomerConversion?.message,
	];

	useEffect(() => {
		reset(defaultValues);

		Object.entries(errors).forEach(([key, value]) => {
			const current = defaultValues?.[key as keyof IFlow] as number;

			if (typeof current === 'undefined' || isNaN(current)) {
				setError(key as keyof IFlow, value as FieldError);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValues]);

	return (
		<Flex as="form" onSubmit={handleSubmit(onSubmitForm)} sx={styles.formContainer}>
			<Flex sx={styles.formBodyContainer}>
				<StoreFlowForm
					register={register}
					control={control}
					watch={watch}
					reset={reset}
					errors={errors}
					onSubmit={onSubmit}
					watching={onSubmit}
					defaultValues={defaultValues}
					placeholders={{
						passingShopFlow: attendenceSimulator?.averageStoreFlowPassing,
						shopSellers: attendenceSimulator?.totalSellersInCompany,
						shopFlowCustomerServiceQuantity: isNaN(averageQuatitySeller) ? undefined : averageQuatitySeller,
						incomingCalls: attendenceSimulator?.averageIncomingCalls,
						attendance: attendenceSimulator?.averageCallAttendance,
					}}
					onOpenAttentionPointsModal={onOpenAttentionPointsModal}
				/>
				<Flex mb={'1.875rem'} flexDir="column">
					<ServiceChannelsTable
						defaultValues={defaultValues}
						watching={onSubmitTable}
						onSubmit={onSubmitTable}
						theadCustomStyle={styles.theadCustomStyle}
						tbodyCustomStyle={styles.tbodyCustomStyle}
						control={control}
						getValues={getValues}
						reset={reset}
						watch={watch}
						errors={errors}
					/>
					{errorsConversion?.map((error, index) => (
						<Text key={index} variant="errorFlow" display="flex" flexDir="column">
							{error}
						</Text>
					))}
				</Flex>
				<VehicleProfitabilityForm
					register={register}
					control={control}
					watch={watch}
					reset={reset}
					errors={errors}
					onSubmit={onSubmit}
					watching={onSubmit}
					defaultValues={defaultValues}
					placeholders={{
						averageTicket: attendenceSimulator?.averageTicket,
						margin: attendenceSimulator?.marginParameter,
					}}
					onOpenAttentionPointsModal={onOpenAttentionPointsModal}
				/>
				<SalesPriceForm
					control={control}
					watch={watch}
					reset={reset}
					errors={errors}
					onSubmit={onSubmit}
					watching={onSubmit}
					defaultValues={defaultValues}
					onOpenAttentionPointsModal={onOpenAttentionPointsModal}
				/>
				<DispatcherForm
					control={control}
					watch={watch}
					reset={reset}
					errors={errors}
					onSubmit={onSubmit}
					watching={onSubmit}
					defaultValues={defaultValues}
					onOpenAttentionPointsModal={onOpenAttentionPointsModal}
				/>
			</Flex>
			<Flex sx={styles.formActionsContainer}>
				<Button sx={styles.formButton} type="submit" data-testid="button--submit">
					Próximo
				</Button>
			</Flex>
		</Flex>
	);
};

export default FormsChannelFlow;
