/**
 *
 * CommissionsScalesForm
 *
 */

import CustomModal from '../CustomModal';
import ScaleInput from '../ScaleInput';
import Select, { IOption } from '../Select';
import { useEffect } from 'react';
import { Button, Flex, Grid, GridItem, Spinner, SystemStyleObject, Text, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useDebounce } from 'hooks/useDebounce';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IResponseCompanies, getAllCompanies } from 'services/http/company';
import { IStoreCommission } from 'types/commission';
import { IStepProps } from 'types/stepper';
import { DEPARTMENTS_OPTIONS } from 'utils/constants';
import { handleDisableSelect } from 'utils/handlePermissions';
import { unMaskThousandsForScales } from 'utils/Numbers';
import { ResponseErrors, parseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import * as yup from 'yup';

export interface ICommissionsScalesForm {
	company?: IOption;
	department?: IOption;
	scaleOne?: number;
	scaleTwo?: number;
	scaleThree?: number;
	scaleFour?: number;
	scaleFive?: number;
}

interface CommissionsScalesFormProps extends IStepProps<ICommissionsScalesForm, IStoreCommission> {}

const CommissionsScalesForm = ({ defaultValues, onSubmit, nextStep, watching }: CommissionsScalesFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDir: 'column',
		},
		title: {
			fontWeight: '700',
			color: 'blue.500',
			mb: '0.625rem',
		},
		grid: {
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				md: 'repeat(2, 1fr)',
				lg: 'repeat(3, 1fr)',
			},
			gap: '1rem',

			_notFirst: {
				mt: '1rem',
			},
		},
		gridItem: {
			gridColumn: {
				base: 'span 1',
				lg: 'span 2',
			},
		},
		buttonContainer: {
			justifyContent: 'flex-end',
			gap: '1.875rem',
			mt: '2rem',
			flexDir: {
				base: 'column-reverse',
				md: 'row',
			},
		},
		button: {
			h: '4rem',
			px: '1.875rem',
		},
		spinner: {
			alignContent: 'center',
			justifyContent: 'center',
		},
	};

	const navigate = useNavigate();
	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const {
		session: { user },
	} = useSession();

	const isSelectDisabled = handleDisableSelect(user);

	const selectSchema = yup
		.object()
		.required('Este campo é obrigatório.')
		.default(undefined)
		.nullable()
		.shape({
			label: yup.string().required('Este campo é obrigatório.'),
			value: yup.string().required('Este campo é obrigatório.'),
		});

	const schema = yup.object().shape({
		company: selectSchema,
		department: selectSchema,
		scaleOne: yup.number().transform(value => (isNaN(value) ? undefined : value)),
		scaleTwo: yup
			.number()
			.transform(value => (isNaN(value) ? undefined : value))
			.test('isBiggerThenScaleOne', 'Valor deve ser maior que o prévio.', value => {
				const scaleOne: number = Number(formValues?.scaleOne || undefined);

				if (value && !isNaN(scaleOne)) return value > scaleOne;

				return true;
			}),
		scaleThree: yup
			.number()
			.transform(value => (isNaN(value) ? undefined : value))
			.test('isBiggerThenScaleTwo', 'Valor deve ser maior que o prévio.', value => {
				const scaleOne: number = Number(formValues?.scaleOne || undefined);
				const scaleTwo: number = Number(formValues?.scaleTwo || undefined);

				if (value && !isNaN(scaleOne) && isNaN(scaleTwo)) return value > scaleOne;
				if (value && !isNaN(scaleTwo)) return value > scaleTwo;
				return true;
			}),
		scaleFour: yup
			.number()
			.transform(value => (isNaN(value) ? undefined : value))
			.test('isBiggerThenScaleThree', 'Valor deve ser maior que o prévio.', value => {
				const scaleOne: number = Number(formValues?.scaleOne || undefined);
				const scaleTwo: number = Number(formValues?.scaleTwo || undefined);
				const scaleThree: number = Number(formValues?.scaleThree || undefined);

				if (value && !isNaN(scaleOne) && isNaN(scaleTwo) && isNaN(scaleThree)) return value > scaleOne;
				if (value && !isNaN(scaleTwo) && isNaN(scaleThree)) return value > scaleTwo;
				if (value && !isNaN(scaleThree)) return value > scaleThree;
				return true;
			}),
		scaleFive: yup
			.number()
			.transform(value => (isNaN(value) ? undefined : value))
			.test('isBiggerThenScaleFour', 'Valor deve ser maior que o prévio.', value => {
				const scaleOne: number = Number(formValues?.scaleOne || undefined);
				const scaleTwo: number = Number(formValues?.scaleTwo || undefined);
				const scaleThree: number = Number(formValues?.scaleThree || undefined);
				const scaleFour: number = Number(formValues?.scaleFour || undefined);

				if (value && !isNaN(scaleOne) && isNaN(scaleTwo) && isNaN(scaleThree) && isNaN(scaleFour))
					return value > scaleOne;
				if (value && !isNaN(scaleTwo) && isNaN(scaleThree) && isNaN(scaleFour)) return value > scaleTwo;
				if (value && !isNaN(scaleThree) && isNaN(scaleFour)) return value > scaleThree;
				if (value && !isNaN(scaleFour)) return value > scaleFour;
				return true;
			}),
	});

	const {
		control,
		reset,
		watch,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<ICommissionsScalesForm>({
		resolver: yupResolver(schema),
		defaultValues,
	});

	const { data: companies, isLoading: isCompaniesLoading } = useQuery<
		IResponseCompanies,
		AxiosError<ResponseErrors>,
		IResponseCompanies
	>(['companies'], () => getAllCompanies(), {
		onSuccess: data => {
			const defaultCompany = data?.companiesObjects?.find(item => item.company === user.company);

			setValue('company', { label: defaultCompany?.company || '', value: defaultCompany?.id });

			const department = DEPARTMENTS_OPTIONS.find(item => item.value === user.department);

			setValue('department', department);
		},
		onError: error => addToast({ type: 'error', title: 'Erro!', description: parseErrors(error?.response?.data) }),
	});

	const formValues = watch();

	const handleGoBack = () => {
		isEmpty(formValues) ? navigate(ROUTES.listCommissions) : onOpen();
	};

	const handleOnSubmit = (data: ICommissionsScalesForm) => {
		onSubmit(data);
		nextStep?.();
	};

	const onChangeValues = () => {
		watching?.(watch());
	};

	const debounceCalc = useDebounce(onChangeValues, 600);

	useEffect(() => reset(defaultValues), [defaultValues, reset]);

	return (
		<Flex as="form" sx={styles.container} onSubmit={handleSubmit(handleOnSubmit)}>
			<Text sx={styles.title}>Escala de quantidade de venda</Text>

			{isCompaniesLoading ? (
				<Flex sx={styles.spinner}>
					<Spinner />
				</Flex>
			) : (
				<>
					<Grid sx={styles.grid}>
						<GridItem sx={styles.gridItem}>
							<Controller
								name="company"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										label="Unidade"
										placeholder="Unidade"
										onChange={onChange}
										value={value}
										options={parsedOptionArray(companies?.companiesObjects, 'company', 'id')}
										variant="medium"
										formLabelVariant="small"
										errorMessageVariant="small"
										dataTestId="input--company"
										errorMessage={errors?.company?.message}
										isDisabled={isSelectDisabled}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<Controller
								name="department"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										label="Departamento"
										placeholder="Departamento"
										onChange={onChange}
										value={value}
										options={DEPARTMENTS_OPTIONS}
										variant="medium"
										formLabelVariant="small"
										errorMessageVariant="small"
										dataTestId="input--department"
										errorMessage={errors?.department?.message}
										isDisabled={isSelectDisabled}
									/>
								)}
							/>
						</GridItem>
					</Grid>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="scaleOne"
								control={control}
								render={({ field: { value, onChange } }) => (
									<ScaleInput
										name="scaleOne"
										label="Escala 1"
										placeholder="Escala 1"
										value={value || ''}
										onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
										decimalScale={0}
										dataTestId="input--scaleOne"
										errorMessage={errors.scaleOne?.message}
										onKeyUp={debounceCalc}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<Controller
								name="scaleTwo"
								control={control}
								render={({ field: { value, onChange } }) => (
									<ScaleInput
										name="scaleTwo"
										label="Escala 2"
										placeholder="Escala 2"
										value={value || ''}
										onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
										decimalScale={0}
										dataTestId="input--scaleTwo"
										errorMessage={errors.scaleTwo?.message}
										onKeyUp={debounceCalc}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<Controller
								name="scaleThree"
								control={control}
								render={({ field: { value, onChange } }) => (
									<ScaleInput
										name="scaleThree"
										label="Escala 3"
										placeholder="Escala 3"
										value={value || ''}
										onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
										decimalScale={0}
										dataTestId="input--scaleThree"
										errorMessage={errors.scaleThree?.message}
										onKeyUp={debounceCalc}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<Controller
								name="scaleFour"
								control={control}
								render={({ field: { value, onChange } }) => (
									<ScaleInput
										name="scaleFour"
										label="Escala 4"
										placeholder="Escala 4"
										value={value || ''}
										onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
										decimalScale={0}
										dataTestId="input--scaleFour"
										errorMessage={errors.scaleFour?.message}
										onKeyUp={debounceCalc}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<Controller
								name="scaleFive"
								control={control}
								render={({ field: { value, onChange } }) => (
									<ScaleInput
										name="scaleFive"
										label="Escala 5"
										placeholder="Escala 5"
										value={value || ''}
										onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
										decimalScale={0}
										dataTestId="input--scaleFive"
										errorMessage={errors.scaleFive?.message}
										onKeyUp={debounceCalc}
									/>
								)}
							/>
						</GridItem>
					</Grid>

					<Flex sx={styles.buttonContainer}>
						<Button sx={styles.button} variant="outlined" data-testid="button--cancel.1" onClick={handleGoBack}>
							Cancelar
						</Button>
						<Button sx={styles.button} data-testid="button--submit.1" type="submit">
							Próximo
						</Button>
					</Flex>
				</>
			)}

			<CustomModal
				title=""
				body="As informações da comissão de vendas não foram salvas. Gostaria de cancelar mesmo assim?"
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Não',
						type: 'outlined',
						onClick: onClose,
						datatestid: 'button--cancel',
					},
					{
						label: 'Sim',
						type: 'solid',
						onClick: () => navigate(ROUTES.listCommissions),
						datatestid: 'button--confirm',
					},
				]}
			/>
		</Flex>
	);
};

export default CommissionsScalesForm;
