import { ACCESS_LEVELS_ENUM } from './constants';
import { User } from 'types/user';

export const handleDisableSelect = (user: User) => {
	const isAdministrator = user?.accessProfile?.includes(ACCESS_LEVELS_ENUM.ADMINISTRATOR);
	const isNewManager = user?.accessProfile?.includes(ACCESS_LEVELS_ENUM.NEW_MANAGER);
	const isPreownedManager = user?.accessProfile?.includes(ACCESS_LEVELS_ENUM.PREOWNED_MANAGER);

	if (isAdministrator) return false;

	if ((isNewManager || isPreownedManager) && !isAdministrator) return true;

	return false;
};

export const handleAccessProfileParam = (user: User) => {
	const isAdministrator = user?.accessProfile?.includes(ACCESS_LEVELS_ENUM.ADMINISTRATOR);
	const isNewManager = user?.accessProfile?.includes(ACCESS_LEVELS_ENUM.NEW_MANAGER);
	const isPreownedManager = user?.accessProfile?.includes(ACCESS_LEVELS_ENUM.PREOWNED_MANAGER);
	const isManagerAll = isNewManager && isPreownedManager;

	if (isAdministrator || isManagerAll) return `${ACCESS_LEVELS_ENUM.NEW_SELLER}, ${ACCESS_LEVELS_ENUM.PREOWNED_SELLER}`;

	if (isNewManager) return ACCESS_LEVELS_ENUM.NEW_SELLER;

	if (isPreownedManager) return ACCESS_LEVELS_ENUM.PREOWNED_SELLER;
};
