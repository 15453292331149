/**
 *
 * CommissionsForm
 *
 */

import InputNumeric from '../InputNumeric';
import ScaleInput from '../ScaleInput';
import Select, { IOption } from '../Select';
import { useEffect, useState, useMemo } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Flex, Grid, GridItem, IconButton, Spinner, SystemStyleObject, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { getIndicators } from 'services/http/indicator';
import { IStoreCommission } from 'types/commission';
import { IPaginatedIndicators } from 'types/indicator';
import { IStepProps } from 'types/stepper';
import { ONLY_NUMBERS_REGEX } from 'utils/constants';
import { allowedPorcentage, unMaskThousandsForScales } from 'utils/Numbers';
import { ResponseErrors, parseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import * as yup from 'yup';

interface ICommissionsItem {
	indicator?: IOption;
	base?: number;
	scaleOne?: number;
	scaleTwo?: number;
	scaleThree?: number;
	scaleFour?: number;
	scaleFive?: number;
}

export interface ICommissionsForm {
	commission?: ICommissionsItem[];
}

interface CommissionsFormProps extends IStepProps<ICommissionsForm, IStoreCommission> {}

const CommissionsForm = ({
	onSubmit,
	defaultValues,
	prevStep,
	watching,
	isLoadingButton,
	isEditing,
}: CommissionsFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDir: 'column',
		},
		title: {
			fontWeight: '700',
			color: 'blue.500',
			mb: '0.625rem',
		},
		arrayContainer: {
			flexDir: 'column',
			mb: '1.5rem',
		},
		grid: {
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				md: 'repeat(2, 1fr)',
				lg: 'repeat(3, 1fr)',
			},
			gap: '1rem',

			_notFirst: {
				mt: '1rem',
			},
		},
		gridItem: {
			gridColumn: {
				base: 'span 1',
				lg: 'span 2',
			},
		},
		buttonContainer: {
			justifyContent: 'flex-end',
			gap: '1.875rem',
			mt: '2rem',
			flexDir: {
				base: 'column-reverse',
				md: 'row',
			},
		},
		button: {
			h: '4rem',
			px: '1.875rem',
		},
		addButton: {
			fontSize: '1.375rem',
			fontWeight: '600',
			color: 'blue.500',
			h: 'fit-content',
			display: 'flex',
			alignItems: 'center',
		},
		spinner: {
			alignContent: 'center',
			justifyContent: 'center',
		},
		deleteButton: {
			fontSize: '1rem',
			backgroundColor: '#AE0000',
			w: '35px',
			h: '35px',
			m: 'auto 0',
			_hover: {
				backgroundColor: '#740303',
			},
		},
	};

	const { addToast } = useCustomToast();

	const schema = yup.object().shape({
		commission: yup.array().of(
			yup.object().shape({
				indicator: yup
					.object()
					.required('Este campo é obrigatório.')
					.default(undefined)
					.nullable()
					.shape({
						label: yup.string().required('Este campo é obrigatório.'),
						value: yup.string().required('Este campo é obrigatório.'),
					}),
				base: yup
					.number()
					.required('Este campo é obrigatório.')
					.transform(value => (isNaN(value) ? undefined : value)),
				scaleOne: yup
					.number()
					.transform(value => (isNaN(value) ? undefined : value))
					.test('is required', 'Este campo é obrigatório.', value => {
						if (defaultValues?.scaleOne && !value) return false;

						return true;
					}),
				scaleTwo: yup
					.number()
					.transform(value => (isNaN(value) ? undefined : value))
					.test('isBiggerThenScaleOne', 'Valor deve ser maior que o prévio.', (value, context) => {
						const index = Number(context.path.replace(ONLY_NUMBERS_REGEX, ''));
						const scaleOne: number = Number(formValues?.commission?.[index]?.scaleOne || undefined);

						if (value && !isNaN(scaleOne)) return value >= scaleOne;
						return true;
					})
					.test('is required', 'Este campo é obrigatório.', value => {
						if (defaultValues?.scaleTwo && !value) return false;

						return true;
					}),
				scaleThree: yup
					.number()
					.transform(value => (isNaN(value) ? undefined : value))
					.test('isBiggerThenScaleTwo', 'Valor deve ser maior que o prévio.', (value, context) => {
						const index = Number(context.path.replace(ONLY_NUMBERS_REGEX, ''));
						const scaleOne: number = Number(formValues?.commission?.[index]?.scaleOne || undefined);
						const scaleTwo: number = Number(formValues?.commission?.[index]?.scaleTwo || undefined);

						if (value && !isNaN(scaleOne) && isNaN(scaleTwo)) return value >= scaleOne;
						if (value && !isNaN(scaleTwo)) return value >= scaleTwo;
						return true;
					})
					.test('is required', 'Este campo é obrigatório.', value => {
						if (defaultValues?.scaleThree && !value) return false;

						return true;
					}),
				scaleFour: yup
					.number()
					.transform(value => (isNaN(value) ? undefined : value))
					.test('isBiggerThenScaleThree', 'Valor deve ser maior que o prévio.', (value, context) => {
						const index = Number(context.path.replace(ONLY_NUMBERS_REGEX, ''));
						const scaleOne: number = Number(formValues?.commission?.[index]?.scaleOne || undefined);
						const scaleTwo: number = Number(formValues?.commission?.[index]?.scaleTwo || undefined);
						const scaleThree: number = Number(formValues?.commission?.[index]?.scaleThree || undefined);

						if (value && !isNaN(scaleOne) && isNaN(scaleTwo) && isNaN(scaleThree)) return value >= scaleOne;
						if (value && !isNaN(scaleTwo) && isNaN(scaleThree)) return value >= scaleTwo;
						if (value && !isNaN(scaleThree)) return value >= scaleThree;
						return true;
					})
					.test('is required', 'Este campo é obrigatório.', value => {
						if (defaultValues?.scaleFour && !value) return false;

						return true;
					}),
				scaleFive: yup
					.number()
					.transform(value => (isNaN(value) ? undefined : value))
					.test('isBiggerThenScaleFour', 'Valor deve ser maior que o prévio.', (value, context) => {
						const index = Number(context.path.replace(ONLY_NUMBERS_REGEX, ''));
						const scaleOne: number = Number(formValues?.commission?.[index]?.scaleOne || undefined);
						const scaleTwo: number = Number(formValues?.commission?.[index]?.scaleTwo || undefined);
						const scaleThree: number = Number(formValues?.commission?.[index]?.scaleThree || undefined);
						const scaleFour: number = Number(formValues?.commission?.[index]?.scaleFour || undefined);

						if (value && !isNaN(scaleOne) && isNaN(scaleTwo) && isNaN(scaleThree) && isNaN(scaleFour))
							return value >= scaleOne;
						if (value && !isNaN(scaleTwo) && isNaN(scaleThree) && isNaN(scaleFour)) return value >= scaleTwo;
						if (value && !isNaN(scaleThree) && isNaN(scaleFour)) return value >= scaleThree;
						if (value && !isNaN(scaleFour)) return value >= scaleFour;
						return true;
					})
					.test('is required', 'Este campo é obrigatório.', value => {
						if (defaultValues?.scaleFive && !value) return false;

						return true;
					}),
			}),
		),
	});

	const {
		control,
		reset,
		watch,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<ICommissionsForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			commission: [
				{
					indicator: undefined,
					base: undefined,
					scaleOne: undefined,
					scaleTwo: undefined,
					scaleThree: undefined,
					scaleFour: undefined,
					scaleFive: undefined,
				},
				...(defaultValues?.commission || []),
			],
			...defaultValues,
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'commission',
	});

	const formValues = watch();

	const { data: indicators, isLoading: isIndicatorsLoading } = useQuery<
		IPaginatedIndicators,
		AxiosError<ResponseErrors>
	>(
		['indicators', defaultValues?.company?.value],
		() => getIndicators({ companies: String(defaultValues?.company?.value), active: 'true' }),
		{
			onError: error => addToast({ type: 'error', title: 'Erro!', description: parseErrors(error?.response?.data) }),
			enabled: !!defaultValues?.company?.value,
		},
	);

	const handleNewArray = () => {
		append({
			indicator: undefined,
			base: undefined,
			scaleOne: undefined,
			scaleTwo: undefined,
			scaleThree: undefined,
			scaleFour: undefined,
			scaleFive: undefined,
		});
	};

	const handleGoBack = () => {
		watching?.(formValues);
		prevStep?.();
	};

	const defaultSelectedIndicators: IOption[] = useMemo(() => {
		if (defaultValues?.commission) {
			return defaultValues?.commission
				?.filter(commission =>
					indicators?.results.find(indicator => String(indicator.id) === String(commission.indicator?.value)),
				)
				.map(item => item.indicator) as IOption[];
		}

		return [] as IOption[];
	}, [defaultValues?.commission, indicators?.results]);

	const [selectedIndicators, setSelectedIndicators] = useState<IOption[]>(defaultSelectedIndicators);

	const filteredOptions = useMemo(() => {
		const updatedOptions = indicators?.results?.filter(
			option => !selectedIndicators.find((indicator: IOption) => indicator?.value === option.id),
		);
		return updatedOptions;
	}, [indicators?.results, selectedIndicators]);

	const handleIndicatorChange = (selectedOption: IOption, index: number) => {
		setValue(`commission.${index}.indicator`, selectedOption);
		const updatedIndicators: IOption[] = [...selectedIndicators];

		if (!selectedOption) {
			updatedIndicators.splice(index, 1);
		} else {
			updatedIndicators[index] = selectedOption;
		}

		setSelectedIndicators(updatedIndicators);
	};

	const handleOnSubmit = (data: ICommissionsForm) => {
		onSubmit(data);
	};

	useEffect(() => {
		reset(defaultValues);
		setSelectedIndicators(defaultSelectedIndicators);
	}, [defaultSelectedIndicators, defaultValues, reset]);

	const deleteCommission = (index: number) => {
		const indicator = getValues('commission')?.[index].indicator;
		remove(index);
		setSelectedIndicators(prev => prev.filter(e => e.value !== indicator?.value));
	};

	return (
		<Flex as="form" sx={styles.container} onSubmit={handleSubmit(handleOnSubmit)}>
			{isIndicatorsLoading ? (
				<Flex sx={styles.spinner}>
					<Spinner />
				</Flex>
			) : (
				fields?.map((item, index) => (
					<Flex sx={styles.arrayContainer} key={item.id}>
						<Text sx={styles.title}>Comissão</Text>

						<Grid sx={styles.grid}>
							<GridItem sx={styles.gridItem}>
								<Controller
									name={`commission.${index}.indicator`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<Select
											label="Indicador"
											placeholder="Indicador"
											options={parsedOptionArray(filteredOptions, 'name', 'id')}
											onChange={selectedOption => {
												handleIndicatorChange(selectedOption as IOption, index);
												onChange(selectedOption);
											}}
											value={value}
											variant="medium"
											formLabelVariant="small"
											errorMessageVariant="small"
											dataTestId={`select--indicator.${index}`}
											errorMessage={errors?.commission?.[index]?.indicator?.message}
										/>
									)}
								/>
							</GridItem>
							<GridItem>
								<Controller
									name={`commission.${index}.base`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<InputNumeric
											label="Base (%)"
											placeholder="Base"
											name={`commission.${index}.base`}
											onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
											value={value || ''}
											suffix="%"
											isAllowed={values => allowedPorcentage(values)}
											variant="small"
											formLabelVariant="small"
											errorMessageVariant="small"
											data-testid={`input--base.${index}`}
											errorMessage={errors?.commission?.[index]?.base?.message}
										/>
									)}
								/>
							</GridItem>
						</Grid>

						<Grid sx={styles.grid}>
							<GridItem>
								<Controller
									name={`commission.${index}.scaleOne`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<ScaleInput
											name={`commission.${index}.scaleOne`}
											label="Escala 1 (%)"
											placeholder="Escala 1"
											suffix="%"
											onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
											value={value || ''}
											dataTestId={`input--scaleOne.${index}`}
											errorMessage={errors?.commission?.[index]?.scaleOne?.message}
										/>
									)}
								/>
							</GridItem>
							<GridItem>
								<Controller
									name={`commission.${index}.scaleTwo`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<ScaleInput
											name={`commission.${index}.scaleTwo`}
											label="Escala 2 (%)"
											placeholder="Escala 2"
											suffix="%"
											onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
											value={value || ''}
											dataTestId={`input--scaleTwo.${index}`}
											errorMessage={errors?.commission?.[index]?.scaleTwo?.message}
										/>
									)}
								/>
							</GridItem>
							<GridItem>
								<Controller
									name={`commission.${index}.scaleThree`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<ScaleInput
											name={`commission.${index}.scaleThree`}
											label="Escala 3 (%)"
											placeholder="Escala 3"
											suffix="%"
											onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
											value={value || ''}
											dataTestId={`input--scaleThree.${index}`}
											errorMessage={errors?.commission?.[index]?.scaleThree?.message}
										/>
									)}
								/>
							</GridItem>
							<GridItem>
								<Controller
									name={`commission.${index}.scaleFour`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<ScaleInput
											name={`commission.${index}.scaleFour`}
											label="Escala 4 (%)"
											placeholder="Escala 4"
											suffix="%"
											onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
											value={value || ''}
											dataTestId={`input--scaleFour.${index}`}
											errorMessage={errors?.commission?.[index]?.scaleFour?.message}
										/>
									)}
								/>
							</GridItem>
							<GridItem>
								<Controller
									name={`commission.${index}.scaleFive`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<ScaleInput
											name={`commission.${index}.scaleFive`}
											label="Escala 5 (%)"
											placeholder="Escala 5"
											suffix="%"
											onChange={e => onChange(unMaskThousandsForScales(e.target.value))}
											value={value || ''}
											dataTestId={`input--scaleFive.${index}`}
											errorMessage={errors?.commission?.[index]?.scaleFive?.message}
										/>
									)}
								/>
							</GridItem>
							{fields.length > 1 && (
								<GridItem display="flex">
									<Flex h="64px" mt="auto">
										<IconButton
											sx={styles.deleteButton}
											aria-label="delete-icon"
											icon={<BsTrash />}
											onClick={() => deleteCommission(index)}
											data-testid={`delete--commission.${index}`}
										/>
									</Flex>
								</GridItem>
							)}
						</Grid>
					</Flex>
				))
			)}

			{!isIndicatorsLoading && filteredOptions?.length! > 0 && (
				<Flex justifyContent="flex-end">
					<Button variant="unstyled" onClick={handleNewArray} leftIcon={<AddIcon />} sx={styles.addButton}>
						Adicionar comissão
					</Button>
				</Flex>
			)}

			<Flex sx={styles.buttonContainer}>
				<Button sx={styles.button} variant="outlined" data-testid="button--cancel.2" onClick={handleGoBack}>
					Voltar
				</Button>
				<Button sx={styles.button} type="submit" data-testid="button--submit.2" isLoading={isLoadingButton}>
					{isEditing ? 'Salvar' : 'Cadastrar'}
				</Button>
			</Flex>
		</Flex>
	);
};

export default CommissionsForm;
