export const generateMonths = (numberOfMonths: number) => {
	const currentDate = new Date();

	// Array de tamanho numberOfMonths, preenchido com valores undefined
	const monthArray = Array.from({ length: numberOfMonths }, (_, _index) => {
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth();

		const monthAbbreviation = new Intl.DateTimeFormat('pt-BR', { month: 'short' }).format(new Date(year, month, 1));

		const monthObject = {
			label: `${monthAbbreviation.toUpperCase().slice(0, -1)}/${year}`,
			key: `${year}-${month + 1}`,
			columnCustomStyle: {
				textAlign: 'end',
			},
			headerColumnCustomStyle: {
				textAlign: 'center',
			},
		};

		currentDate.setMonth(currentDate.getMonth() - 1);

		return monthObject;
	});

	return monthArray;
};
