/**
 *
 * ScaleInput
 *
 */
import InputNumeric, { InputNumericProps } from '../InputNumeric';
import { allowedPorcentage } from 'utils/Numbers';

interface ScaleInputProps extends InputNumericProps {
	dataTestId: string;
	suffix?: string;
}

const ScaleInput = ({ dataTestId, suffix, ...props }: ScaleInputProps) => {
	return (
		<>
			<InputNumeric
				isAllowed={values => allowedPorcentage(values)}
				suffix={suffix}
				variant="small"
				formLabelVariant="small"
				errorMessageVariant="small"
				data-testid={dataTestId}
				{...props}
			/>
		</>
	);
};

export default ScaleInput;
