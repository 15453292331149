import Axios from 'axios';
import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { Session } from 'types/user';
import { AUTH_MANAGER_KEYS } from 'utils/constants';
export interface IRequestLogin {
	email: string;
	password: string;
}

export const login = async (data: IRequestLogin) => {
	const authApi = getAxios();
	const response = await authApi.post<Session>(ENDPOINTS.login, data);
	return response.data;
};

export const refreshToken = async () => {
	const apiKey = process.env.REACT_APP_API_KEY;
	const axios = Axios.create({
		baseURL: process.env.REACT_APP_API_BASE_URL,
		headers: apiKey ? { 'X-Gravitee-Api-Key': apiKey } : {},
	});

	const response = await axios.post<{ token: string; refreshToken: string }>(ENDPOINTS.refreshToken, {
		refreshToken: localStorage.getItem(AUTH_MANAGER_KEYS.REFRESH_TOKEN),
	});

	const { token, refreshToken } = response.data;

	localStorage.setItem(AUTH_MANAGER_KEYS.TOKEN, token);
	localStorage.setItem(AUTH_MANAGER_KEYS.REFRESH_TOKEN, refreshToken);
};
