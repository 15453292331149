import { useMemo } from 'react';
import { extendTheme } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Text: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: 'Boston',
		fontWeight: 500,
		fontSize: { base: '1.25rem' },
		color: 'black',
	},
	variants: {
		default: {},
		required: {
			color: 'red.500',
		},
		error: {
			color: 'red.500',
			fontStyle: 'italic',
		},
		errorFlow: {
			fontFamily: 'Boston',
			fontWeight: 500,
			fontSize: '0.875rem',
			color: 'red.500',
			fontStyle: 'italic',
			mt: '0rem',
			ml: '0rem',
		},
		bold: {
			fontSize: '1.563rem',
			fontWeight: 600,
		},
	},
	defaultProps: {
		variant: 'default',
	},
};

const Link: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: 'Boston',
		fontWeight: 500,
		fontSize: { base: '1.25rem' },
	},
	variants: {
		default: {
			color: 'blue.800',
			textDecoration: 'underline',
		},
		secondary: {
			color: 'blue.500',
			textDecoration: 'underline',
		},
	},
	defaultProps: {
		variant: 'default',
	},
};

const Input: ComponentStyleConfig = {
	baseStyle: {},
	variants: {
		default: {
			field: {
				fontFamily: 'Boston',
				fontWeight: 500,
				fontSize: { base: '1.25rem' },
				border: '1px solid',
				borderRadius: '5px',
				height: '4.8125rem', //77px
				padding: '1.56rem 1.87rem',
				_placeholder: {
					color: 'gray.400',
				},
				borderColor: 'gray.400',
				_invalid: {
					borderColor: 'red.500',
				},
				_disabled: {
					background: 'gray.90',
					color: 'gray.800',
					_placeholder: {
						color: 'gray.800',
					},
				},
			},
			_placeholder: {
				fontSize: { base: '1.25rem' },
			},
		},
		small: {
			field: {
				minHeight: '4rem',
				padding: '1rem 1.125rem',
				fontFamily: 'Boston',
				fontWeight: 500,
				fontSize: { base: '1.25rem' },
				border: '1px solid',
				borderRadius: '5px',
				height: '4rem',
				_placeholder: {
					color: 'gray.400',
				},
				borderColor: 'gray.400',
				_invalid: {
					borderColor: 'red.500',
				},
				_disabled: {
					background: 'gray.90',
					color: 'gray.800',
					_placeholder: {
						color: 'gray.800',
					},
				},
			},
			_placeholder: {
				fontSize: { base: '1.25rem' },
			},
		},
		minor: {
			field: {
				minHeight: '4rem',
				padding: '1rem 1.125rem',
				fontFamily: 'Boston',
				fontWeight: 500,
				fontSize: { base: '1.25rem' },
				border: '1px solid',
				borderRadius: '5px',
				height: '3.75rem',
				_placeholder: {
					color: 'gray.400',
				},
				borderColor: 'gray.400',
				_invalid: {
					borderColor: 'red.500',
				},
				_disabled: {
					background: 'gray.90',
					color: 'gray.800',
					_placeholder: {
						color: 'gray.800',
					},
				},
			},
			_placeholder: {
				fontSize: { base: '1.25rem' },
			},
		},
		noStyle: {
			field: {
				fontFamily: 'Boston',
				fontWeight: 400,
				fontSize: { base: '1.125rem' },
				border: '2px solid',
				borderRadius: '0.375rem',
				height: '1.75rem',
				maxW: '4rem',
				backgroundColor: 'transparent',
				textAlign: 'center',
				py: '1rem',
				_placeholder: {
					color: 'gray.400',
				},

				borderColor: 'transparent',

				_focus: {
					border: '2px solid #63B3ED',
				},
				_active: {
					border: '2px solid #63B3ED',
				},
			},
			_placeholder: {
				fontSize: { base: '1.125rem' },
			},
		},
	},
	defaultProps: {
		variant: 'default',
		size: '',
	},
};

const Select: ComponentStyleConfig = {
	baseStyle: {
		field: {
			fontFamily: 'Boston',
			fontWeight: 500,
			fontSize: { base: '1.25rem' },
			border: '1px solid',
			borderRadius: '5px',
			_disabled: {
				background: 'gray.90',
				color: 'gray.800',
				_placeholder: {
					color: 'gray.800',
				},
			},
		},
		_placeholder: {
			fontSize: { base: '1.25rem' },
		},
	},
	variants: {
		default: {
			field: {
				height: '4.8125rem',
				padding: '1.56rem 1.87rem',
				_placeholder: {
					color: 'gray.400',
				},
				borderColor: 'gray.400',
				_invalid: {
					borderColor: 'red.500',
				},
				_disabled: {
					background: 'gray.90',
					color: 'gray.800',
				},
			},
			_placeholder: {
				fontSize: { base: '1.25rem' },
			},
		},
		medium: {
			field: {
				background: 'transparent',
				height: '4rem',
				padding: '0.5rem 0.9375rem',
				_placeholder: {
					color: 'gray.400',
				},
				borderColor: 'gray.400',
				_invalid: {
					borderColor: 'red.500',
				},
				_disabled: {
					background: 'gray.90',
					color: 'gray.800',
				},
			},
			_placeholder: {
				fontSize: { base: '1.25rem' },
			},
		},
		small: {
			field: {
				background: 'transparent',
				height: '3rem',
				padding: '0.5rem 0.9375rem',
				_placeholder: {
					color: 'gray.400',
				},
				borderColor: 'gray.400',
				_invalid: {
					borderColor: 'red.500',
				},
				_disabled: {
					background: 'gray.90',
					color: 'gray.800',
				},
			},
			_placeholder: {
				fontSize: { base: '1.25rem' },
			},
		},
	},
	defaultProps: {
		variant: 'default',
		size: '',
	},
};

const FormLabel: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: 'Boston',
		fontWeight: 500,
		fontSize: { base: '1.25rem' },
		color: 'black',
		marginBottom: '0.75rem',
	},
	variants: {
		default: {},
		small: {
			fontSize: { base: '1.125rem' },
			mb: '0.813rem',
			mr: '0',
		},
	},
	defaultProps: {
		variant: 'default',
	},
};

const FormErrorMessage: ComponentStyleConfig = {
	baseStyle: {
		text: {
			fontFamily: 'Boston',
			fontWeight: 500,
			fontSize: { base: '1.25rem' },
			color: 'red.500',
			fontStyle: 'italic',
			ml: '0.5rem',
			mt: '0.9375rem',
		},
	},
	variants: {
		default: {},
		small: {
			text: {
				fontSize: { base: '0.875rem' }, //14px
				mt: '0rem',
				ml: '0rem',
			},
		},
	},
	defaultProps: {
		variant: 'default',
	},
};

const Heading: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: 'Boston',
		color: 'blue.800',
	},
	variants: {
		default: {
			fontWeight: 700,
			fontSize: { base: '2.5rem' },
		},
		normal: {
			fontWeight: 600,
			fontSize: { base: '1.875rem' },
		},
		stepper: {
			fontSize: { base: '1.25rem' },
			color: 'blue.500',
		},
	},
	defaultProps: {
		variant: 'default',
		size: '',
	},
};

const Button: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: 'Boston',
		fontWeight: 500,
		fontSize: { base: '1.75rem' },
		h: '5.3125rem',
		borderRadius: '5px',
		transition: '0.2s',
		m: '0',
	},
	variants: {
		solid: {
			background: 'blue.800',
			color: 'white',
			_hover: {
				background: 'blue.800',
				filter: 'brightness(0.8)',
			},
			_disabled: {
				filter: 'contrast(0.7)',
				cursor: 'not-allowed',
				_hover: {
					filter: 'contrast(0.7)',
				},
			},
		},
		outlined: {
			background: 'transparent',
			border: '1px solid',
			borderColor: 'blue.800',
			color: 'blue.800',
		},
		ghost: {
			background: 'transparent',
		},
	},
	defaultProps: {
		variant: 'solid',
		size: '',
	},
};

const IconButton: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: 'Boston',
		fontWeight: 500,
		fontSize: { base: '1.75rem' },
		h: '5.3125rem',
		borderRadius: '5px',
		transition: '0.2s',
	},
	variants: {
		solid: {
			background: 'blue.800',
			color: 'white',
			_hover: {
				background: 'blue.800',
				filter: 'brightness(0.8)',
			},
			_disabled: {
				filter: 'contrast(0.7)',
				cursor: 'not-allowed',
				_hover: {
					filter: 'contrast(0.7)',
				},
			},
		},
		outlined: {
			background: 'transparent',
			border: '1px solid',
			borderColor: 'blue.800',
			color: 'blue.800',
		},
		ghost: {
			background: 'transparent',
		},
	},
	defaultProps: {
		variant: 'solid',
		size: '',
	},
};

const Spinner: ComponentStyleConfig = {
	baseStyle: {
		color: 'blue.800',
		alignSelf: 'center',
	},
	variants: {},
	defaultProps: {
		variant: 'default',
		size: 'lg',
	},
};

const Table: ComponentStyleConfig = {
	baseStyle: {},
	variants: {
		default: {
			table: {
				borderCollapse: 'separate',
				borderSpacing: 0,
			},
			thead: {
				th: {
					fontFamily: 'Boston',
					fontWeight: 600,
					fontSize: { base: '1.125rem' },
					textTransform: 'inherit',
					color: 'white',
					padding: '0.875rem 1.8125rem',
					borderTop: '1px solid',
					borderBottom: '1px solid',
					borderRight: '1px solid',
					borderColor: 'gray.200',
				},

				'th:first-of-type': {
					borderLeft: '1px solid',
					borderColor: 'gray.200',
				},
			},
			tbody: {
				td: {
					fontFamily: 'Boston',
					fontSize: { base: '1.125rem' },
					padding: '0.875rem 1.8125rem',
					borderBottom: '1px solid',
					borderRight: '1px solid',
					borderColor: 'gray.200',
				},

				'td:first-of-type': {
					borderLeft: '1px solid ',
					borderColor: 'gray.200',
				},
			},
		},
		noStyled: {
			table: {
				borderCollapse: 'separate',
				borderSpacing: 0,
			},
			thead: {
				th: {
					fontFamily: 'Boston',
					fontWeight: 600,
					fontSize: { base: '1.125rem' },
					textTransform: 'inherit',
					color: 'white',
					padding: '0.875rem 1.8125rem',
					borderTop: '0',
					borderBottom: '0',
					borderRight: '0',
					borderColor: 'transparent',
				},

				'th:first-of-type': {
					borderLeft: '0px',
					borderColor: 'gray.200',
				},
			},
			tbody: {
				td: {
					fontFamily: 'Boston',
					fontSize: { base: '1.125rem' },
					padding: '0.875rem 1.8125rem',
					borderBottom: '0px',
					borderRight: '0px',
					borderColor: 'gray.200',
				},

				'td:first-of-type': {
					borderLeft: '0px ',
					borderColor: 'gray.200',
				},
			},
		},
		small: {
			table: {
				borderCollapse: 'separate',
				borderSpacing: 0,
			},
			thead: {
				th: {
					fontFamily: 'Boston',
					fontWeight: 600,
					fontSize: { base: '1rem' },
					textTransform: 'inherit',
					color: 'white',
					padding: '0.75rem 1rem',
					borderTop: '1px solid',
					borderBottom: '1px solid',
					borderRight: '1px solid',
					borderColor: 'gray.200',
				},

				'th:first-of-type': {
					borderLeft: '1px solid',
					borderColor: 'gray.200',
				},
			},
			tbody: {
				td: {
					fontFamily: 'Boston',
					fontSize: { base: '1rem' },
					padding: '0.75rem 1rem',
					borderBottom: '1px solid',
					borderRight: '1px solid',
					borderColor: 'gray.200',
				},

				'td:first-of-type': {
					borderLeft: '1px solid ',
					borderColor: 'gray.200',
				},
			},
		},
	},
	defaultProps: {
		variant: 'default',
		size: '',
	},
};

const Checkbox: ComponentStyleConfig = {
	baseStyle: {
		control: {
			h: '1.25rem',
			w: '1.25rem',
			border: '1.5px solid',
			borderColor: 'blue.500',
			borderRadius: '2.5px',
		},
		label: {
			fontSize: '1.125rem',
			fontWeight: '400',
		},
	},
	variants: {},
	defaultProps: {
		variant: 'default',
	},
};

export const useCustomTheme = (breakpoints: any) => {
	const customTheme = useMemo(
		() => ({
			styles: {
				global: {
					body: {
						bg: 'gray.80',
					},
					'input[type=password]::-ms-reveal, input[type=password]::-ms-clear': {
						display: 'none',
					},

					'::-webkit-scrollbar': {
						width: '16px',
						height: '16px',
						background: '#E6E4E4',
					},

					'::-webkit-scrollbar-corner ::-webkit-scrollbar-track': {
						backgroundColor: '#A3A3A3',
					},

					'::-webkit-scrollbar-thumb': {
						backgroundColor: '#A3A3A3',
						borderRadius: '10px',
						backgroundClip: 'padding-box',
					},

					'::-webkit-scrollbar-thumb:horizontal': {
						borderLeft: '2px solid transparent',
						borderRight: '2px solid transparent',
					},

					'::-webkit-scrollbar-thumb:vertical': {
						borderTop: '2px solid transparent',
						borderBottom: '2px solid transparent',
					},

					'::-webkit-scrollbar-thumb:active': {
						backgroundColor: 'rgb(128, 128, 128)',
					},

					/* Buttons */
					'::-webkit-scrollbar-button:single-button': {
						backgroundColor: '#A3A3A3',
						width: '20px',
						height: '20px',
						display: 'block',
						backgroundSize: '10px',
						backgroundRepeat: 'no-repeat',
					},

					/* Up */
					'::-webkit-scrollbar-button:single-button:vertical:decrement': {
						height: '14px',
						width: '14px',
						backgroundPosition: 'center 4px',
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='50,00 0,50 100,50'/></svg>")`,
					},

					'::-webkit-scrollbar-button:single-button:vertical:decrement:hover': {
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='50,00 0,50 100,50'/></svg>")`,
					},

					'::-webkit-scrollbar-button:single-button:vertical:decrement:active': {
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='50,00 0,50 100,50'/></svg>")`,
					},

					/* Down */
					'::-webkit-scrollbar-button:single-button:vertical:increment': {
						height: '14px',
						width: '14px',
						backgroundPosition: 'center 4px',
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='0,0 100,0 50,50'/></svg>")`,
					},

					'::-webkit-scrollbar-button:single-button:vertical:increment:hover': {
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='0,0 100,0 50,50'/></svg>")`,
					},

					'::-webkit-scrollbar-button:single-button:vertical:increment:active': {
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='0,0 100,0 50,50'/></svg>")`,
					},

					/* Left */
					'::-webkit-scrollbar-button:single-button:horizontal:decrement': {
						height: '14px',
						width: '14px',
						backgroundPosition: '4px 3px',
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='0,50 50,100 50,0'/></svg>")`,
					},

					'::-webkit-scrollbar-button:single-button:horizontal:decrement:hover': {
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='0,50 50,100 50,0'/></svg>")`,
					},

					'::-webkit-scrollbar-button:single-button:horizontal:decrement:active': {
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='0,50 50,100 50,0'/></svg>")`,
					},

					/* Right */
					'::-webkit-scrollbar-button:single-button:horizontal:increment': {
						height: '14px',
						width: '14px',
						backgroundPosition: '4px 3px',
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='0,0 0,100 50,50'/></svg>")`,
					},

					'::-webkit-scrollbar-button:single-button:horizontal:increment:hover': {
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='0,0 0,100 50,50'/></svg>")`,
					},

					'::-webkit-scrollbar-button:single-button:horizontal:increment:active': {
						backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(230, 228, 228)'><polygon points='0,0 0,100 50,50'/></svg>")`,
					},
				},
			},
			colors: {
				transparent: 'rgba(217, 217, 217, 0)',
				blue: {
					50: 'rgba(178, 208, 223, 0.2)',
					500: '#2E769B',
					800: '#173569',
				},
				white: '#FFFFFF',
				gray: {
					80: '#F7F7F7',
					85: '#F2F2F2',
					90: '#EEEEEE',
					100: '#F0F0F0',
					200: '#E2E2E2',
					250: '#EDEDED',
					300: '#D9D9D9',
					400: '#C4C4C4',
					500: '#9C9C9C',
					550: '#A3A3A3',
					600: '#566277',
					800: '#484848',
					900: '#3b3a3a',
				},
				black: '#000000',
				red: {
					500: '#FF0F0F',
				},
				green: {
					500: '#009E2C',
				},
				yellow: {
					500: '#E8A803',
				},
			},
			components: {
				Heading,
				Text,
				FormLabel,
				FormError: FormErrorMessage,
				Button,
				Link,
				Input,
				Select,
				IconButton,
				Spinner,
				Table,
				Checkbox,
			},
			size: {},
			fontSize: {},
		}),
		[],
	);

	return extendTheme({ breakpoints, ...customTheme });
};
