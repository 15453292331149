/**
 *
 * Select
 *
 */

import { ClearIndicator } from './CustomClearIndicator';
import { ReactNode } from 'react';
import { FormControl, FormErrorMessage, FormLabel, SystemStyleObject, Text, useStyleConfig } from '@chakra-ui/react';
import ReactSelect, {
	components,
	CSSObjectWithLabel,
	Props as ReactSelectProps,
	StylesConfig,
	ValueContainerProps,
} from 'react-select';

type InputStyles = {
	field: CSSObjectWithLabel;
} & SystemStyleObject;

export interface IOption {
	value: string | number | boolean | undefined | null;
	label: string;
	isDisabled?: boolean;
}

interface ISelectChildrenProperties {
	props: {};
}

interface SelectProps extends ReactSelectProps {
	dataTestId: string;
	options?: IOption[];
	label?: string;
	errorMessage?: string;
	required?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	variant?: string;
	placeholderSelectedItems?: string;
	dropdownIndicatorComponent?: React.ComponentType<any>;
	errorMessageVariant?: string;
	formLabelVariant?: string;
}

const Select = ({
	variant,
	options = [],
	label,
	errorMessage,
	required,
	isDisabled,
	dataTestId,
	placeholderSelectedItems = 'items selecionados',
	isMulti,
	dropdownIndicatorComponent: IndicatorComponet,
	errorMessageVariant,
	formLabelVariant,
	...rest
}: SelectProps) => {
	const ControlStyles: CSSObjectWithLabel = (useStyleConfig('Select', { variant }) as InputStyles)?.field;
	const customStyles: StylesConfig = {
		container: () => ({
			display: 'flex',
			flexDirection: 'column',
			height: 'fit-content',
			flex: 1,
		}),
		option: (styles, { isSelected }) => ({
			...styles,
			minHeight: '3.5rem',
			display: 'flex',
			alignItems: 'center',
			height: 'auto',
			color: isSelected ? 'var(--chakra-colors-blue-800)' : 'var(--chakra-colors-black)',
			fontWeight: isSelected ? 'bold' : 'normal',
			background: isSelected
				? 'linear-gradient(360deg, var(--chakra-colors-blue-50) 0%, var(--chakra-colors-transparent) 45.31%)'
				: 'white',
			borderBottom: '1px solid var(--chakra-colors-gray-400)',
			':last-child': {
				borderBottom: 'none',
				paddingBottom: 'none',
			},
			':active': {
				background: 'white',
			},
			cursor: 'pointer',
		}),
		control: styles => ({
			...styles,
			...ControlStyles,
			cursor: 'pointer',
			border: errorMessage ? '1px solid var(--chakra-colors-red-500)' : '1px solid var(--chakra-colors-gray-400)',
			opacity: isDisabled ? '0.4' : '1',
			boxShadow: 'none',
		}),
		valueContainer: styles => ({
			...styles,
			padding: '0',
			flexWrap: 'nowrap',
			bottom: variant !== 'medium' ? '3px' : '0',
		}),
		indicatorsContainer: styles => ({ ...styles, bottom: variant !== 'medium' ? '2px' : '0', position: 'relative' }),
		indicatorSeparator: styles => ({ ...styles, display: 'none' }),
		placeholder: styles => ({
			...styles,
			color: isDisabled ? 'var(--chakra-colors-gray-800)' : 'var(--chakra-colors-gray-400)',
			fontSize: '1.25rem',
		}),
		singleValue: styles => ({
			...styles,
			color: 'var(--chakra-colors-black)',
			fontSize: '1.125rem',
		}),
		multiValue: styles => ({
			...styles,
			background: 'transparent',
			padding: '0',
		}),
		multiValueLabel: styles => ({
			...styles,
			color: 'var(--chakra-colors-black)',
			fontSize: '1.125rem',
			padding: '0',
			paddingLeft: '-6px',
		}),
		multiValueRemove: styles => ({
			...styles,
			display: 'none',
		}),
	};
	const styles: Record<string, SystemStyleObject> = {
		defaultOption: {
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			p: '0.9375rem 1rem',
			borderBottom: '1px solid #C4C4C4',
			':last-child': {
				borderBottom: 'none',
			},
		},
		selected: {
			color: 'blue.800',
			fontWeight: '700',
		},
	};

	const allOption = { label: 'Todos', value: 'Todos' };

	const ValueContainer = ({ children, ...props }: ValueContainerProps) => {
		const { getValue } = props;
		const amountOfItems = getValue().length;

		if (amountOfItems > 1) {
			const [value, selectProps] = children as ISelectChildrenProperties[][];

			const formattedChildren = [
				[
					{
						...value[0],
						props: {
							...value[0]?.props,
							children: amountOfItems === options?.length ? 'Todos' : `${amountOfItems} ${placeholderSelectedItems}`,
						},
					},
				],
				selectProps,
			];

			return <components.ValueContainer {...props}>{formattedChildren as ReactNode}</components.ValueContainer>;
		}

		return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
	};

	const Option = (props: any) => {
		if (!options?.length) {
			return <Text sx={{ ...styles?.defaultOption, cursor: 'not-allowed' }}>Nenhuma opção!</Text>;
		}

		if (isMulti && props?.value === allOption?.value) {
			const allSelected = props?.getValue()?.length === options?.length;

			return (
				<Text
					sx={allSelected ? { ...styles?.defaultOption, ...styles?.selected } : { ...styles?.defaultOption }}
					onClick={() => (allSelected ? props.setValue([]) : props.setValue(options))}
				>
					{props?.label}
				</Text>
			);
		}
		return (
			<Text
				sx={props?.isSelected ? { ...styles?.defaultOption, ...styles?.selected } : { ...styles?.defaultOption }}
				onClick={() => props?.selectOption(props?.data)}
			>
				{props?.label}
			</Text>
		);
	};

	const DropdownIndicator = (props: any) => {
		if (IndicatorComponet) {
			return (
				<components.DropdownIndicator {...props}>
					<IndicatorComponet size={20} color="#000" />
				</components.DropdownIndicator>
			);
		} else {
			return <components.DropdownIndicator {...props} />;
		}
	};

	return (
		<FormControl sx={{ ...customStyles?.container }} isInvalid={!!errorMessage}>
			{label && (
				<FormLabel htmlFor={dataTestId} variant={formLabelVariant}>
					{label}{' '}
					{required && (
						<Text as="span" variant="required">
							*
						</Text>
					)}
				</FormLabel>
			)}
			<ReactSelect
				options={isMulti ? [allOption, ...options] : options}
				styles={customStyles}
				isDisabled={isDisabled}
				hideSelectedOptions={false}
				components={{
					ValueContainer,
					ClearIndicator,
					Option,
					DropdownIndicator,
					IndicatorSeparator: IndicatorComponet ? null : components.IndicatorSeparator,
				}}
				inputId={dataTestId}
				noOptionsMessage={() => <Text as="span">Nenhum dado encontrado.</Text>}
				isMulti={isMulti}
				{...rest}
			/>
			{errorMessage && (
				<FormErrorMessage data-testid="text--error" variant={errorMessageVariant}>
					{errorMessage}
				</FormErrorMessage>
			)}
		</FormControl>
	);
};

export default Select;
