import { Session } from 'types/user';
import { AUTH_MANAGER_KEYS } from 'utils/constants';

export const authManager = {
	set: async ({ accessToken, refreshToken, user }: Session) => {
		try {
			localStorage.setItem(AUTH_MANAGER_KEYS.TOKEN, accessToken || '');
			localStorage.setItem(AUTH_MANAGER_KEYS.REFRESH_TOKEN, refreshToken || '');
			localStorage.setItem(AUTH_MANAGER_KEYS.USER, JSON.stringify(user || ''));
			return true;
		} catch {
			return false;
		}
	},

	get: () => {
		const accessToken = localStorage.getItem(AUTH_MANAGER_KEYS.TOKEN);
		const refreshToken = localStorage.getItem(AUTH_MANAGER_KEYS.REFRESH_TOKEN);
		const user = localStorage.getItem(AUTH_MANAGER_KEYS.USER);

		return { accessToken, refreshToken, user };
	},

	clear: async () => {
		localStorage.removeItem(AUTH_MANAGER_KEYS.TOKEN);
		localStorage.removeItem(AUTH_MANAGER_KEYS.REFRESH_TOKEN);
		localStorage.removeItem(AUTH_MANAGER_KEYS.USER);
	},
};
