import { ONLY_NUMBERS_AND_COMMA_REGEX, THOUSAND_REGEX } from './constants';
import { NumberFormatValues } from 'react-number-format';

export const unMaskThousands = (receivedValue: string | number) => {
	if (typeof receivedValue === 'number') return receivedValue;

	return Number(String(receivedValue)?.replace(ONLY_NUMBERS_AND_COMMA_REGEX, '')?.replace(',', '.') || undefined);
};

export const addThousandFormat = (receivedValue: string | number, suffix = '') => {
	const value = String(receivedValue)?.replace(ONLY_NUMBERS_AND_COMMA_REGEX, '');

	const [integers, decimals] = value.split(',');

	const integersFormatted = integers.replace(THOUSAND_REGEX, '.');
	const decimalsFormatted = decimals === '' || !!decimals ? `,${decimals}` : '';

	return integersFormatted + decimalsFormatted + suffix;
};

export const maskThousands = (value: number, suffix = '') => {
	return addThousandFormat(String(value)?.replace('.', ',') || '0', suffix);
};

export const formatNumbers = (value: number, currency: boolean = false, decimals: number = 2) => {
	if (currency)
		return new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals,
		}).format(value);

	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	}).format(value);
};

export const allowedPorcentage = (value: NumberFormatValues) => {
	const MAX_INTEGER_NUMBERS = 3;
	return value.formattedValue.split(',')[0].length <= MAX_INTEGER_NUMBERS;
};

export const unMaskThousandsForScales = (receivedValue: string | number) => {
	if (typeof receivedValue === 'number') return receivedValue;

	return Number(String(receivedValue)?.replace(ONLY_NUMBERS_AND_COMMA_REGEX, '')?.replace(',', '.') || undefined);
};

export const fixNaN = (value: number) => {
	return isNaN(value) ? undefined : value;
};

interface INumberFormatOptions extends Intl.NumberFormatOptions {
	roundingMode?:
		| 'ceil'
		| 'floor'
		| 'expand'
		| 'trunc'
		| 'halfCeil'
		| 'halfFloor'
		| 'halfExpand'
		| 'halfTrunc'
		| 'halfEven';
}

export const formatDecimalNumber = (number?: string | number, symbol?: '*' | '%') => {
	const options: INumberFormatOptions = {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
		roundingMode: 'floor',
	};
	if (number !== 0 && !number) return '';

	const formated = Number(number)?.toLocaleString('pt-BR', options);

	if (symbol) return `${formated}${symbol}`;

	return formated;
};
