import { useContext } from 'react';
import { SessionContext } from 'contexts/SessionContext';
import { authManager } from 'services/authManager';
import { User } from 'types/user';

export function useSession() {
	const payload = useContext(SessionContext);

	if (!payload) {
		throw new Error('useSession must be used inside SessionContext Provider');
	}

	try {
		let { accessToken, refreshToken, user } = authManager.get();

		let parseUser = {} as User;

		if (user) parseUser = JSON.parse(user);

		return {
			...payload,
			session: {
				user: parseUser,
				accessToken: accessToken || '',
				refreshToken: refreshToken || '',
			},
			isAuth: !!accessToken && !!parseUser?.username,
		};
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
		return {
			...payload,
			isAuth: false,
		};
	}
}

export const sessionMock = (accessProfile: string[]) => {
	return {
		session: {
			user: {
				id: '1',
				username: 'JOHN DOE',
				phoneNumber: '11222222222',
				email: 'mail@mail.com',
				accessProfile,
				company: 'Nisa Hyundai Aparecida',
				department: 'new',
				role: 'Dev',
				isActive: true,
				profileImage: undefined,
				cpf: '23141971005',
			},
			accessToken: '',
			refreshToken: '',
		},
		isAuth: true,
		removeSession: () => {},
		startSession: () => {},
		updateSession: () => {},
	};
};
