/**
 *
 * TableGroup
 *
 */

import React from 'react';
import { Box, SystemStyleObject, Table as ChakraTable, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

interface IStyle {
	column: IColumnTableGroup;
	index?: number;
	isHeader?: boolean;
	isHeaderGroup?: boolean;
}

export interface IColumnTableGroup {
	label: string;
	key: string;
	order?: boolean;
	sticky?: 'left' | 'right';
	columnCustomStyle?: SystemStyleObject;
	headerColumnCustomStyle?: SystemStyleObject;
}
export interface IRowTableGroup {
	[key: string]: string | JSX.Element | boolean | Record<string, string>[] | undefined;
	showHeaderGroup?: boolean;
	children?: Record<string, string>[];
}

interface TableGroupProps {
	columns: IColumnTableGroup[];
	data: IRowTableGroup[];
	striped?: boolean;
	contentCustomStyle?: SystemStyleObject;
	headerCustomStyle?: SystemStyleObject;
	cellCustomStyle?: SystemStyleObject;
	cellStripedCustomStyle?: SystemStyleObject;
	cellHeaderGroupCustomStyle?: SystemStyleObject;
	variant?: string;
}

const TableGroup = ({
	columns,
	data,
	striped,
	contentCustomStyle,
	headerCustomStyle,
	cellCustomStyle,
	cellStripedCustomStyle,
	cellHeaderGroupCustomStyle,
	variant = 'default',
}: TableGroupProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			maxWidth: '112.75rem',
			width: { base: '20rem', md: '100%' },
			overflowX: 'auto',
			pb: '0.25rem',
		},
		content: {
			minW: '62.5rem',
			...contentCustomStyle,
		},
		header: {
			background: 'blue.800',
			...headerCustomStyle,
		},
		cell: {
			color: 'black',
			background: 'gray.85',
			...cellCustomStyle,
		},
		cellStriped: {
			background: 'gray.250',
			...cellStripedCustomStyle,
		},
		cellHeaderGroup: {
			background: 'gray.600',
			color: 'white',
			...cellHeaderGroupCustomStyle,
		},
		left: {
			position: 'sticky',
			left: 0,
		},
		right: {
			position: 'sticky',
			right: 0,
		},
	};

	let numberOfLine = 0;

	const itsPair = (number: number) => number % 2 === 0; // Verifica se o número é par para atribuição do estilo striped quando necessário

	const getStyle = ({ column, index = 0, isHeader = false, isHeaderGroup = false }: IStyle) => {
		// Monta estilização das células da tabela
		let style = { ...styles?.cell, ...column?.columnCustomStyle };

		if (striped) {
			if (itsPair(index)) {
				style = { ...style, ...styles?.cellStriped };
			} else {
				style = { ...style, ...styles?.cell };
			}
		}

		if (column?.sticky) {
			style = { ...style, ...styles[column?.sticky] };
		}

		if (isHeader) {
			style = { ...style, ...styles?.header, ...column?.columnCustomStyle, ...column?.headerColumnCustomStyle };
		}

		if (isHeaderGroup) {
			style = {
				...style,
				...styles?.cellHeaderGroup,
				...column?.columnCustomStyle,
				...column?.headerColumnCustomStyle,
			};
		}

		return style;
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.content}>
				<ChakraTable variant={variant}>
					<Thead>
						<Tr sx={styles.header}>
							{columns?.map(column => (
								<Th
									sx={getStyle({
										column,
										isHeader: true,
									})}
									key={column?.key}
								>
									{column?.label}
								</Th>
							))}
						</Tr>
					</Thead>
					<Tbody>
						{data?.map((rowGroup, rowGroupIndex: number) => (
							<React.Fragment key={`${rowGroupIndex}`}>
								<Tr>
									{columns?.map(
										column =>
											rowGroup?.showHeaderGroup && (
												<Td
													key={`${rowGroupIndex}-${column?.key}`}
													sx={getStyle({
														column,
														index: rowGroupIndex,
														isHeaderGroup: true,
													})}
												>
													{(rowGroup[column?.key] as string) || '-'}
												</Td>
											),
									)}
								</Tr>

								{rowGroup?.children?.map((row, rowGroupedLineIndex: number) => {
									numberOfLine = numberOfLine + 1;
									return (
										<React.Fragment key={`${rowGroupIndex}-${rowGroupedLineIndex}`}>
											<Tr>
												{columns?.map(column => (
													<React.Fragment key={`${rowGroupIndex}-${rowGroupedLineIndex}-${column?.key}`}>
														<Td
															sx={getStyle({
																column,
																index: numberOfLine,
															})}
														>
															{(row[column?.key] as string) || '-'}
														</Td>
													</React.Fragment>
												))}
											</Tr>
										</React.Fragment>
									);
								})}
							</React.Fragment>
						))}
					</Tbody>
				</ChakraTable>
			</Box>
		</Box>
	);
};

export default TableGroup;
