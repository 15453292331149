import { useEffect, useState } from 'react';
import { Box, Flex, Spinner, SystemStyleObject, Text } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import CommissionsForm from 'app/components/CommissionsForm';
import CommissionsScalesForm from 'app/components/CommissionsScalesForm';
import Stepper from 'app/components/Stepper';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { editCommission, getCommissionById } from 'services/http/commission';
import { Commission, IRequestCommissionScale, IStoreCommission } from 'types/commission';
import { DEPARTMENTS_OPTIONS } from 'utils/constants';
import { fixNaN } from 'utils/Numbers';
import { ResponseErrors, parseErrors } from 'utils/parseErrors';

const EditCommissions = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			h: 'full',
			minH: '100vh',
			mt: '6.25rem',
			justifyContent: 'center',
		},
		content: {
			maxW: '48.75rem',
			w: 'full',
			h: 'fit-content',
			p: '3rem',
			bgColor: 'white',
			flexDir: 'column',
		},
		stepHeader: {
			justifyContent: 'flex-end',
			gap: '2rem',
		},
		stepItem: {
			flexDir: 'column',
			alignItems: 'center',
		},
		text: {
			fontWeight: '700',
			fontSize: '1.313rem',
		},
		line: {
			h: '100%',
			w: '100%',
			borderBottom: '3px solid',
			borderRadius: '1.5px',
		},
		title: {
			mt: '2rem',
			mb: '0.625rem',
			fontWeight: '600',
			fontSize: '1.875rem',
		},
		spinnerBox: {
			justifyContent: 'center',
		},
	};
	const { commissionId = '' } = useParams();
	const [currentStep, setCurrentStep] = useState(0);
	const [formValues, setFormValues] = useState<IStoreCommission>({});

	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { data: commission, isLoading } = useQuery<Commission, AxiosError<ResponseErrors>>(
		['commissionById', commissionId],
		() => getCommissionById(commissionId),
		{
			onError: (errors: any) =>
				addToast({
					type: 'error',
					title: 'Erro!',
					description: parseErrors(errors?.response?.data),
				}),
			enabled: !!commissionId,
		},
	);

	useEffect(() => {
		if (commission) {
			setFormValues({
				...commission,
				company: { value: commission.company.id, label: commission.company.company },
				department: DEPARTMENTS_OPTIONS.find(department => department.value === commission.department),
				scaleOne: commission.scale1,
				scaleTwo: commission.scale2,
				scaleThree: commission.scale3,
				scaleFour: commission.scale4,
				scaleFive: commission.scale5,
				commission: commission.indicatorComissionValues.map(commission => ({
					indicator: { value: commission.indicator.id, label: commission.indicator.name },
					base: Number(commission.base),
					scaleOne: commission.scale1,
					scaleTwo: commission.scale2,
					scaleThree: commission.scale3,
					scaleFour: commission.scale4,
					scaleFive: commission.scale5,
				})),
			});
		}
	}, [commission]);

	const { mutate: editCommissionScaleMutate, isLoading: isLoadingCreateCommissionScale } = useMutation<
		IRequestCommissionScale,
		AxiosError<ResponseErrors>,
		IRequestCommissionScale
	>(data => editCommission(data, commissionId).then(() => data), {
		onSuccess: () => navigate(ROUTES.listCommissions),
		onError: errors => {
			addToast({
				type: 'error',
				title: 'Erro!',
				description: parseErrors(errors?.response?.data),
			});
		},
	});

	const handleOnSubmit = (data: IStoreCommission) => {
		setFormValues(prevState => ({ ...prevState, ...data }));
	};

	const handleCommissionScaleMutation = (data: IStoreCommission) => {
		const payload: IRequestCommissionScale = {
			comissionScale: {
				company: String(data?.company?.value || ''),
				department: String(data?.department?.value || ''),
				scale1: fixNaN(data?.scaleOne!),
				scale2: fixNaN(data?.scaleTwo!),
				scale3: fixNaN(data?.scaleThree!),
				scale4: fixNaN(data?.scaleFour!),
				scale5: fixNaN(data?.scaleFive!),
			},
			comissions:
				data?.commission?.map(commission => ({
					indicator: String(commission.indicator?.value || ''),
					base: commission?.base!,
					scale1: fixNaN(commission?.scaleOne!),
					scale2: fixNaN(commission?.scaleTwo!),
					scale3: fixNaN(commission?.scaleThree!),
					scale4: fixNaN(commission?.scaleFour!),
					scale5: fixNaN(commission?.scaleFive!),
				})) || [],
		};

		editCommissionScaleMutate(payload);
	};

	return (
		<Flex sx={styles.container}>
			<Flex sx={styles.content}>
				<Flex sx={styles.stepHeader}>
					<Flex sx={styles.stepItem}>
						<Text sx={{ ...styles.text, color: currentStep === 0 ? 'black' : '#00000080' }}>Escalas</Text>
						<Box sx={{ ...styles.line, borderBottomColor: currentStep === 0 ? 'blue.500' : '#2E769B80' }} />
					</Flex>
					<Flex sx={styles.stepItem}>
						<Text sx={{ ...styles.text, color: currentStep === 1 ? 'black' : '#00000080' }}>Comissão</Text>
						<Box sx={{ ...styles.line, borderBottomColor: currentStep === 1 ? 'blue.500' : '#2E769B80' }} />
					</Flex>
				</Flex>

				<Text sx={styles.title}>Editar comissão de loja</Text>

				{isLoading ? (
					<Flex sx={styles.spinnerBox}>
						<Spinner />
					</Flex>
				) : (
					<Stepper
						elements={[
							<CommissionsScalesForm onSubmit={handleOnSubmit} watching={handleOnSubmit} defaultValues={formValues} />,
							<CommissionsForm
								onSubmit={handleCommissionScaleMutation}
								watching={handleOnSubmit}
								defaultValues={formValues}
								isLoadingButton={isLoadingCreateCommissionScale}
								isEditing
							/>,
						]}
						currentStep={setCurrentStep}
					/>
				)}
			</Flex>
		</Flex>
	);
};

export default EditCommissions;
