/**
 *
 * CustomModal
 *
 */

import {
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { useIsMobile } from 'hooks/useIsMobile';

interface IModalAction {
	label: string;
	type: 'solid' | 'outlined';
	onClick: () => void;
	datatestid: 'button--redirect' | 'button--confirm' | 'button--cancel';
}

interface CustomModalProps {
	title: string;
	body?: string;
	onClose: () => void;
	isOpen: boolean;
	actions: IModalAction[];
	containerCustomStyle?: SystemStyleObject;
}

const CustomModal = ({ title, body, actions, isOpen, onClose, containerCustomStyle }: CustomModalProps) => {
	const isMobile = useIsMobile(400);

	const styles: Record<string, SystemStyleObject> = {
		container: {
			maxW: '39.938rem',
			p: isMobile ? '3.5rem 2rem' : '3.75rem 3.5rem',
			mx: '0.5rem',
			...containerCustomStyle,
		},
		title: {
			textAlign: 'center',
		},
		bodyContainer: {
			p: '0',
			mt: '1.875rem',
			w: '100%',
		},
		body: {
			fontWeight: '600',
			textAlign: 'center',
		},
		footerContainer: {
			justifyContent: 'center',
			w: '100%',
			p: '0',
			mt: '2.5rem',
		},
		footerContent: {
			w: '100%',
			justifyContent: 'center',
			gap: '1.875rem',
		},
		button: {
			p: '1.375rem 2.25rem',
			fontSize: '1.563rem',
			h: '4.813rem',
		},
	};

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent sx={styles.container} data-testid="modal">
				<ModalHeader p="0">
					<Text sx={styles.title} variant="bold">
						{title}
					</Text>
				</ModalHeader>
				{!!body && (
					<ModalBody sx={styles.bodyContainer} data-testid="modal-body">
						<Text sx={styles.body}>{body}</Text>
					</ModalBody>
				)}

				<ModalFooter sx={styles.footerContainer}>
					<Stack sx={styles.footerContent} direction={isMobile ? 'column' : 'row'}>
						{actions?.map(({ onClick, label, type, datatestid }, index) => (
							<Button variant={type} key={index} onClick={onClick} sx={styles.button} data-testid={datatestid}>
								{label}
							</Button>
						))}
					</Stack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default CustomModal;
