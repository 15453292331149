import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { Commission, IPaginatedCommissions, IRequestCommissionScale } from 'types/commission';
import { DEPARTMENTS_OPTIONS } from 'utils/constants';

type ValueOf<T> = T extends { value: infer V } ? V : never;
export type DepartmentValue = ValueOf<(typeof DEPARTMENTS_OPTIONS)[number]>;

export interface CommissionParams {
	company?: string | number;
	department?: DepartmentValue | string;
	page?: number;
	page_size?: number;
	ordering?: string;
}

export const getCommissions = async (params?: CommissionParams): Promise<IPaginatedCommissions> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.listCommissions, { params });
	return response.data;
};

export const deleteCommission = async (commissionId: string): Promise<void> => {
	const api = getAxios();
	return api.delete(ENDPOINTS.deleteComission(commissionId));
};

export const createCommissionScale = async (data: IRequestCommissionScale): Promise<IRequestCommissionScale> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.createCommissionScale, data);
	return response.data;
};

export const getCommissionById = async (commissionId: string): Promise<Commission> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.getCommissionById(commissionId));
	return response.data;
};

export const editCommission = async (data: IRequestCommissionScale, commissionId: string): Promise<void> => {
	const api = getAxios();
	const response = await api.put(ENDPOINTS.editCommission(commissionId), data);
	return response.data;
};
