import { TOTAL_PERCENTAGE } from './constants';
import { formatNumbers } from './Numbers';

export const commissionOnSaleHelper = (totalSales: number) => {
	let commissionOnSaleA = 0;

	if (totalSales < 10) {
		commissionOnSaleA = 0.0;
	} else if (totalSales < 13) {
		commissionOnSaleA = 0.05;
	} else if (totalSales < 16) {
		commissionOnSaleA = 0.15;
	} else if (totalSales < 20) {
		commissionOnSaleA = 0.2;
	} else {
		commissionOnSaleA = 0.25;
	}

	return commissionOnSaleA;
};

export const commissionOnMarginHelper = (totalSales: number) => {
	let commissionOnMarginA;

	if (totalSales < 10) {
		commissionOnMarginA = 0.0;
	} else if (totalSales < 13) {
		commissionOnMarginA = 1.0;
	} else {
		commissionOnMarginA = 2.0;
	}

	return commissionOnMarginA;
};

export const financingAwardHelper = (totalSales: number) => {
	let financingAwardA = 0;

	if (totalSales < 10) {
		financingAwardA = 0.0;
	} else if (totalSales < 13) {
		financingAwardA = 2.3049;
	} else if (totalSales < 16) {
		financingAwardA = 3.0732;
	} else {
		financingAwardA = 3.8415;
	}

	return financingAwardA;
};

export const forwardingAndTccbAwardHelper = (totalSales: number) => {
	let forwardingAwardA;

	if (totalSales < 10) {
		forwardingAwardA = 0.0;
	} else if (totalSales < 13) {
		forwardingAwardA = 3.0;
	} else if (totalSales < 16) {
		forwardingAwardA = 4.0;
	} else {
		forwardingAwardA = 5.0;
	}

	return forwardingAwardA;
};

export const formattedCommission = (comission: number, rawValue: number) => {
	const percentage = comission / TOTAL_PERCENTAGE;
	return formatNumbers(rawValue * percentage);
};
