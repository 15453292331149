import React from 'react';
import { SystemStyleObject, Box, Flex, useBreakpointValue } from '@chakra-ui/react';

interface PaginationProps {
	onPaginate: (page: number) => void;
	currentPage: number;
	totalItems?: number;
	pageSize?: number;
}

const Pagination = ({ onPaginate, currentPage, totalItems = 0, pageSize = 0 }: PaginationProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			gap: '0.4375rem',
		},
		defaultButtonStyle: {
			userSelect: 'none',
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: 'transparent',
			color: 'black',
			w: '2.5625rem',
			h: '2.5625rem',
			borderRadius: '0',
			border: '1px solid',
			borderColor: 'gray.200',
			transition: '0.2s',
			_hover: {
				backgroundColor: 'transparent',
				filter: 'brightness(0.8)',
			},
		},
		activePage: {
			backgroundColor: 'blue.800',
			color: 'white',
			border: '1px solid',
			borderColor: 'blue.800',
			_hover: {
				backgroundColor: 'blue.800',
				filter: 'brightness(0.8)',
			},
		},
		inactivePage: {
			w: {
				base: '1.7rem',
				sm: '2.5625rem',
			},
			cursor: 'not-allowed',
			color: 'blue.800',
			borderRadius: '0',

			border: '0',
			_hover: {},
		},
	};

	const buttons = useBreakpointValue(
		{
			base: '3',
			md: '4',
		},
		{ fallback: 'base' },
	);

	const pages = Math.ceil(totalItems / pageSize);

	return (
		<Flex sx={styles?.container}>
			{Array.from({ length: pages }, (_, index) => {
				const page = index + 1;

				let qtdButtons = Number(buttons);

				if (currentPage > 1 && currentPage < pages) qtdButtons = Number(buttons) - 1;

				const showButton = page > currentPage - qtdButtons && page < currentPage + qtdButtons;

				// Render fisrt page and [...]
				if (!showButton && page === 1) {
					return (
						<React.Fragment key={`group-dots-${page}`}>
							<Box
								key={page}
								onClick={() => onPaginate(page)}
								data-testid={currentPage === page ? 'button--paginate--selected' : `button--paginate-${page}`}
								sx={
									currentPage === page
										? { ...styles?.defaultButtonStyle, ...styles?.activePage }
										: styles?.defaultButtonStyle
								}
							>
								{page}
							</Box>

							{currentPage - qtdButtons !== page && (
								<Box
									key={`dots-${page}`}
									data-testid="button--dots-init"
									sx={{ ...styles?.defaultButtonStyle, ...styles?.inactivePage }}
								>
									...
								</Box>
							)}
						</React.Fragment>
					);
				}

				// Ultima página e [...]
				if (!showButton && page === pages) {
					return (
						<React.Fragment key={`group-dots-${page}`}>
							{currentPage + qtdButtons !== pages && (
								<Box
									key={`dots-${page}`}
									data-testid="button--dots-finish"
									sx={{ ...styles?.defaultButtonStyle, ...styles?.inactivePage }}
								>
									...
								</Box>
							)}
							<Box
								key={page}
								onClick={() => onPaginate(page)}
								data-testid={currentPage === page ? 'button--paginate--selected' : `button--paginate-${page}`}
								sx={
									currentPage === page
										? { ...styles?.defaultButtonStyle, ...styles?.activePage }
										: styles?.defaultButtonStyle
								}
							>
								{page}
							</Box>
						</React.Fragment>
					);
				}

				if (showButton) {
					return (
						<Box
							key={page}
							onClick={() => onPaginate(page)}
							data-testid={currentPage === page ? 'button--paginate--selected' : `button--paginate-${page}`}
							sx={
								currentPage === page
									? { ...styles?.defaultButtonStyle, ...styles?.activePage }
									: styles?.defaultButtonStyle
							}
						>
							{page}
						</Box>
					);
				}

				return false;
			})}
		</Flex>
	);
};

export default Pagination;
