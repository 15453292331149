/**
 *
 * UserFilter
 *
 */

import Select, { IOption } from '../Select';
import { useMemo } from 'react';
import { Box, Flex, Grid, HStack, SystemStyleObject, Text } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { User } from 'types/user';
import { DEPARTMENTS_OPTIONS } from 'utils/constants';
import { parsedOptionArray } from 'utils/parseOptionArray';

interface IUserFilterForm {
	employee: IOption;
	company: IOption;
	department: IOption;
}

interface UserFilterProps {
	companies: string[];
	company?: string;
	department?: string;
	employees: User[];
	employee?: User;
	isTransparent?: boolean;
	labelEmployee?: string;
	isReadOnly?: boolean;
	title?: string;
	showDepartment?: boolean;
	isUserPermited?: boolean;
}

const UserFilter = ({
	companies,
	company,
	employees,
	employee,
	isTransparent = false,
	isReadOnly = false,
	labelEmployee = 'Vendedor',
	title = 'Filtro',
	showDepartment = false,
	isUserPermited = false,
	department,
}: UserFilterProps) => {
	let [searchParams, setSearchParams] = useSearchParams();

	const styles: Record<string, SystemStyleObject> = {
		base: {
			backgroundColor: isTransparent ? 'transparent' : '#ededed',
			padding: isTransparent ? '0' : '1.563rem',
			justifyContent: 'left',
		},
		container: {
			flexDirection: {
				base: 'column',
				md: 'row',
			},
			width: '100%',
			rowGap: '2rem',
			spacing: {
				base: '0',
				md: '2rem',
			},
		},
		sectionTitle: {
			fontSize: '1.375rem',
			fontWeight: 600,
			color: 'blue.500',
			mb: '1.25rem',
			ml: isTransparent ? '0' : '1rem',
		},
		gridFields: {
			gap: '3.75rem',
			rowGap: '2.5rem',
			gridTemplateColumns: {
				md: 'repeat(1, 1fr)',
				lg: 'repeat(3, 1fr)',
			},
		},
		access: {
			gap: '3rem',
			padding: isTransparent ? '0' : '1rem',
		},
		box: {
			marginBottom: '1.563rem',
		},
	};

	const parsedCompanies = useMemo(() => {
		return parsedOptionArray(companies);
	}, [companies]);

	const parsedEmployees = useMemo(() => {
		return parsedOptionArray<User>(employees, 'username', 'id');
	}, [employees]);

	const { control, getValues, resetField } = useForm<IUserFilterForm>({
		defaultValues: {
			company: company ? { label: company, value: company } : undefined,
			employee: employee?.username ? { label: employee?.username, value: employee?.id } : undefined,
			department: department ? DEPARTMENTS_OPTIONS.find(dep => dep?.label === department) : undefined,
		},
	});

	const handleNavigate = () => {
		const params = {
			unidade: getValues('company.value') as string,
			usuario: getValues('employee.value') as string,
			departamento: getValues('department.label') as string,
			page: searchParams?.get('page') || '1',
		};
		const parsedParams = new URLSearchParams();

		for (const [key, value] of Object.entries(params)) {
			if (value) parsedParams.append(key, value);
		}

		setSearchParams(parsedParams);
	};

	return (
		<>
			<Flex sx={styles.base}>
				<Box sx={styles.box}>
					<Text sx={styles.sectionTitle}>{title}</Text>

					<HStack sx={styles.container}>
						<Grid sx={styles.gridFields}>
							<Box sx={styles.access}>
								<Controller
									name="company"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Select
											label="Unidade"
											placeholder="Selecione a unidade"
											onChange={option => {
												onChange(option);
												resetField('employee');
												handleNavigate();
											}}
											value={value}
											options={parsedCompanies}
											dataTestId="input--unity"
											isDisabled={isReadOnly || isUserPermited}
										/>
									)}
								/>
							</Box>
							{showDepartment && (
								<Box sx={styles.access}>
									<Controller
										name="department"
										control={control}
										render={({ field: { onChange, value } }) => (
											<Select
												label="Departamento"
												placeholder="Selecione o departamento"
												onChange={option => {
													onChange(option);
													handleNavigate();
												}}
												value={value}
												options={DEPARTMENTS_OPTIONS}
												dataTestId="input--department"
												isDisabled={!getValues('company') || isReadOnly || isUserPermited}
											/>
										)}
									/>
								</Box>
							)}
							<Box sx={styles.access}>
								<Controller
									name="employee"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Select
											isDisabled={(showDepartment ? !getValues('department') : !getValues('company')) || isReadOnly}
											label={labelEmployee}
											placeholder={`Selecione o ${labelEmployee.toLowerCase()}`}
											onChange={option => {
												onChange(option);
												handleNavigate();
											}}
											value={value}
											options={parsedEmployees}
											dataTestId="input--employee"
										/>
									)}
								/>
							</Box>
						</Grid>
					</HStack>
				</Box>
			</Flex>
		</>
	);
};

export default UserFilter;
