import { getToast } from './useToast';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ROUTES } from 'config/routes';
import { authManager } from 'services/authManager';
import { refreshToken } from 'services/http/login';
import { STATUS_CODE, TIME_TO_REDIRECT } from 'utils/constants';

interface IAxiosConfig extends AxiosRequestConfig {
	retry?: boolean;
}

const redirect = (path: string) => setTimeout(() => (window.location.href = path), TIME_TO_REDIRECT);

export const tokenErrorLogout = () => {
	getToast({ type: 'error', description: 'Sua sessão expirou, faça login novamente!' });
	authManager.clear();
	redirect(ROUTES.login);
};

export function createAxiosInstance() {
	const axios = Axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

	axios.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			const { accessToken } = authManager.get();
			const apiKey = process.env.REACT_APP_API_KEY;

			if (accessToken && config.headers) config.headers.Authorization = `Bearer ${accessToken}`;

			if (apiKey && config.headers) config.headers['X-Gravitee-Api-Key'] = apiKey;

			return config;
		},
		error => {
			return Promise.reject(error);
		},
	);

	axios.interceptors.response.use(
		response => response,
		async (error: AxiosError) => {
			const originalConfig = error.config as IAxiosConfig;

			if (error?.response?.status === STATUS_CODE.Unauthorized && !originalConfig.retry) {
				try {
					originalConfig.retry = true;
					await refreshToken();
					await axios(originalConfig);
					//
				} catch (error) {
					tokenErrorLogout();
				}
			}

			return Promise.reject(error);
		},
	);

	return axios;
}

export default function useAxios() {
	return createAxiosInstance();
}

export function getAxios() {
	return createAxiosInstance();
}
