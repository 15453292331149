import InputTextarea from '../InputTextarea';
import { AddIcon } from '@chakra-ui/icons';
import {
	Button,
	Flex,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { Control, Controller, FieldErrors, useFieldArray } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';

/**
 *
 * AttentionPoints
 *
 */
interface IAttentionPoint {
	point?: string;
}

export interface AttentionPointForm {
	attentionPoints?: IAttentionPoint[];
}

interface AttentionPointsProps {
	control: Control<AttentionPointForm>;
	errors: FieldErrors<AttentionPointForm>;
	onClose: () => void;
	isOpen: boolean;
}

export const attentionsPointsButton = (onClick: () => void, styles: Record<string, SystemStyleObject>) => {
	return (
		<Flex justifyContent="flex-end">
			<Button
				variant="unstyled"
				sx={styles.addButton}
				leftIcon={<AddIcon />}
				onClick={onClick}
				data-testid="button--add-attentionPoints"
			>
				Adicionar pontos de atenção
			</Button>
		</Flex>
	);
};

const AttentionPoints = ({ control, errors, isOpen, onClose }: AttentionPointsProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			w: '100%',
			flexDir: 'column',
			mt: '0.313rem',
			p: '1rem 2rem',
		},
		content: {
			w: '100%',
			flexDir: 'column',
			mt: '0.5rem',
			mb: '2.5rem',
			gap: '2rem',
		},
		fieldItem: {
			w: '100%',
			gap: '1.875rem',
		},
		textarea: {
			h: '6rem',
			resize: 'none',
		},
		addButton: {
			fontSize: '1.125rem',
			fontWeight: '600',
			color: 'blue.500',
			h: 'fit-content',
			display: 'flex',
			alignItems: 'center',
			mb: '15px',
		},
		deleteButton: {
			fontSize: '1rem',
			backgroundColor: '#AE0000',
			w: '35px',
			h: '35px',
			m: 'auto 0',
			_hover: {
				backgroundColor: '#740303',
			},
		},
		modalContent: {
			minW: '25rem',
			maxW: '50rem',
			m: '2rem',
		},
		backButtonContainer: {
			justifyContent: 'flex-end',
		},
		backButton: {
			fontWeight: '600',
			fontSize: '1.375rem',
			px: '2rem',
			h: '4rem',
			w: {
				base: '100%',
				md: 'fit-content',
			},
		},
		title: {
			fontSize: '1.875rem',
			mb: '2rem',
		},
	};

	const { fields, append, remove } = useFieldArray({
		name: 'attentionPoints',
		keyName: 'code',
		control,
	});

	const firstInputIndex = 0;
	const hasNotFirstInput = fields.length < 1;
	const hasMultipleFields = fields.length > 1;

	if (hasNotFirstInput) append({ point: undefined });

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose} size={'100%'}>
			<ModalOverlay />
			<ModalContent sx={styles.modalContent}>
				<ModalHeader>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody>
					<Flex sx={styles.container}>
						<Text sx={styles.title}>Cadastrar Pontos de atenção</Text>
						{!!fields?.length && (
							<Text fontSize="1rem">
								{fields.length === 1 ? 'Ponto de atenção' : 'Pontos de atenção (limite de 5 pontos no total)'}
							</Text>
						)}

						{!!fields?.length && (
							<Flex sx={styles.content}>
								{fields.map((item, index) => (
									<Flex key={item.code} sx={styles.fieldItem}>
										<Controller
											name={`attentionPoints.${index}.point`}
											control={control}
											render={({ field: { value, onChange } }) => (
												<InputTextarea
													sx={styles?.textarea}
													name={`attentionPoints.${index}.point`}
													placeholder="Digite os pontos de atenção "
													value={value || ''}
													onChange={onChange}
													errorMessageVariant="small"
													data-testid={`input--attentionPoints-point.${index}`}
													errorMessage={errors?.attentionPoints?.[index]?.point?.message}
												/>
											)}
										/>

										{(index > firstInputIndex || hasMultipleFields) && (
											<Flex>
												<IconButton
													sx={styles.deleteButton}
													aria-label="delete-icon"
													icon={<BsTrash />}
													onClick={() => remove(index)}
													data-testid={`button--delete-attentionPoints-point.${index}`}
												/>
											</Flex>
										)}
									</Flex>
								))}
							</Flex>
						)}

						{fields.length < 5 && (
							<Flex justifyContent="flex-end">
								<Button
									variant="unstyled"
									sx={styles.addButton}
									leftIcon={<AddIcon />}
									onClick={() => {
										append({ point: undefined });
									}}
									data-testid="button--add-attentionPoints"
								>
									Adicionar pontos de atenção
								</Button>
							</Flex>
						)}
						<Flex sx={styles.backButtonContainer}>
							<Button sx={styles.backButton} onClick={onClose} data-testid="button--submit">
								Voltar
							</Button>
						</Flex>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default AttentionPoints;
