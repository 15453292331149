/**
 *
 * Stepper
 *
 */

import React, { cloneElement, useCallback, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface StepperProps {
	elements: JSX.Element[];
	currentStep?: React.Dispatch<React.SetStateAction<number>>;
}

const Stepper = ({ elements, currentStep }: StepperProps) => {
	const [stepNumber, setStepNumber] = useState(0);

	const nextStep = useCallback(() => {
		if (stepNumber >= elements.length - 1) return;
		setStepNumber(stepNumber + 1);
		currentStep?.(stepNumber + 1);
	}, [currentStep, elements.length, stepNumber]);

	const prevStep = useCallback(() => {
		if (stepNumber <= 0) return;
		setStepNumber(stepNumber - 1);
		currentStep?.(stepNumber - 1);
	}, [currentStep, stepNumber]);

	return (
		<>
			<Flex>
				{elements.map((element, index) => {
					return (
						<Box width="100%" display={stepNumber === index ? 'initial' : 'none'} key={index}>
							{cloneElement(element, {
								nextStep,
								prevStep,
							})}
						</Box>
					);
				})}
			</Flex>
		</>
	);
};

export default Stepper;
