import { IOption } from 'app/components/Select';
import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { Company } from 'types/company';
import {
	Flow,
	IAccessories,
	ICall,
	IDispatcher,
	IFinancing,
	ILeads,
	IPriceOfPlacement,
	IProspection,
	ISalary,
	IServiceChannels,
	IShopFlow,
	ISignature,
	ITccb,
	IUsedPickup,
	IVehicleProfitability,
	IWorkshop,
} from 'types/flow';
import { User } from 'types/user';

const api = getAxios();

export interface IRequestGetFlowParams {
	user_seller?: string;
	company?: string | IOption | number | boolean | undefined;
	year?: number;
	month?: number;
}

export interface IResponseGetFlow {
	totalRecords: number;
	recordsInThisPage: number;
	totalPages: number;
	currentPage: number;
	results: Flow[];
}

export interface IResponseGetFlowById {
	appointmentRecord: {
		id: number;
		shopFlow: IShopFlow;
		calls: ICall;
		prospection: IProspection;
		leads: ILeads;
		workshop: IWorkshop;
		vehicleProfitability: IVehicleProfitability;
		financing: IFinancing;
		tccb: ITccb;
		priceOfPlacement: IPriceOfPlacement;
		dispatcher: IDispatcher;
		accessories: IAccessories;
		usedPickup: IUsedPickup;
		attentionPoints: string[];
		user: User;
		company: Company;
		serviceChannels: IServiceChannels;
		salary: ISalary;
		monthYear: string;
	};
	signatures: ISignature[];
}

export interface IResponseGetIndicators {
	[key: string]: string;
}

export interface IRequestHistoryIndicators {
	company_id: number;
	indicator_type: string;
	users: string[];
}

export interface IMonthsOfTheYear {
	[key: string]: number;
}

export interface IResponseHistoryIndicators {
	[key: string]: IMonthsOfTheYear;
}

export interface IResponseAttendenceSimulatorValues {
	totalSellersInCompany: number;
	averageStoreFlowPassing: number;
	averageIncomingCalls: number;
	averageCallAttendance: number;
	averageTicket: number;
	marginParameter: number;
}

export interface IRequestCreateFlow {
	shopFlow: IShopFlow;
	calls: ICall;
	prospection: IProspection;
	leads: ILeads;
	workshop: IWorkshop;
	vehicleProfitability: IVehicleProfitability;
	financing: IFinancing;
	tccb: ITccb;
	dispatcher: IDispatcher;
	accessories: IAccessories;
	usedPickup: IUsedPickup;
	priceOfPlacement: IPriceOfPlacement;
	attentionPoints?: string[]; //PONTOS DE ATENÇÃO
	user: number; //ID DO USUÁRIO
	company: Company; //UNIDADE DO USUÁRIO
	serviceChannels: IServiceChannels; //CANAIS DE COMUNICAÇÃO
	salary: ISalary;
	monthYear: string;
}

export interface IResponseCreateFlow {
	id: number;
	shopFlow: IShopFlow;
	calls: ICall;
	prospection: IProspection;
	leads: ILeads;
	workshop: IWorkshop;
	vehicleProfitability: IVehicleProfitability;
	financing: IFinancing;
	tccb: ITccb;
	dispatcher: IDispatcher;
	accessories: IAccessories;
	usedPickup: IUsedPickup;
	priceOfPlacement: IPriceOfPlacement;
	attentionPoints?: string[]; //PONTOS DE ATENÇÃO
	user: number; //ID DO USUÁRIO
	company: Company; //UNIDADE DO USUÁRIO
	serviceChannels: IServiceChannels; //CANAIS DE COMUNICAÇÃO
	salary: ISalary;
	monthYear: string;
}

export interface IResponseSalaryCalculationValues {
	financingIncome: number; //RECEITA FINANCIAMENTO
	revenueTccb: number; //RECETIA TCCB
	dispatcherRevenue: number; //RECEITA DESPACHANTE
	captureAward: number; //PREMIAÇÃO POR CAPTURA
	saleAccessories: number; //VENDA ACESSORIOS
	commissionOnSale: number; //COMISSAO SOBRE VENDA
	comissionOnMargin: number; //COMISSAO SOBRE MARGEM
}

export const getFlow = async (params: IRequestGetFlowParams): Promise<IResponseGetFlow> => {
	const response = await api.get(ENDPOINTS.getFlow, { params });
	return response.data;
};

export const getIndicators = async (): Promise<IResponseGetIndicators> => {
	const response = await api.get(ENDPOINTS.getIndicators);
	return response.data;
};

export const getHistoryIndicators = async (data: IRequestHistoryIndicators): Promise<IResponseHistoryIndicators[]> => {
	const response = await api.post(ENDPOINTS.getHistoryIndicators, data);
	return response.data;
};

export const getHistoryYears = async (): Promise<number[]> => {
	const response = await api.get(ENDPOINTS.getHistoryYears);
	return response.data;
};

export const getAttendenceSimulatorValues = async (userId: number): Promise<IResponseAttendenceSimulatorValues> => {
	const response = await api.post(ENDPOINTS.getAttendenceSimulatorValues, { userId });
	return {
		...response.data,
		averageTicket: 105000,
		marginParameter: 5.5,
	};
};

export const getSalaryCalculationValues = async (): Promise<IResponseSalaryCalculationValues> => {
	const response = await api.get(ENDPOINTS.getSalaryCalculationValues);
	return response.data;
};

export const getFlowById = async (flowId: string): Promise<IResponseGetFlowById> => {
	const response = await api?.get<IResponseGetFlowById>(ENDPOINTS.getFlowById(flowId));
	return response?.data;
};

export const createFlow = async (data: IRequestCreateFlow): Promise<IResponseCreateFlow> => {
	const response = await api.post(ENDPOINTS.createFlow, data);
	return response.data;
};

export const editFlow = async (data: IRequestCreateFlow, flowId: string): Promise<IResponseCreateFlow> => {
	const response = await api.put(ENDPOINTS.editFlow(flowId), data);
	return response.data;
};

export const deleteFlow = async (flowId: string): Promise<void> => {
	return api.delete(ENDPOINTS.deleteFlow(flowId));
};

export interface ISignFlowRequest {
	appointmentRecord: number;
}

export interface ISignFlowResponse {
	appointmentRecord: number;
}

export const signFlow = async (data: ISignFlowRequest): Promise<ISignFlowResponse> => {
	return api.post(ENDPOINTS.signFlow, data);
};
