import { IOption } from '.';
import { Icon } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { ClearIndicatorProps } from 'react-select';

export const ClearIndicator = (props: ClearIndicatorProps) => {
	const onClear = () => {
		const options = props?.options as IOption[];
		props?.clearValue();
		props?.setValue?.(
			options?.filter(e => e?.isDisabled),
			'deselect-option',
		);
	};

	return <Icon as={MdClose} size={25} onClick={onClear} />;
};
