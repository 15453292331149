/**
 *
 * InputText
 *
 */

import { FormControl, FormErrorMessage, FormLabel, Input, InputProps, SystemStyleObject, Text } from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';
interface InputTextProps extends InputProps {
	name: string;
	register?: UseFormRegister<any>;
	label?: string;
	labelVariant?: string;
	errorMessage?: string;
	errorMessageVariant?: string;
	required?: boolean;
	disabled?: boolean;
	customLabelStyle?: SystemStyleObject;
}

const InputText = ({
	name,
	label,
	errorMessage,
	required,
	register,
	disabled,
	labelVariant,
	errorMessageVariant,
	customLabelStyle,
	...rest
}: InputTextProps) => {
	return (
		<>
			<FormControl isInvalid={!!errorMessage}>
				{label && (
					<FormLabel variant={labelVariant} sx={customLabelStyle}>
						{label}{' '}
						{required && (
							<Text as="span" variant="required">
								*
							</Text>
						)}
					</FormLabel>
				)}
				<Input {...register?.(name)} disabled={disabled} {...rest} />
				{errorMessage && (
					<FormErrorMessage data-testid="text--error" variant={errorMessageVariant}>
						{errorMessage}
					</FormErrorMessage>
				)}
			</FormControl>
		</>
	);
};

export default InputText;
