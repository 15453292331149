/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * ListSalesChannelsFlow
 *
 */
import { viewSaleChannelFlowPDF } from '../ViewSalesChannelFlow';
import { useMemo, useState } from 'react';
import {
	Box,
	Flex,
	Grid,
	GridItem,
	Heading,
	HStack,
	IconButton,
	Spinner,
	SystemStyleObject,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import CustomModal from 'app/components/CustomModal';
import CustomPagination from 'app/components/Pagination';
import Select, { IOption } from 'app/components/Select';
import Table, { IColumn } from 'app/components/Table';
import Wrapper from 'app/components/Wrapper';
import { TableDeleteIcon, TableEditIcon, TableViewIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useDebounce } from 'hooks/useDebounce';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getAllCompanies, IResponseCompanies } from 'services/http/company';
import { deleteFlow, getFlow, getFlowById, IResponseGetFlow, IResponseGetFlowById } from 'services/http/flow';
import { IEmployeesByCompanyParams, getListEmployeesByCompany } from 'services/http/user';
import { Flow } from 'types/flow';
import { User } from 'types/user';
import { DATE_HOUR_FORMAT } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { handleAccessProfileParam, handleDisableSelect } from 'utils/handlePermissions';
import { useListAccessProfiles } from 'utils/listAccessProfiles';
import { parseErrors, ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { parseUrlFilters } from 'utils/parseUrlFilters';

interface IForm {
	page?: number;
	page_size?: number;
	company?: IOption | string;
	user_seller?: IOption | string;
}

const ListSalesChannelsFlow = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
		},
		header: {
			w: '100%',
			alignItems: 'baseline',
			justifyContent: 'space-between',
			gap: '1.5rem',
			flexDirection: {
				base: 'column',
				md: 'row',
			},
		},
		heading: {
			fontSize: '2.5rem',
			lineHeight: '3.25rem',
		},
		filter: {
			m: {
				base: '1rem 0',
				md: '2.5rem 0',
			},
			background: 'gray.250',
			padding: '1.8125rem 3.125rem',
		},
		filterText: {
			color: 'blue.500',
			fontWeight: 600,
			fontSize: '1.375rem',
		},
		filterFields: {
			display: 'flex',
			flexWrap: 'wrap',
			mt: '1.25rem',
			gap: '3.125rem',
			flexDirection: {
				base: 'column',
				xl: 'row',
			},
		},
		select: {
			w: '100%',
			flex: 1,
			maxW: {
				base: '100%',
				xl: '23.5rem',
			},
		},
		button: {
			borderRadius: 0,
			width: '2.1875rem',
			height: '2.1875rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			_hover: {
				filter: 'brightness(0.8)',
			},
		},
		spinner: {
			boxSize: 4,
			color: 'white',
		},
		pagination: {
			w: '100%',
			justifyContent: 'end',
			mt: '2.5rem',
		},
		spinnerBox: {
			justifyContent: 'center',
		},
		grid: {
			gridTemplateColumns: '1fr 1fr 1fr',
			gap: '0.65rem',
		},
	};
	const navigate = useNavigate();

	const {
		session: { user },
	} = useSession();

	const isSelectDisabled = handleDisableSelect(user);

	const columns: IColumn[] = [
		{
			label: 'Mês',
			key: 'monthYear',
			columnCustomStyle: {
				textTransform: 'capitalize',
			},
		},
		{
			label: 'Vendedor',
			key: 'user',
		},
		{
			label: 'Unidade',
			key: 'company',
		},
		{
			label: 'Data de criação',
			key: 'createdAt',
		},
		{
			label: 'Última alteração',
			key: 'updatedAt',
		},
		{
			label: 'Assinatura',
			key: 'signedBySeller',
		},
		{
			label: 'Ações',
			key: 'actions',
			sticky: 'right',
		},
	];

	const defaultValues = {
		page: 1,
		page_size: 10,
	};

	const listAccessProfiles = useListAccessProfiles();
	const { addToast } = useCustomToast();
	const [seletedFlowDelete, setSeletedFlowDelete] = useState<Flow>({} as Flow);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { control, setValue, handleSubmit, getValues, watch } = useForm<IForm>({
		defaultValues,
	});

	const formValues = watch();

	const access_profile = handleAccessProfileParam(user);

	const { data: companies, isLoading: companiesIsLoading } = useQuery<
		IResponseCompanies,
		AxiosError<ResponseErrors>,
		IResponseCompanies
	>(['companies'], () => getAllCompanies(), {
		onSuccess: data => {
			const defaultCompany = data?.companiesObjects?.find(item => item.company === user.company);

			setValue('company', { label: defaultCompany?.company || '', value: defaultCompany?.id });

			defaultCompany?.id &&
				getEmployeesMutate({
					company: String(defaultCompany?.company || ''),
					access_profile,
				});
		},
		onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
	});

	const parsedCompanies: IOption[] = useMemo(() => {
		return (
			companies?.companiesObjects?.map(item => {
				return {
					value: item.id!,
					label: item.company,
				};
			}) || []
		);
	}, [companies?.companiesObjects]);

	const {
		mutate: getEmployeesMutate,
		isLoading: isLoadingEmployees,
		data: employees,
	} = useMutation<User[], AxiosError<ResponseErrors>, IEmployeesByCompanyParams>(
		data => getListEmployeesByCompany(data),
		{
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
		},
	);

	const {
		data: listFlows,
		refetch: refetchListFlows,
		isLoading: isLoadingListFlows,
		isRefetching: isRefetchingListFlows,
	} = useQuery<IResponseGetFlow, AxiosError<ResponseErrors>, IResponseGetFlow>(
		['listFlow', formValues],
		() =>
			getFlow({
				...getValues(),
				company: getValues('company.value') || '',
				user_seller: getValues('user_seller.label'),
			}),
		{
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
		},
	);

	const {
		mutate: getFlowMutation,
		isLoading: isLoadingFlow,
		variables: viewFlowId,
	} = useMutation<IResponseGetFlowById, AxiosError<ResponseErrors>, string>(flowId => getFlowById(flowId), {
		onSuccess: data => {
			viewSaleChannelFlowPDF(data);
		},
		onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
	});

	const { mutate: deleteFlowMutate } = useMutation<void, AxiosError<ResponseErrors>, string>(
		flowId => deleteFlow(flowId),
		{
			onSuccess: () => {
				onClose();
				setSeletedFlowDelete({} as Flow);
				refetchListFlows();
			},
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
		},
	);

	const listEmployeesFilter = useMemo(
		() =>
			listFlows?.results?.map(flow => {
				const [createdAtDate, createdAtTime] = flow.createdAt.split('T');
				const [updatedAtDate, updatedAtTime] = flow.updatedAt.split('T');
				return {
					...flow,
					monthYear: formatDate(flow.monthYear, undefined, 'MMMM'),
					createdAt: formatDate(createdAtDate, createdAtTime, DATE_HOUR_FORMAT),
					updatedAt: formatDate(updatedAtDate, updatedAtTime, DATE_HOUR_FORMAT),
					signedBySeller: flow.signedBySeller ? (
						<Text color="green.500">Assinado</Text>
					) : (
						<Text color="yellow.500">Assinatura pendente</Text>
					),
					actions: (
						<Grid sx={styles?.grid}>
							<GridItem>
								<IconButton
									data-testid={`button--view--${flow?.id}`}
									aria-label={`Visualizar fluxo ${flow.id}`}
									icon={<TableViewIcon />}
									background="blue.500"
									sx={styles?.button}
									onClick={() => getFlowMutation(String(flow.id))}
									isLoading={viewFlowId === String(flow.id) && isLoadingFlow}
									spinner={<Spinner sx={styles?.spinner} />}
								/>
							</GridItem>
							{!flow.signedBySeller && (
								<>
									<GridItem>
										<IconButton
											data-testid={`button--edit--${flow?.id}`}
											aria-label={`Editar fluxo ${flow.id}`}
											icon={<TableEditIcon />}
											background="yellow.500"
											sx={styles?.button}
											onClick={() => navigate(parseUrlFilters(ROUTES.editFlow, { flowId: String(flow.id) }))}
										/>
									</GridItem>
									<GridItem>
										<IconButton
											data-testid={`button--delete--${flow?.id}`}
											aria-label={`Excluir fluxo ${flow.id}`}
											icon={<TableDeleteIcon />}
											background="red.500"
											sx={styles?.button}
											onClick={() => {
												onOpen();
												setSeletedFlowDelete(flow);
											}}
										/>
									</GridItem>
								</>
							)}
						</Grid>
					),
				};
			}) || [],
		[listAccessProfiles, listFlows?.results, getFlowMutation],
	);

	const onFilter = (page: number) => {
		setValue('page', page);
		handleSubmit(onSubmit)();
	};

	const debounced = useDebounce(() => onFilter(defaultValues.page));

	const getEmployeesByCompany = (selectedValue: IOption) => {
		const value = selectedValue;
		setValue('user_seller', '');
		value?.label &&
			getEmployeesMutate({
				company: value?.label,
				access_profile,
			});
		debounced();
	};

	const onSubmit = () => refetchListFlows();

	return (
		<Wrapper contentCustomStyles={styles?.container}>
			<HStack sx={styles?.header}>
				<Heading sx={styles.heading}>Histórico de Objetivos Cadastrados</Heading>
			</HStack>

			{companiesIsLoading ? (
				<Flex sx={styles.spinnerBox}>
					<Spinner />
				</Flex>
			) : (
				<>
					<Box sx={styles?.filter} as="form" onSubmit={handleSubmit(onSubmit)}>
						<Text sx={styles.filterText}>Filtro</Text>

						<Box sx={styles?.filterFields}>
							<Box sx={styles?.select}>
								<Controller
									control={control}
									name="company"
									render={({ field }) => (
										<Select
											isClearable
											label="Unidade"
											placeholder="Selecione a unidade"
											dataTestId="input--unity"
											options={parsedCompanies}
											value={field?.value}
											onChange={value => {
												field?.onChange(value);
												getEmployeesByCompany(value as IOption);
											}}
											isDisabled={isSelectDisabled}
										/>
									)}
								/>
							</Box>

							<Box sx={styles?.select}>
								<Controller
									control={control}
									name="user_seller"
									render={({ field }) => (
										<Select
											isClearable
											isLoading={isLoadingEmployees}
											isDisabled={!getValues('company')}
											label="Vendedor"
											placeholder="Selecione o vendedor"
											dataTestId="input--seller"
											options={parsedOptionArray(employees || [], 'username', 'username')}
											value={field?.value}
											onChange={value => {
												field?.onChange(value);
												debounced();
											}}
										/>
									)}
								/>
							</Box>
						</Box>
					</Box>

					{isLoadingListFlows || isRefetchingListFlows ? (
						<Flex sx={styles.spinnerBox}>
							<Spinner />
						</Flex>
					) : (
						<Table striped columns={columns} data={listEmployeesFilter} />
					)}
				</>
			)}

			<Flex sx={styles.pagination}>
				<CustomPagination
					currentPage={Number(getValues('page'))}
					totalItems={listFlows?.totalRecords}
					pageSize={defaultValues.page_size}
					onPaginate={onFilter}
				/>
			</Flex>

			<CustomModal
				title=""
				body="Ao excluir este item ele não aparecerá mais na tabela. Deseja mesmo excluir?"
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						datatestid: 'button--cancel',
						label: 'Não',
						type: 'outlined',
						onClick: onClose,
					},
					{
						datatestid: 'button--confirm',
						label: 'Sim',
						type: 'solid',
						onClick: () => deleteFlowMutate(String(seletedFlowDelete?.id)),
					},
				]}
			/>
		</Wrapper>
	);
};

export default ListSalesChannelsFlow;
