import { FLOW_ERROR_TYPES } from './constants';

export interface ResponseErrors {
	errors?: {
		[key: string]: string[];
	};
	comissionScale?: string[];
	detail?: string;
}

export const parseErrors = (response?: ResponseErrors) => {
	if (!response?.errors && !response?.comissionScale && !response?.detail)
		return 'Ocorreu um problema inesperado, tente novamente.';

	let message = '';

	if (response.errors) {
		Object.values(response?.errors)?.forEach(error => {
			message += `${error?.join('; ')} <br>`;
		});
	}

	if (response.comissionScale) {
		Object.values(response?.comissionScale)?.forEach(error => {
			message = message?.concat(`${error}; `);
		});
	}

	if (response.detail) message = response.detail;

	return message;
};

export const parseErrorsFlow = (response?: any) => {
	if (!response?.errors && !response?.detail) return 'Ocorreu um problema inesperado, tente novamente.';

	let message = '';

	if (response?.errors) {
		if (response?.errors?.appointmentRecord) {
			Object.values(response?.errors as any)?.forEach(error => {
				const value = error as unknown as string[];
				message = message?.concat(value?.join('; '));
			});
		} else {
			Object.values(response?.errors).forEach(value => {
				Object.entries(value as any)?.forEach(([key, value]) => {
					const formatted = value as unknown as string[];
					message += `${FLOW_ERROR_TYPES[key as keyof typeof FLOW_ERROR_TYPES]} ${formatted?.join('; ')} <br>`;
				});
			});
		}
	}

	if (response?.detail) message = response?.detail;

	return message;
};
