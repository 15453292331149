import { IOption } from 'app/components/Select';

export const orderOptionArray = <T>(array: T[] = [], orderBy?: keyof T) => {
	return array?.sort((firstOption, secondOption) => {
		const nameFirstOption = (firstOption[orderBy!] as String).toUpperCase(); // Convertendo para maiúsculas para garantir uma ordenação sem distinção entre maiúsculas e minúsculas
		const nameSecondOption = (secondOption[orderBy!] as String).toUpperCase();

		return nameFirstOption.localeCompare(nameSecondOption);
	}) as IOption[];
};
