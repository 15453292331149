import { lazy, Suspense } from 'react';
import EditCommission from 'app/pages/EditCommission';
import EditIndicator from 'app/pages/EditIndicators';
import EditSalesChannelFlow from 'app/pages/EditSalesChannelFlow';
import ListCommissions from 'app/pages/ListCommissions';
import ListIndicators from 'app/pages/ListIndicators';
import ListSalesChannelsFlow from 'app/pages/ListSalesChannelsFlow';
import RegisterCommissions from 'app/pages/RegisterCommissions';
import RegisterIndicators from 'app/pages/RegisterIndicators';
import RegisterSalesChannelFlow from 'app/pages/RegisterSalesChannelFlow';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useSession } from 'hooks/useSession';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthRouteProps, RoutesProps } from 'types/routes';
import { ACCESS_LEVELS } from 'utils/constants';

// No Auth
const DefaultLayout = lazy(() => import('app/components/DefaultLayout'));
const Login = lazy(() => import('app/pages/Login'));
const Register = lazy(() => import('app/pages/Register'));
const RecoverPassword = lazy(() => import('app/pages/RecoverPassword'));
const ResetPassword = lazy(() => import('app/pages/ResetPassword'));

// Auth
const Home = lazy(() => import('app/pages/Home'));
const ViewUser = lazy(() => import('app/pages/ViewUser'));
const ViewProfile = lazy(() => import('app/pages/ViewProfile'));
const UserRegister = lazy(() => import('app/pages/UserRegister'));
const UserEdit = lazy(() => import('app/pages/UserEdit'));
const AccessControl = lazy(() => import('app/pages/AccessControl'));
const EditProfile = lazy(() => import('app/pages/EditProfile'));

const AuthRoute = ({ children, noLayout = false, hasHeader, headerProps, accessLevels }: AuthRouteProps) => {
	const { isAuth } = useSession();
	const canView = useCanViewAction(accessLevels);

	if (!isAuth || !canView) return <Navigate to={ROUTES.login} replace />;

	return noLayout ? children : <DefaultLayout {...{ hasHeader, headerProps }}>{children}</DefaultLayout>;
};

const NoAuthRoute = ({ children, noLayout = false, hasHeader, headerProps }: AuthRouteProps) => {
	const { isAuth } = useSession();

	if (isAuth) return <Navigate to={ROUTES.home} replace />;

	return noLayout ? children : <DefaultLayout {...{ hasHeader, headerProps }}>{children}</DefaultLayout>;
};

const topAccessLevels = [
	ACCESS_LEVELS?.administrator?.value,
	ACCESS_LEVELS?.new_manager?.value,
	ACCESS_LEVELS.preowned_manager.value,
];

const noProtectRoutes: RoutesProps[] = [
	{
		path: ROUTES.login,
		element: <Login />,
		hasHeader: false,
	},
	{
		path: ROUTES.register,
		element: <Register />,
		hasHeader: false,
	},
	{
		path: ROUTES.recoverPassword,
		element: <RecoverPassword />,
		hasHeader: false,
	},
	{
		path: ROUTES.resetPassword,
		element: <ResetPassword />,
		hasHeader: false,
	},
];

const protectedRoutes: RoutesProps[] = [
	{
		path: ROUTES.home,
		element: <Home />,
	},
	{
		path: ROUTES.userRegister,
		element: <UserRegister />,
		accessLevels: topAccessLevels,
	},
	{
		path: ROUTES.editUser(':id'),
		element: <UserEdit />,
		accessLevels: topAccessLevels,
	},
	{
		path: ROUTES.accessControl,
		element: <AccessControl />,
		accessLevels: topAccessLevels,
	},
	{
		path: ROUTES.viewUser,
		element: <ViewUser />,
		accessLevels: topAccessLevels,
	},
	{
		path: ROUTES.editProfile,
		element: <EditProfile />,
	},
	{
		path: ROUTES.profile,
		element: <ViewProfile />,
	},
	{
		path: ROUTES.listSalesChannelsFlow,
		element: <ListSalesChannelsFlow />,
	},
	{
		path: ROUTES.registerFlow,
		element: <RegisterSalesChannelFlow />,
	},
	{
		path: ROUTES.editFlow,
		element: <EditSalesChannelFlow />,
	},
	{
		path: ROUTES.listCommissions,
		element: <ListCommissions />,
		accessLevels: topAccessLevels,
	},
	{
		path: ROUTES.registerCommission,
		element: <RegisterCommissions />,
		accessLevels: topAccessLevels,
	},
	{
		path: ROUTES.listIndicators,
		element: <ListIndicators />,
		accessLevels: topAccessLevels,
	},
	{
		path: ROUTES.registerIndicator,
		element: <RegisterIndicators />,
		accessLevels: topAccessLevels,
	},
	{
		path: ROUTES.editIndicator(':indicatorId'),
		element: <EditIndicator />,
		accessLevels: topAccessLevels,
	},
	{
		path: ROUTES.editCommission(':commissionId'),
		element: <EditCommission />,
		accessLevels: topAccessLevels,
	},
];

export const Router = () => {
	return (
		<BrowserRouter>
			<Suspense fallback={''}>
				<Routes>
					{noProtectRoutes?.map((route, index) => (
						<Route
							key={index}
							{...{ ...route }}
							element={
								<NoAuthRoute {...{ ...route }}>
									<>{route.element}</>
								</NoAuthRoute>
							}
						/>
					))}
					{protectedRoutes?.map((route, index) => (
						<Route
							key={index}
							{...{ ...route }}
							element={
								<AuthRoute {...{ ...route }}>
									<>{route.element}</>
								</AuthRoute>
							}
						/>
					))}
					<Route path="*" element={<Navigate to={ROUTES.home} />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};
