import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { Company } from 'types/company';
import { User } from 'types/user';

export interface IRequestListEmployeesByCompany {
	company: string;
}
export interface IResponseCompanies {
	companies: string[];
	companiesObjects?: Company[];
}

export interface IResponseListEmployeesByCompany {
	CustomUser: [User[]];
}

const api = getAxios();

export const getAllCompanies = async (): Promise<IResponseCompanies> => {
	const response = await api.get<IResponseCompanies>(ENDPOINTS.getCompanies);
	return response?.data;
};
