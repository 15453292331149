/**
 *
 * Table
 *
 */

import { Box, SystemStyleObject, Table as ChakraTable, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

interface IStyle {
	column: IColumn;
	index?: number;
	isHeader?: boolean;
	row?: SystemStyleObject;
}

export interface IColumn {
	label: string;
	key: string;
	order?: boolean;
	sticky?: 'left' | 'right';
	columnCustomStyle?: SystemStyleObject;
	alignHeaderText?: string;
}

export interface IRow {
	[key: string]: string | number | JSX.Element | undefined | SystemStyleObject;
}

interface TableProps {
	columns: IColumn[];
	data: IRow[];
	striped?: boolean;
	contentCustomStyle?: SystemStyleObject;
	headerCustomStyle?: SystemStyleObject;
	cellCustomStyle?: SystemStyleObject;
	cellStripedCustomStyle?: SystemStyleObject;
	variant?: string;
}

const Table = ({
	variant = 'default',
	columns,
	data,
	striped,
	contentCustomStyle,
	headerCustomStyle,
	cellCustomStyle,
	cellStripedCustomStyle,
}: TableProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			overflowX: 'auto',
			pb: '0.25rem',
		},
		content: {
			minW: '62.5rem',
			...contentCustomStyle,
		},
		header: {
			background: 'blue.800',
			...headerCustomStyle,
		},
		cell: {
			color: 'black',
			fontWeight: 500,
			background: 'gray.85',
			...cellCustomStyle,
		},
		cellStriped: {
			color: 'black',
			background: 'gray.250',
			...cellStripedCustomStyle,
		},
		left: {
			position: 'sticky',
			left: 0,
		},
		right: {
			position: 'sticky',
			right: 0,
		},
	};

	const itsPair = (number: number) => number % 2 === 0; // Verifica se o número é par para atribuição do estilo striped quando necessário

	const getStyle = ({ column, index = 0, isHeader = false, row }: IStyle) => {
		// Monta estilização das células da tabela
		let style = { ...styles?.cell, ...column?.columnCustomStyle };

		if (striped) {
			const cellStyle = itsPair(index) ? styles?.cellStriped : styles?.cell;
			style = { ...style, ...cellStyle };
		}

		if (column?.sticky) style = { ...style, ...styles[column?.sticky] };

		const defaultTextAlignPosition = 'left';

		if (isHeader)
			style = { ...style, ...styles?.header, textAlign: column?.alignHeaderText || defaultTextAlignPosition };

		if (row) style = { ...style, ...row };

		return style;
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.content}>
				<ChakraTable variant={variant}>
					<Thead>
						<Tr sx={styles.header}>
							{columns?.map(column => (
								<Th
									sx={getStyle({
										column,
										isHeader: true,
									})}
									key={column?.key}
								>
									{column?.label}
								</Th>
							))}
						</Tr>
					</Thead>
					<Tbody>
						{data?.map((data, index) => (
							<Tr key={index}>
								{columns?.map(column => (
									<Td
										sx={getStyle({
											column,
											index,
											row: data?.row as SystemStyleObject,
										})}
										key={`${index}-${column?.key}`}
									>
										{data[column?.key] as string}
									</Td>
								))}
							</Tr>
						))}
					</Tbody>
				</ChakraTable>
			</Box>
		</Box>
	);
};

export default Table;
