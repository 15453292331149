/**
 *
 * InputText
 *
 */

import { FormControl, FormErrorMessage, FormLabel, Textarea, TextareaProps, Text } from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';
interface InputTextProps extends TextareaProps {
	name: string;
	register?: UseFormRegister<any>;
	label?: string;
	errorMessage?: string;
	required?: boolean;
	disabled?: boolean;
	errorMessageVariant?: string;
}

const InputTextarea = ({
	name,
	label,
	errorMessage,
	required,
	register,
	disabled,
	errorMessageVariant,
	...rest
}: InputTextProps) => {
	return (
		<>
			<FormControl isInvalid={!!errorMessage}>
				{label && (
					<FormLabel>
						{label}{' '}
						{required && (
							<Text as="span" variant="required">
								*
							</Text>
						)}
					</FormLabel>
				)}
				<Textarea {...register?.(name)} disabled={disabled} {...rest} />
				{errorMessage && (
					<FormErrorMessage data-testid="text--error" variant={errorMessageVariant}>
						{errorMessage}
					</FormErrorMessage>
				)}
			</FormControl>
		</>
	);
};

export default InputTextarea;
