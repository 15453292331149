import { useMemo, useState } from 'react';
import {
	Box,
	Flex,
	Heading,
	HStack,
	IconButton,
	Spinner,
	SystemStyleObject,
	useDisclosure,
	Button,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import CustomModal from 'app/components/CustomModal';
import CustomPagination from 'app/components/Pagination';
import Select, { IOption } from 'app/components/Select';
import Table, { IColumn } from 'app/components/Table';
import { TableDeleteIcon, TableEditIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useDebounce } from 'hooks/useDebounce';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { MdArrowDropDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { deleteCommission, getCommissions } from 'services/http/commission';
import { getAllCompanies, IResponseCompanies } from 'services/http/company';
import { Commission, IPaginatedCommissions } from 'types/commission';
import { DEPARTMENTS_OPTIONS, ORDER_BY_COMPANY } from 'utils/constants';
import { handleDisableSelect } from 'utils/handlePermissions';
import { orderOptionArray } from 'utils/orderOptionsArray';
import { parseErrors, ResponseErrors } from 'utils/parseErrors';

interface IForm {
	company?: IOption;
	department?: IOption;
	page?: number;
	page_size?: number;
}

const ListCommissions = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			padding: { lg: '4.5rem', base: '1rem' },
			boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
			backgroundColor: 'white',
			borderRadius: '8px',
		},
		header: {
			w: '100%',
			alignItems: 'baseline',
			justifyContent: 'space-between',
			gap: '1.5rem',
			flexDirection: {
				base: 'column',
				md: 'row',
			},
		},
		addButton: {
			h: '4rem',
			fontSize: { base: '1rem', lg: '1.375rem' },
			fontWeight: 400,
			px: '2rem',
			letterSpacing: '1px',
			w: { base: '100%', md: 'fit-content' },
		},
		heading: {
			fontSize: '1.87rem',
			lineHeight: '3.25rem',
			color: '#000',
			fontWeight: 'semibold',
		},
		filter: {
			m: {
				base: '1rem 0',
				md: '2.5rem 0',
			},
		},
		filterText: {
			color: 'blue.500',
			fontWeight: 600,
			fontSize: '1.375rem',
		},
		filterFields: {
			display: 'flex',
			flexWrap: 'wrap',
			mt: '1.25rem',
			gap: '3.125rem',
			flexDirection: {
				base: 'column',
				xl: 'row',
			},
			alignItems: 'flex-end',
		},
		select: {
			w: '100%',
			flex: 1,
			maxW: {
				base: '100%',
				xl: '23.5rem',
			},
		},
		selectLeft: {
			w: '100%',
			flex: 1,
			maxW: {
				base: '100%',
				xl: '15.25rem',
			},
		},
		button: {
			borderRadius: 0,
			width: '2.1875rem',
			height: '2.1875rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			_hover: {
				filter: 'brightness(0.8)',
			},
		},
		spinner: {
			boxSize: 4,
			color: 'white',
		},
		pagination: {
			w: '100%',
			justifyContent: 'end',
			mt: '2.5rem',
		},
		spinnerBox: {
			justifyContent: 'center',
		},
		grid: {
			justifyContent: 'center',
			gap: '0.65rem',
		},
		strippedCell: {
			backgroundColor: '#F2F2F2',
		},
		cell: {
			backgroundColor: '#ffffff',
		},
	};

	const selectStyle = {
		border: '1px solid #cccccc',
		height: '60px',
		borderRadius: '5px',
	};

	const navigate = useNavigate();

	const columns: IColumn[] = [
		{
			label: 'Unidade',
			key: 'company',
		},
		{
			label: 'Departamento',
			key: 'department',
		},
		{
			label: 'Escala 1',
			key: 'scale1',
		},
		{
			label: 'Escala 2',
			key: 'scale2',
		},
		{
			label: 'Escala 3',
			key: 'scale3',
		},
		{
			label: 'Escala 4',
			key: 'scale4',
		},
		{
			label: 'Escala 5',
			key: 'scale5',
		},
		{
			label: 'Ações',
			key: 'actions',
			sticky: 'right',
			alignHeaderText: 'center',
		},
	];

	const { addToast } = useCustomToast();
	const [seletedCommissionDelete, setSeletedCommissionDelete] = useState<Commission>({} as Commission);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const allOptions = useMemo(() => ({ value: undefined, label: 'Todos' }), []);

	const {
		session: { user },
	} = useSession();

	const isSelectDisabled = handleDisableSelect(user);

	const defaultValues = {
		page: 1,
		page_size: 10,
		company: allOptions,
		department: allOptions,
	};

	const { control, setValue, handleSubmit, getValues, watch } = useForm<IForm>({
		defaultValues,
	});

	const formValues = watch();

	const { data: companies, isLoading: companiesIsLoading } = useQuery<
		IResponseCompanies,
		AxiosError<ResponseErrors>,
		IResponseCompanies
	>(['companies'], () => getAllCompanies(), {
		onSuccess: data => {
			const defaultCompany = data?.companiesObjects?.find(item => item.company === user.company);

			setValue('company', { label: defaultCompany?.company || '', value: defaultCompany?.id });

			const department = DEPARTMENTS_OPTIONS.find(item => item.value === user.department);

			setValue('department', department);
		},
		onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
	});

	const parsedCompanies: IOption[] = useMemo(() => {
		const companiesOptions =
			companies?.companiesObjects?.map(item => ({
				value: item.id!,
				label: item.company,
			})) || [];

		const companiesOptionsOrded = orderOptionArray(companiesOptions, 'label');

		return [allOptions, ...companiesOptionsOrded];
	}, [allOptions, companies?.companiesObjects]);

	const {
		data: listCommissions,
		refetch: refetchListCommissions,
		isLoading: isLoadingListCommissions,
		isRefetching: isRefetchingListCommissions,
	} = useQuery<IPaginatedCommissions, AxiosError<ResponseErrors>, IPaginatedCommissions>(
		['getCommissions', formValues],
		() =>
			getCommissions({
				...getValues(),
				company: String(formValues?.company?.value || ''),
				department: String(formValues?.department?.value || ''),
				ordering: ORDER_BY_COMPANY,
			}),
		{
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
			enabled: !!formValues?.company?.value && !!formValues?.department?.value,
		},
	);

	const { mutate: deleteCommissionMutate } = useMutation<void, AxiosError<ResponseErrors>, string>(
		CommissionId => deleteCommission(CommissionId),
		{
			onSuccess: () => {
				onClose();
				setSeletedCommissionDelete({} as Commission);
				refetchListCommissions();
			},
			onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data) }),
		},
	);

	const listCommissionsFilter = useMemo(
		() =>
			listCommissions?.results?.map(commision => {
				return {
					company: commision.company.company,
					department: DEPARTMENTS_OPTIONS.find(item => item.value === commision.department)?.label,
					scale1: commision.scale1,
					scale2: commision.scale2,
					scale3: commision.scale3,
					scale4: commision.scale4,
					scale5: commision.scale5,
					actions: (
						<Flex sx={styles?.grid}>
							<Box>
								<IconButton
									data-testid={`button--edit--${commision.id}`}
									aria-label={`Editar fluxo ${commision.id}`}
									icon={<TableEditIcon />}
									background="yellow.500"
									sx={styles?.button}
									onClick={() => navigate(`${ROUTES.editCommission(commision.id)}`)}
								/>
							</Box>
							<Box>
								<IconButton
									data-testid={`button--delete--${commision?.id}`}
									aria-label={`Excluir fluxo ${commision.id}`}
									icon={<TableDeleteIcon />}
									background="red.600"
									sx={styles?.button}
									onClick={() => {
										onOpen();
										setSeletedCommissionDelete(commision);
									}}
								/>
							</Box>
						</Flex>
					),
				};
			}) || [],
		[listCommissions?.results, navigate, onOpen, styles?.button, styles?.grid],
	);

	const onFilter = (page: number) => {
		setValue('page', page);
		handleSubmit(onSubmit)();
	};

	const debounced = useDebounce(() => onFilter(defaultValues.page));

	const onSubmit = () => refetchListCommissions();

	return (
		<Box p={{ lg: '3.75rem', base: '1rem' }}>
			<Box sx={styles.container}>
				<HStack sx={styles?.header}>
					<Heading sx={styles.heading}>Comissão de veículos para lojas</Heading>
				</HStack>

				{companiesIsLoading ? (
					<Flex sx={styles.spinnerBox}>
						<Spinner />
					</Flex>
				) : (
					<>
						<Box sx={styles?.filter} as="form" onSubmit={handleSubmit(onSubmit)}>
							<Box sx={styles?.filterFields}>
								<Box sx={styles?.select}>
									<Controller
										control={control}
										name="company"
										render={({ field }) => (
											<Select
												dropdownIndicatorComponent={MdArrowDropDown}
												styles={{
													control: styles => {
														return {
															...styles,
															...selectStyle,
														};
													},
												}}
												label="Unidade"
												dataTestId="input--unity"
												options={parsedCompanies}
												value={field?.value}
												onChange={value => {
													field?.onChange(value);
													debounced();
												}}
												isDisabled={isSelectDisabled}
											/>
										)}
									/>
								</Box>

								<Box sx={styles?.selectLeft}>
									<Controller
										control={control}
										name="department"
										render={({ field }) => (
											<Select
												styles={{
													control: styles => {
														return {
															...styles,
															...selectStyle,
														};
													},
												}}
												dropdownIndicatorComponent={MdArrowDropDown}
												label="Departamento"
												options={[allOptions, ...DEPARTMENTS_OPTIONS]}
												dataTestId="input--seller"
												value={field?.value}
												onChange={value => {
													field?.onChange(value);
													debounced();
												}}
												isDisabled={isSelectDisabled}
											/>
										)}
									/>
								</Box>
								<Box ml="auto" w={['100%', 'fit-content']}>
									<Button
										sx={styles.addButton}
										data-testid="button--cancel"
										onClick={() => navigate(ROUTES.registerCommission)}
									>
										Cadastrar comissão de loja
									</Button>
								</Box>
							</Box>
						</Box>

						{isLoadingListCommissions || isRefetchingListCommissions ? (
							<Flex sx={styles.spinnerBox}>
								<Spinner />
							</Flex>
						) : (
							<Table
								striped
								columns={columns}
								data={listCommissionsFilter}
								cellStripedCustomStyle={styles.strippedCell}
								cellCustomStyle={styles.cell}
								headerCustomStyle={{ backgroundColor: '#173569' }}
							/>
						)}
					</>
				)}

				<Flex sx={styles.pagination}>
					<CustomPagination
						currentPage={Number(getValues('page'))}
						totalItems={listCommissions?.totalRecords}
						pageSize={defaultValues.page_size}
						onPaginate={onFilter}
					/>
				</Flex>

				<CustomModal
					title=""
					body="Ao excluir este item ele não aparecerá mais na tabela. Deseja mesmo excluir?"
					isOpen={isOpen}
					onClose={onClose}
					actions={[
						{
							datatestid: 'button--cancel',
							label: 'Não',
							type: 'outlined',
							onClick: onClose,
						},
						{
							datatestid: 'button--confirm',
							label: 'Sim',
							type: 'solid',
							onClick: () => deleteCommissionMutate(String(seletedCommissionDelete?.id)),
						},
					]}
				/>
			</Box>
		</Box>
	);
};

export default ListCommissions;
