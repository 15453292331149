import { ENDPOINTS } from '../endpoints';
import { IOption } from 'app/components/Select';
import { getAxios } from 'hooks/useAxios';
import { User } from 'types/user';

export interface INotification {
	id: string;
	createdAt: string;
	flagReaded?: boolean;
	text: string;
	user: string;
	notificationTemplate: string;
}

export interface IResponseListEmployees {
	totalRecords?: number;
	recordsInThisPage?: number;
	totalPages?: number;
	currentPage?: number;
	results?: User[];
}

export interface IRequestListEmployees {
	username?: string;
	company?: string | IOption | undefined;
	email?: string;
	is_active?: string;
	page?: number;
	page_size?: number;
}

export interface IRequestRegisterUser {
	username: string;
	cpf: string;
	email: string;
	phoneNumber: string;
	company?: string;
	role: string;
	password?: string;
	confirmPassword?: string;
}
export interface IRequestPreRegisterUser {
	username: string;
	cpf: string;
	email: string;
	department: string;
	phoneNumber: string;
	company: string;
	role: string;
	accessProfile: string[];
	isActive: boolean;
}

export interface IRequestProfileUser extends IRequestRegisterUser {
	id?: string;
	profileImage?: string;
	oldPassword?: string;
}

export interface IEmployeesByCompanyParams {
	company: string;
	access_profile?: string;
	is_active?: string;
}

export const editProfileUser = async (data: IRequestProfileUser): Promise<User> => {
	const api = getAxios();
	const response = await api.put(ENDPOINTS.editProfile, data);
	return response?.data;
};

export const preRegisterUser = async (data: IRequestPreRegisterUser): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.registerUser, data);
	return response?.data;
};

export const registerUser = async (data: IRequestRegisterUser, token: string): Promise<IRequestRegisterUser> => {
	const api = getAxios();
	const response = await api?.post<IRequestRegisterUser>(ENDPOINTS.finalizeRegister, data, {
		params: { token },
	});
	return response?.data;
};

export const getUserByToken = async (token: string): Promise<User> => {
	const api = getAxios();
	const response = await api?.get<User>(ENDPOINTS.getUserByToken, { params: { token } });
	return response?.data;
};

export const getListEmployees = async (params: IRequestListEmployees): Promise<IResponseListEmployees> => {
	const api = getAxios();
	const response = await api?.get<IResponseListEmployees>(ENDPOINTS.listEmployees, { params });
	return response?.data;
};

export const getViewProfile = async (userId: string): Promise<User> => {
	const api = getAxios();
	const response = await api?.get<User>(`${ENDPOINTS.getViewProfile}/${userId}/view`, { params: { userId } });
	return response?.data;
};

export const getNotifications = async (): Promise<INotification[]> => {
	const api = getAxios();

	const response = await api.get(ENDPOINTS.getNotification);

	return response?.data;
};

export const markReadAll = async () => {
	const api = getAxios();

	const response = await api.get(ENDPOINTS.readAllNotifications);

	return response?.data;
};

export const editUser = async (data: User, userId: string) => {
	const api = getAxios();
	const response = await api.put<User>(ENDPOINTS.editUser(userId), data);
	return response?.data;
};

export const getListEmployeesByCompany = async (params: IEmployeesByCompanyParams): Promise<User[]> => {
	const api = getAxios();

	const response = await api?.get<User[]>(ENDPOINTS.listEmployeesByCompany, {
		params: {
			is_active: 'true',
			...params,
		},
	});
	return response?.data;
};

export const deleteEmployee = async (id: string): Promise<void> => {
	const api = getAxios();
	await api?.delete(ENDPOINTS.deleteEmployee(id));
};
