/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * DispatcherForm
 *
 */

import { AttentionPointForm, attentionsPointsButton } from '../AttentionPoints';
import InputNumeric from '../InputNumeric';
import { useEffect } from 'react';
import { Flex, Grid, GridItem, SystemStyleObject, Text } from '@chakra-ui/react';
import { IFlow } from 'app/pages/Home';
import { useDebounce } from 'hooks/useDebounce';
import { Control, Controller, FieldErrors, UseFormReset, UseFormWatch } from 'react-hook-form';
import { IStepProps } from 'types/stepper';
import { unMaskThousands } from 'utils/Numbers';
import * as yup from 'yup';

export const schemaDispatcherForm = {
	penetrationAmount: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	averageCarRevenue: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	dispatcherRevenue: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	unitSales: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	accessoriesSaleAccessories: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	assessmentRate: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	reviewsAmount: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	fundingRate: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	totalCatch: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	usedPickupCaptureAward: yup
		.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.required('Este campo é obrigatório.'),
	attentionPoints: yup.array().of(
		yup.object().shape({
			point: yup.string().max(60, 'Limite de 60 caracteres.').nullable(),
		}),
	),
};

export interface IDispatcherForm extends AttentionPointForm {
	penetrationAmount?: number; //QUANTIDADE PENETRATION
	averageCarRevenue?: number; //RECEITA MEDIA CARRO
	dispatcherRevenue?: number; //RECEITA DESPACHANTE
	unitSales?: number; //VENDAS POR UNIDADE
	accessoriesSaleAccessories?: number; //VENDA ACESSORIOS
	assessmentRate?: number; //TAXA DE AVALIACAO
	reviewsAmount?: number; //QUANTIDADE AVALIACOES
	fundingRate?: number; //TAXA CAPTACAO
	totalCatch?: number; //TOTAL CAPTURA
	usedPickupCaptureAward?: number; //PREMIACAO CAPTURA
}

interface DispatcherFormProps extends IStepProps<IDispatcherForm, IFlow> {
	control: Control<IDispatcherForm>;
	watch: UseFormWatch<IDispatcherForm>;
	reset: UseFormReset<IDispatcherForm>;
	errors: FieldErrors<IDispatcherForm>;
	readOnly?: boolean;
	onOpenAttentionPointsModal: () => void;
}

const DispatcherForm = ({
	defaultValues,
	readOnly,
	watching,
	control,
	watch,
	reset,
	errors,
	onOpenAttentionPointsModal,
}: DispatcherFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDirection: 'column',
			maxW: {
				base: '100%',
				'2xl': '44rem',
			},
			minW: {
				base: '100%',
				'2xl': '41rem',
			},
		},
		tooltipContainer: {
			w: '100%',
			alignItems: 'center',
			gap: '0.5rem',
			justifyContent: 'flex-end',
		},
		content: {
			w: '100%',
			flexDirection: 'column',
			pr: '2.5rem',
			pb: '1.875rem',
			gap: '2.188rem',
		},
		section: {
			w: '100%',
			flexDirection: 'column',
			gap: '1.25rem',
		},
		title: {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			color: '#2E769B',
		},
		grid: {
			gap: '0.625rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				md: 'repeat(2, 1fr)',
				lg: 'repeat(3, 1fr)',
			},
		},
		buttonContainer: {
			w: '100%',
			justifyContent: 'end',
			mt: '0.313rem',
			gap: '1.875rem',
		},
		button: {
			fontSize: '1.375rem',
			px: '2rem',
			h: '4rem',
			w: {
				base: '100%',
				md: 'fit-content',
			},
		},
		customLabelStyle: {
			minHeight: '3.37rem',
			display: 'flex',
			alignItems: 'center',
			whiteSpace: 'pre-line',
		},
		addButton: {
			fontSize: '1.125rem',
			fontWeight: '600',
			color: 'blue.500',
			h: 'fit-content',
			display: 'flex',
			alignItems: 'center',
		},
	};

	const onChangeValues = () => {
		watching?.(watch());
	};

	const debounceCalc = useDebounce(onChangeValues, 800);

	useEffect(() => reset(defaultValues), [defaultValues]);

	return (
		<Flex sx={styles.container}>
			<Flex sx={styles.content}>
				<Flex sx={styles.section}>
					<Text sx={styles.title}>Despachante</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="penetrationAmount"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Penetration - qtde (%):"
										name="penetrationAmount"
										placeholder="Penetration"
										errorMessage={errors?.penetrationAmount?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled={readOnly}
										suffix="%"
										data-testid="input--penetration"
										decimalScale={0}
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="averageCarRevenue"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label={`Receita \n média/carro (R$):`}
										name="averageCarRevenue"
										placeholder="Receita média/carro"
										errorMessage={errors?.averageCarRevenue?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled
										data-testid="input--averageCarRevenue"
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="dispatcherRevenue"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label={`Receita \n despachante (R$):`}
										name="dispatcherRevenue"
										placeholder="Receita despachante"
										errorMessage={errors?.dispatcherRevenue?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled
										data-testid="input--dispatcherRevenue"
										variant="small"
										formLabelVariant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
					</Grid>
				</Flex>

				<Flex sx={styles.section}>
					<Text sx={styles.title}>Acessórios</Text>
					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="unitSales"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Vendas por unidade:"
										name="unitSales"
										formLabelVariant="small"
										placeholder="Vendas por unidade"
										errorMessage={errors?.unitSales?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled={readOnly}
										data-testid="input--unitSales"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
						<GridItem>
							<Controller
								name="accessoriesSaleAccessories"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Venda acessórios (R$):"
										name="accessoriesSaleAccessories"
										formLabelVariant="small"
										placeholder="Venda acessórios"
										errorMessage={errors?.accessoriesSaleAccessories?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled
										data-testid="input--accessoriesSaleAccessories"
										variant="small"
										errorMessageVariant="small"
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>

				<Flex sx={styles.section}>
					<Text sx={styles.title}>Captação usados</Text>

					<Grid sx={styles.grid}>
						<GridItem>
							<Controller
								name="assessmentRate"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Taxa de avaliação (%):"
										name="assessmentRate"
										formLabelVariant="small"
										placeholder="Taxa de avaliação"
										errorMessage={errors?.assessmentRate?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled={readOnly}
										suffix="%"
										data-testid="input--assessmentRate"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="reviewsAmount"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Qtde de avaliações:"
										name="reviewsAmount"
										formLabelVariant="small"
										placeholder="Qtde de avaliações"
										errorMessage={errors?.reviewsAmount?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled
										data-testid="input--reviewsAmount"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="fundingRate"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Taxa de captação (%):"
										name="fundingRate"
										formLabelVariant="small"
										placeholder="Taxa de captação"
										errorMessage={errors?.fundingRate?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled={readOnly}
										suffix="%"
										data-testid="input--fundingRate"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="totalCatch"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Total captura:"
										name="totalCatch"
										formLabelVariant="small"
										placeholder="Total captura"
										errorMessage={errors?.totalCatch?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled
										data-testid="input--totalCatch"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="usedPickupCaptureAward"
								control={control}
								render={({ field: { value, onChange } }) => (
									<InputNumeric
										label="Premiação por captura:"
										name="usedPickupCaptureAward"
										formLabelVariant="small"
										placeholder="Premiação por captura"
										errorMessage={errors?.usedPickupCaptureAward?.message}
										onChange={e => onChange(unMaskThousands(e?.target?.value))}
										value={value || ''}
										onKeyUp={debounceCalc}
										disabled
										data-testid="input--usedPickupCaptureAward"
										variant="small"
										errorMessageVariant="small"
										decimalScale={0}
										customLabelStyle={styles.customLabelStyle}
									/>
								)}
							/>
						</GridItem>
					</Grid>
					{attentionsPointsButton(onOpenAttentionPointsModal, styles)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default DispatcherForm;
