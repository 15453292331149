import { unmaskValue } from './mask';
import { maskThousands, unMaskThousands } from './Numbers';

export const formatValues = <T extends object>(data: T) => {
	const payload = {};

	Object.keys(data)?.map(key => {
		if (key === 'attentionPoints') {
			return Object.assign(payload, { [key]: data[key as keyof T] });
		}

		const value = unMaskThousands(maskThousands(data[key as keyof T] as number));

		return Object.assign(payload, {
			[key]: isNaN(value) ? undefined : value,
		});
	});

	return payload;
};

export const convertPercentage = (value: any) => {
	let number = +unmaskValue(value);
	number = number / 100;
	return number;
};

export const multiplyPercentageFields = (numberField?: number | string, percentageField?: number | string) => {
	return Number(numberField || 0) * (convertPercentage(percentageField) || 1);
};
