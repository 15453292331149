export const ENDPOINTS = {
	notifications: '/notifications',
	login: `users/login/`,
	getUserByToken: '/users/get-user-by-token/',
	finalizeRegister: '/users/finalize-register/',
	refreshToken: '/users/get-access-token',
	registerUser: '/users/pre-register/',
	getCompanies: '/users/get-companies/',
	recoverPassword: 'users/recover-password/',
	resetPassword: 'users/change-password/',
	listEmployeesByCompany: '/users/list-employees-company/',
	listEmployees: '/users/list-employees/',
	editProfile: '/users/edit-user/',
	getViewProfile: '/users',
	viewUser: (userId: string) => `/users/${userId}/view/`,
	editUser: (userId: string) => `/users/${userId}/edit-user-manager/`,
	deleteEmployee: (id: string) => `/users/${id}/delete-user/`,

	//FLOW
	getFlow: '/flow/',
	getIndicators: '/historic/indicators-list/',
	getHistoryIndicators: '/indicators/indicators/',
	getHistoryYears: '/historic/get-historic-years/',
	getSalaryCalculationValues: '/historic/get-salary-calculation-values/',
	getAttendenceSimulatorValues: '/historic/get-attendence-simulator-values/',
	createFlow: '/flow/',

	getFlowById: (id: string) => `/flow/${id}/`,
	editFlow: (id: string) => `/flow/${id}/`,
	deleteFlow: (id: string) => `/flow/${id}/`,
	signFlow: '/flow/assign/',

	//HISTORY
	getCompanyInformations: '/historic/get-company-informations/',
	getIndicatorsExpanded: '/indicators/indicators-expanded/',
	getIndicatorsAvarege: '/indicators/indicators-avarege/',
	getGoals: (companyId: string) => `/historic/get-real-salary-vs-expectative/${companyId}/`,
	getServicesChannels: (companyId: string) => `/syonet/track-sellers/${companyId}/`,

	//INDICATORS
	editIndicator: (indicatorId: string) => `/historic/indicators-types/${indicatorId}/`,
	getIndicatorById: (indicatorId: string) => `/historic/indicators-types/${indicatorId}/`,
	getIndicatorsTypes: '/historic/indicators-types',
	registerIndicatorsTypes: '/historic/indicators-types/',
	getIndicatorsNames: '/historic/indicators-names',

	//COMMISSION SCALES
	listCommissions: '/historic/indicator-commission-scales/',
	createCommissionScale: '/historic/indicator-commission-scales/',
	deleteComission: (commissionId: string) => `/historic/indicator-commission-scales/${commissionId}/`,
	editCommission: (commissionId: string) => `/historic/indicator-commission-scales/${commissionId}/`,
	getCommissionById: (commissionId: string) => `/historic/indicator-commission-scales/${commissionId}/`,

	//NOTIFICAÇÃO
	getNotification: 'users/get-notifications/',
	readAllNotifications: 'users/read-all-notifications/',
};
