/**
 *
 * ViewSalesChannelFlow
 *
 */
import {
	Box,
	Divider,
	Flex,
	GridItem,
	Heading,
	Image,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
} from '@chakra-ui/react';
import logo from 'assets/logo.png';
import { jsPDF } from 'jspdf';
import { renderToString } from 'react-dom/server';
import { IResponseGetFlowById } from 'services/http/flow';
import { MONTH_YEAR_FORMAT } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { formatNumbers } from 'utils/Numbers';

interface ViewSalesChannelFlowProps {
	flow: IResponseGetFlowById;
}

export const viewSaleChannelFlowPDF = (flow: IResponseGetFlowById) => {
	const string = renderToString(<ViewSalesChannelFlow flow={flow} />);
	const doc = new jsPDF('p', 'pt', 'a4');
	doc.setCharSpace(0.1);

	doc.html(string, {
		callback: function (pdf) {
			pdf.output('dataurlnewwindow', {
				filename: `Fluxo por Canal de Venda ${flow?.appointmentRecord?.id}`,
			});
		},
	});
};

export const ViewSalesChannelFlow = ({ flow }: ViewSalesChannelFlowProps) => {
	const widthPageA4 = 446;
	const heightOnePageA4 = 621;
	const styles: Record<string, React.CSSProperties> = {
		wrapper: {
			width: `${widthPageA4}pt`,
			height: `${heightOnePageA4}pt`,
			background: 'white',
			padding: '1rem 2.5rem',
			flexDirection: 'column',
		},
		container: {
			background: 'orange',
		},
		heading: {
			wordSpacing: '0.2rem',
			fontSize: '0.5rem',
			marginTop: '0.4rem',
			color: 'black',
			fontWeight: 'bold',
		},
		content: {
			width: '176px',
			marginBottom: '1.25rem',
		},
		header: {
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		content1text: {
			fontWeight: 400,
			fontSize: '0.43rem',
			textAlign: 'left',
			lineHeight: '1',
		},
		filterText: {
			fontSize: '0.416rem',
			fontWeight: 600,
		},
		valueText: {
			color: 'black',
			fontSize: '0.416rem',
			fontWeight: 400,
		},
		flexHeader: {
			justifyContent: 'space-between',
		},
		filter1: {
			width: '164.37px',
			background: '#F0F0F0',
			paddingLeft: '0.25rem',
			paddingRight: '0.25rem',
			paddingBottom: '0.5rem',
		},
		dividerGrey: {
			marginTop: '0.25rem',
			borderColor: '#F0F0F0',
		},
		dividerWhite: {
			marginTop: '0.25rem',
			borderColor: '#FFFFFF',
		},
		description: {
			fontSize: '0.4rem',
			fontWeight: '700',
			paddingBottom: '0.3rem',
			marginBottom: '0.3rem',
			color: '#173569',
		},
		logo: {
			width: '4.7rem',
			height: '11px',
		},
		grid: {
			justifyContent: 'space-between',
			marginBottom: '1rem',
		},
		wageGrid: {
			width: '65%',
		},
		gridItem: {
			width: '33%',
		},
		wageHeader: {
			backgroundColor: '#173569',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: '0.5rem',
			paddingRight: '0.5rem',
			paddingBottom: '0.70rem',
		},
		gridHeaderText: {
			fontWeight: 400,
			fontSize: '0.4rem',
			color: 'white',
		},
		gridStripeLighter: {
			width: '50%',
			backgroundColor: '#F2F2F2',
			borderBottom: '0.3px solid #E2E2E2',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: '0.5rem',
			paddingRight: '0.5rem',
			paddingBottom: '0.35rem',
		},
		gridWithoutColor: {
			width: '50%',
		},
		gridStripeDarker: {
			width: '50%',
			backgroundColor: '#EDEDED',
			borderBottom: '0.3px solid #E2E2E2',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: '0.5rem',
			paddingRight: '0.5rem',
			paddingBottom: '0.35rem',
		},
		priceStripeLighter: {
			backgroundColor: '#F2F2F2',
			borderBottom: '0.5px solid #E2E2E2',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: '0.5rem',
			paddingRight: '0.5rem',
			paddingBottom: '0.35rem',
		},
		priceStripeDarker: {
			backgroundColor: '#EDEDED',
			borderBottom: '0.5px solid #E2E2E2',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: '0.5rem',
			paddingRight: '0.5rem',
			paddingBottom: '0.35rem',
		},
		priceContainer: {
			flexDirection: 'column',
			marginBottom: '1rem',
		},
		priceHeader: {
			backgroundColor: '#2E769B',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: '0.5rem',
			paddingRight: '0.5rem',
			paddingBottom: '0.70rem',
		},
		attentionHeader: {
			backgroundColor: '#000000',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: '0.5rem',
			paddingRight: '0.5rem',
			paddingBottom: '0.70rem',
		},
		table: {
			borderCollapse: 'collapse',
		},
		table1: {
			justifyContent: 'space-between',
		},
		table1Head: {
			backgroundColor: '#173569',
			color: 'white',
		},
		table1RowLighter: {
			backgroundColor: '#EDEDED',
		},
		table1RowDarker: {
			backgroundColor: '#E2E2E2',
		},
		table1RowTotal: {
			backgroundColor: '#E8EBF0',
		},
		TableHeaderText: {
			wordSpacing: '0.2rem',
			width: 161,
			fontWeight: 400,
			fontSize: '0.375rem',
			color: 'white',
			textAlign: 'start',
			paddingLeft: '0.5rem',
			paddingBottom: '0.5rem',
		},
		TableHeaderTextCenter: {
			width: 121,
			fontWeight: 400,
			fontSize: '0.375rem',
			color: 'white',
			textAlign: 'center',
			paddingBottom: '0.5rem',
		},
		tableHeaderTextWithoutBorder: {
			width: 108,
			fontWeight: 400,
			fontSize: '0.375rem',
			color: 'white',
			textAlign: 'center',
			paddingBottom: '0.5rem',
		},
		tableText: {
			wordSpacing: '0.2rem',
			color: '#000000',
			fontWeight: 400,
			fontSize: '0.375rem',
			paddingLeft: '0.5rem',
			paddingBottom: '0.5rem',
			borderBottom: '0.3px solid #E2E2E2',
			borderLeft: '0.3px solid #E2E2E2',
			borderRight: '0.3px solid #E2E2E2',
		},
		tableTextCenter: {
			color: '#000000',
			fontWeight: 400,
			fontSize: '0.375rem',
			paddingBottom: '0.5rem',
			textAlign: 'center',
			borderRight: '0.3px solid #E2E2E2',
			borderBottom: '0.3px solid #E2E2E2',
		},
		appointmentRecordBox: {
			background: '#F2F2F2',
			paddingLeft: '1rem',
			paddingRight: '0.75rem',
			paddingBottom: '0.75rem',
			paddingTop: '0.75rem',
			marginBottom: '0.25rem',
		},
		appointmentRecordBoxCard: {
			marginBottom: '1rem',
		},
		appointmentRecordFlexCard: {
			justifyContent: 'space-between',
			gap: '1rem',
		},
		appointmentRecordTitleItem: {
			fontWeight: 600,
			color: '#173569',
			fontSize: '0.6rem',
		},
		flexContainer: {
			width: '100%',
			justifyContent: 'space-between',
			marginBottom: '0.5rem',
		},
		flexContainerWithoutFullItems: {
			width: '100%',
			gap: '10.5px',
			marginBottom: '0.5rem',
		},
		customWrapper: {
			flexDirection: 'column',
		},
	};

	return (
		<Flex style={styles.wrapper}>
			<Flex style={styles.flexHeader}>
				<Box>
					<Image src={logo} style={styles.logo} alt="Grupo Nisa Logo" />
					<Heading style={styles.heading}>Registro de compromisso vendedor /empresa</Heading>
				</Box>
				<Box style={styles?.content}>
					<Flex style={styles?.header}>
						<Text style={styles?.content1text}>Vendedor:</Text>
						<Text style={styles?.valueText}>{flow?.appointmentRecord.user.username}</Text>
					</Flex>

					<Divider style={styles?.dividerGrey} />

					<Flex style={styles?.header}>
						<Text style={styles?.content1text}>Unidade:</Text>
						<Text style={styles.valueText}>{flow?.appointmentRecord.user.company}</Text>
					</Flex>

					<Divider style={styles?.dividerGrey} />

					<Flex style={styles?.header}>
						<Text style={styles?.content1text}>Mês/Ano:</Text>
						<Text style={styles.valueText}>
							{formatDate(String(flow?.appointmentRecord.monthYear), '00:00', MONTH_YEAR_FORMAT)}
						</Text>
					</Flex>
				</Box>
			</Flex>

			<Flex style={styles.flexContainer}>
				<Box style={styles?.filter1}>
					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Fluxo de loja passante</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.shopFlow.passingShopFlow}</Text>
					</Flex>

					<Divider style={styles?.dividerWhite} />

					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Vendedores loja</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.shopFlow.shopSellers}</Text>
					</Flex>

					<Divider style={styles?.dividerWhite} />

					<Flex style={styles?.header}>
						<Text style={styles?.valueText}>Quantidade de atendimento por vendedor</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.shopFlow.customerServiceQuantity}</Text>
					</Flex>
				</Box>
				<Box style={styles?.filter1}>
					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Ligações recebidas</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.calls.incomingCalls}</Text>
					</Flex>

					<Divider style={styles?.dividerWhite} />

					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Comparecimento ligações</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.calls.attendance}%</Text>
					</Flex>

					<Divider style={styles?.dividerWhite} />

					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Quantidade de atendimento</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.calls.customerServiceQuantity}</Text>
					</Flex>
				</Box>

				<Box style={styles?.filter1}>
					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Prospecção</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.prospection.prospection}</Text>
					</Flex>

					<Divider style={styles?.dividerWhite} />

					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Conversão em fluxo de loja</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.prospection.conversionOnFlow}%</Text>
					</Flex>

					<Divider style={styles?.dividerWhite} />

					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Quantidade de atendimento</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.prospection.customerServiceQuantity}</Text>
					</Flex>
				</Box>
			</Flex>

			<Flex style={styles.flexContainerWithoutFullItems}>
				<Box style={styles?.filter1}>
					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Leads</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.leads.leads}</Text>
					</Flex>

					<Divider style={styles?.dividerWhite} />

					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Conversão em fluxo de loja</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.leads.conversionOnFlow}%</Text>
					</Flex>

					<Divider style={styles?.dividerWhite} />

					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Quantidade de atendimento</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.leads.customerServiceQuantity}</Text>
					</Flex>
				</Box>

				<Box style={styles?.filter1}>
					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Cliente oficina</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.workshop.customerWorkshop}</Text>
					</Flex>

					<Divider style={styles?.dividerWhite} />

					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Conversão em avaliação</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.workshop.conversionEvaluation}%</Text>
					</Flex>

					<Divider style={styles?.dividerWhite} />

					<Flex style={styles?.header}>
						<Text style={styles.valueText}>Quantidade</Text>
						<Text style={styles?.filterText}>{flow?.appointmentRecord.workshop.quantity}</Text>
					</Flex>
				</Box>
			</Flex>

			<Table style={styles.table}>
				<Thead>
					<Tr style={styles?.table1Head}>
						<Th style={styles.TableHeaderText}>Canais de atendimento</Th>
						<Th style={styles.TableHeaderTextCenter}>Atendimentos</Th>
						<Th style={styles.TableHeaderTextCenter}>Conversão</Th>
						<Th style={styles.tableHeaderTextWithoutBorder}>Vendas</Th>
					</Tr>
				</Thead>
				<Tbody>
					<Tr style={styles?.table1RowLighter}>
						<Td style={styles.tableText}>Fluxo de loja passante</Td>
						<Td style={styles.tableTextCenter}>
							{flow?.appointmentRecord.serviceChannels.passingShopFlow.atendimentos}
						</Td>
						<Td style={styles.tableTextCenter}>
							{`${flow?.appointmentRecord.serviceChannels.passingShopFlow.conversao}%`}
						</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.passingShopFlow.vendas}</Td>
					</Tr>
					<Tr style={styles?.table1RowDarker}>
						<Td style={styles.tableText}>Comparecimento ligações</Td>
						<Td style={styles.tableTextCenter}>
							{flow?.appointmentRecord.serviceChannels.attendanceCalls.atendimentos}
						</Td>
						<Td style={styles.tableTextCenter}>
							{`${flow?.appointmentRecord.serviceChannels.attendanceCalls.conversao}%`}
						</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.attendanceCalls.vendas}</Td>
					</Tr>
					<Tr style={styles?.table1RowLighter}>
						<Td style={styles.tableText}>Prospecção</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.prospection.atendimentos}</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.prospection.conversao}%</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.prospection.vendas}</Td>
					</Tr>
					<Tr style={styles?.table1RowDarker}>
						<Td style={styles.tableText}>Leads</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.leads.atendimentos}</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.leads.conversao}%</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.leads.vendas}</Td>
					</Tr>
					<Tr style={styles?.table1RowLighter}>
						<Td style={styles.tableText}>Cliente oficina</Td>
						<Td style={styles.tableTextCenter}>
							{flow?.appointmentRecord.serviceChannels.customerWorkshop.atendimentos}
						</Td>
						<Td style={styles.tableTextCenter}>
							{flow?.appointmentRecord.serviceChannels.customerWorkshop.conversao}%
						</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.customerWorkshop.vendas}</Td>
					</Tr>
					<Tr style={styles?.table1RowTotal}>
						<Td style={styles.tableText}>Total</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.total.atendimentos}</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.total.conversao}%</Td>
						<Td style={styles.tableTextCenter}>{flow?.appointmentRecord.serviceChannels.total.vendas}</Td>
					</Tr>
				</Tbody>
			</Table>

			<Flex style={styles.flexContainer}>
				<Box>
					<Heading style={styles.description}>Rentabilidade veículo</Heading>
					<Box style={styles?.filter1}>
						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Ticket médio</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(flow?.appointmentRecord.vehicleProfitability.averageTicket)}
							</Text>
						</Flex>
						<Divider style={styles?.dividerWhite} />
						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Margem</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(Number(flow?.appointmentRecord.vehicleProfitability.margin))}%
							</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Brindes válidos veículo</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.vehicleProfitability.validGift}</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Margem bruta unidade</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(Number(flow?.appointmentRecord.vehicleProfitability.unitGrossMargin))}
							</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Margens total</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(Number(flow?.appointmentRecord.vehicleProfitability.totalMargin))}
							</Text>
						</Flex>
					</Box>
				</Box>

				<Box>
					<Heading style={styles.description}>Financiamento</Heading>
					<Box style={styles?.filter1}>
						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Financiamento</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.financing.financing}</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles?.valueText}>Penetration - quantidade</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.financing.penetrationAmount}%</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Percentual valor financiado</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.financing.financedAmount}%</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>"R" Aplicado</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.financing.rApplied}%</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Receita média/contrato</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(Number(flow?.appointmentRecord.financing.averageContractRevenue))}
							</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Receita financiamento</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(Number(flow?.appointmentRecord.financing.financingIncome))}
							</Text>
						</Flex>
					</Box>
				</Box>

				<Box>
					<Heading style={styles.description}>Captação usados</Heading>
					<Box style={styles?.filter1}>
						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Taxa de avaliação</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.usedPickup.assessmentRate}%</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Quantidade de avaliações</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.usedPickup.reviewsAmount}</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Taxa de captação</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.usedPickup.fundingRate}%</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Total captura</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.usedPickup.totalCatch}</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Premiação por captura</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.usedPickup.captureAward}</Text>
						</Flex>
					</Box>
				</Box>
			</Flex>

			<Flex style={styles.flexContainer}>
				<Box>
					<Heading style={styles.description}>T.C.C.B.</Heading>
					<Box style={styles?.filter1}>
						<Flex style={styles?.header}>
							<Text style={styles?.valueText}>Penetration - quantidade</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.tccb.penetrationAmount}%</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Contratos</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.tccb.contracts}</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Valor médio por contato</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(Number(flow?.appointmentRecord.tccb.averageContractValue))}
							</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Receita T.C.C.B.</Text>
							<Text style={styles?.filterText}>{formatNumbers(Number(flow?.appointmentRecord.tccb.revenueTccb))}</Text>
						</Flex>
					</Box>
				</Box>

				<Box>
					<Heading style={styles.description}>Despachante</Heading>
					<Box style={styles?.filter1}>
						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Penetration - quantidade</Text>
							<Text style={styles?.filterText}>{flow?.appointmentRecord.dispatcher.penetrationAmount}%</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Receita média/carro</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(Number(flow?.appointmentRecord.dispatcher.averageCarRevenue))}
							</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Receita despachante</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(Number(flow?.appointmentRecord.dispatcher.dispatcherRevenue))}
							</Text>
						</Flex>
					</Box>
				</Box>

				<Box>
					<Heading style={styles.description}>Acessórios</Heading>
					<Box style={styles?.filter1}>
						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Vendas por unidade</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(Number(flow?.appointmentRecord.accessories.unitSales))}
							</Text>
						</Flex>

						<Divider style={styles?.dividerWhite} />

						<Flex style={styles?.header}>
							<Text style={styles.valueText}>Venda acessórios</Text>
							<Text style={styles?.filterText}>
								{formatNumbers(Number(flow?.appointmentRecord.accessories.saleAccessories))}
							</Text>
						</Flex>
					</Box>
				</Box>
			</Flex>

			<Flex style={styles.customWrapper}>
				<Flex style={styles.grid}>
					<GridItem style={styles.wageGrid}>
						<Flex style={styles.wageHeader}>
							<Text style={styles.gridHeaderText}>Salário</Text>
							<Text style={styles.gridHeaderText}>
								{formatNumbers(Number(flow?.appointmentRecord.salary.totalSalary))}
							</Text>
						</Flex>
						<Flex>
							<Flex style={styles.gridStripeDarker}>
								<Text style={styles.content1text}>Comissão sobre venda</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.salary.commissionOnSale))}
								</Text>
							</Flex>
							<Flex style={styles.gridStripeLighter}>
								<Text style={styles.content1text}>Receita T.C.C.B.</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.salary.revenueTccb))}
								</Text>
							</Flex>
						</Flex>
						<Flex>
							<Flex style={styles.gridStripeLighter}>
								<Text style={styles.content1text}>Comissão sobre margem</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.salary.comissionOnMargin))}
								</Text>
							</Flex>
							<Flex style={styles.gridStripeDarker}>
								<Text style={styles.content1text}>Prêmio financiamento</Text>
								<Text style={styles.valueText}>
									{formatNumbers(+Number(flow?.appointmentRecord.salary.fundingAward).toFixed(2))}
								</Text>
							</Flex>
						</Flex>
						<Flex>
							<Flex style={styles.gridStripeDarker}>
								<Text style={styles.content1text}>Comissão financiamento</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.salary.financingCommission))}
								</Text>
							</Flex>
							<Flex style={styles.gridStripeLighter}>
								<Text style={styles.content1text}>Prêmio despachante</Text>
								<Text style={styles.valueText}>
									{formatNumbers(+Number(flow?.appointmentRecord.salary.dispatcherAward).toFixed(2))}
								</Text>
							</Flex>
						</Flex>
						<Flex>
							<Flex style={styles.gridStripeLighter}>
								<Text style={styles.content1text}>Comissão despachante</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.salary.forwardingCommission))}
								</Text>
							</Flex>
							<Flex style={styles.gridStripeDarker}>
								<Text style={styles.content1text}>Prêmio T.C.C.B.</Text>
								<Text style={styles.valueText}>{formatNumbers(Number(flow?.appointmentRecord.salary.tccbAward))}</Text>
							</Flex>
						</Flex>
						<Flex>
							<Flex style={styles.gridStripeDarker}>
								<Text style={styles.content1text}>Comissão T.C.C.B.</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.salary.tccbCommission))}
								</Text>
							</Flex>
							<Flex style={styles.gridStripeLighter}>
								<Text style={styles.content1text}>Venda acessórios</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.salary.saleAccessories))}
								</Text>
							</Flex>
						</Flex>
						<Flex>
							<Flex style={styles.gridStripeLighter}>
								<Text style={styles.content1text}>Valor venda</Text>
								<Text style={styles.valueText}>{formatNumbers(Number(flow?.appointmentRecord.salary.saleValue))}</Text>
							</Flex>
							<Flex style={styles.gridStripeDarker}>
								<Text style={styles.content1text}>Premiação por captura</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.salary.captureAward))}
								</Text>
							</Flex>
						</Flex>
						<Flex>
							<Flex style={styles.gridStripeDarker}>
								<Text style={styles.content1text}>Qtde venda</Text>
								<Text style={styles.valueText}>{flow?.appointmentRecord.salary.quantitySale}</Text>
							</Flex>
							<Flex style={styles.gridStripeLighter}>
								<Text style={styles.content1text}>DSR</Text>
								<Text style={styles.valueText}>
									{formatNumbers(+Number(flow?.appointmentRecord.salary.dsr).toFixed(2))}
								</Text>
							</Flex>
						</Flex>
						<Flex>
							<Flex style={styles.gridStripeLighter}>
								<Text style={styles.content1text}>Margem total</Text>
								<Text style={styles.valueText}>{formatNumbers(flow?.appointmentRecord.salary.totalMargin)}</Text>
							</Flex>
							<Flex style={styles.gridStripeDarker}>
								<Text style={styles.content1text}>Dias de calendário</Text>
								<Text style={styles.valueText}>{flow?.appointmentRecord.salary.calendarDays}</Text>
							</Flex>
						</Flex>
						<Flex>
							<Flex style={styles.gridStripeDarker}>
								<Text style={styles.content1text}>Receita financiamento</Text>
								<Text style={styles.valueText}>
									{formatNumbers(+Number(flow?.appointmentRecord.salary.financingIncome).toFixed(2))}
								</Text>
							</Flex>
							<Flex style={styles.gridStripeLighter}>
								<Text style={styles.content1text}>Domingos e feriados</Text>
								<Text style={styles.valueText}>{flow?.appointmentRecord.salary.sundayHolidays}</Text>
							</Flex>
						</Flex>
						<Flex>
							<Flex style={styles.gridStripeLighter}>
								<Text style={styles.content1text}>Receita despachante</Text>
								<Text style={styles.valueText}>
									{formatNumbers(+Number(flow?.appointmentRecord.salary.dispatcherRevenue).toFixed(2))}
								</Text>
							</Flex>
							<Flex style={styles.gridWithoutColor}></Flex>
						</Flex>
					</GridItem>

					<GridItem style={styles.gridItem}>
						<Flex style={styles.priceContainer}>
							<Flex style={styles.priceHeader}>
								<Text style={styles.gridHeaderText}>Preço de venda/emplacamento</Text>
							</Flex>
							<Flex style={styles.priceStripeDarker}>
								<Text style={styles.content1text}>Valor médio de venda</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.priceOfPlacement.averageSaleValue))}
								</Text>
							</Flex>
							<Flex style={styles.priceStripeLighter}>
								<Text style={styles.content1text}>Valor médio de custo</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.priceOfPlacement.averageValueCost))}
								</Text>
							</Flex>
							<Flex style={styles.priceStripeDarker}>
								<Text style={styles.content1text}>Lucro médio por documento</Text>
								<Text style={styles.valueText}>
									{formatNumbers(Number(flow?.appointmentRecord.priceOfPlacement.profitDocument))}
								</Text>
							</Flex>
						</Flex>

						<Flex flexDir="column">
							<Flex style={styles.attentionHeader}>
								<Text style={styles.gridHeaderText}>Pontos de atenção</Text>
							</Flex>
							<VStack style={styles.priceStripeLighter}>
								{flow?.appointmentRecord.attentionPoints ? (
									flow?.appointmentRecord.attentionPoints.map((item, index) => (
										<Text style={styles.content1text} key={index}>
											• {item}
										</Text>
									))
								) : (
									<Text style={styles.content1text}>Não existem pontos de atenção registrados.</Text>
								)}
							</VStack>
						</Flex>
					</GridItem>
				</Flex>

				<Box style={styles.appointmentRecordBox}>
					<Box style={styles.appointmentRecordBoxCard}>
						<Text style={styles.appointmentRecordTitleItem}>Registro de compromisso vendedor/empresa</Text>
						<Text style={styles.filterText}>Assinaturas</Text>
					</Box>
					<Flex style={styles.appointmentRecordFlexCard}>
						<Box>
							<Text style={styles.appointmentRecordTitleItem}>Data e hora</Text>
							{flow?.signatures.map(signature => (
								<Text style={styles.filterText} key={signature?.user?.id}>
									{signature.signatureDate.split('T')[0].split('-').reverse().join('/') +
										' às ' +
										signature.signatureDate.split('T')[1].split('.')[0]}
								</Text>
							))}
						</Box>
						<Box>
							<Text style={styles.appointmentRecordTitleItem}>Documento assinado por</Text>
							{flow?.signatures.map(signature => (
								<Text style={styles.filterText} key={signature?.user?.id}>
									{signature.user.username}
								</Text>
							))}
						</Box>

						<Box>
							<Text style={styles.appointmentRecordTitleItem}>Cargo ocupado: </Text>
							{flow?.signatures.map(signature => (
								<Text style={styles.filterText} key={signature?.user?.id}>
									{signature.user.role}
								</Text>
							))}
						</Box>
					</Flex>
				</Box>
			</Flex>
		</Flex>
	);
};

export default ViewSalesChannelFlow;
